import { useState, useEffect } from "react"

const parse = (key, val) => {
  try {
    const res = JSON.parse(sessionStorage.getItem(key))
    if (!res) throw new Error("no sessionStorage")
    return res
  } catch (err) {
    return val
  }
}

export default function useSessionStorage(key, val) {
  const [value, setValue] = useState(parse(key, val))

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value))
  }, [value])

  return [value, setValue]
}
