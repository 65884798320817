import React, { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from "./styles"
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Box
} from "@material-ui/core"
import { closeDialog } from "actions/dialogs"
import DialogHeader from "components/DialogHeader"
import accounting from "accounting"
import LoaderButton from "@bit/c_t.components.loader-button"
import { purchaseRaffle } from "actions/items"
import { openDialog } from "actions/dialogs"
import DialogButton from "components/DialogButton"
import { openSnackBar } from "actions/snack-bar"
import { Link } from "react-router-dom"

const RaffleConfirmationDialog = () => {
  const classes = useStyles()
  let buttonRef = useRef()
  const open = useSelector(state => state.dialogs.raffleConfirmation?.open || false)
  const { itemId, userID, bidAmt, userPayId, paysource, currency, price, chances } = useSelector(state => state.dialogs.raffleConfirmation?.data || {})
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeDialog("raffleConfirmation"))
  }

  const handleSubmit = () => {
    buttonRef.current.setWorking(true)
    setIsButtonLoading(true)
    dispatch(purchaseRaffle({
      item_id: itemId,
      user_id: userID,
      bid_amt: bidAmt,
      user_pay_id: userPayId,
      paysource: paysource,
      currency: currency
      // stripe_test: 1
    })).then(res => {
      setTimeout(() => {
        handleClose()
        dispatch(openDialog("bidWinner", { type: "raffle", chances: res.tickets_purchased_by_user }))
        buttonRef.current.setWorking(false)
        setIsButtonLoading(false)
      }, 500)
    })
      .catch((err) => {
        console.log(err)
        dispatch(
          openSnackBar({
            message: "No tickets available."
          }))
        buttonRef.current.setWorking(false)
        setIsButtonLoading(false)
        handleClose()
      })
  }

  return (
    <Dialog
      open={open}
    >
      <DialogHeader onClose={handleClose}/>
      <Box className={classes.container}>
        <Typography
          color="primary"
          className={classes.bidTitle}
          variant="h4">{`For your donation of ${accounting.formatMoney(price, "$", 0)} you will receive entries to win this item`}
        </Typography>
        <Typography className={classes.confirmationText}>
        Please confirm your donation
        </Typography>
        <Typography className={classes.termsOfServiceText}>
            By placing a donation you are agreeing to our <br/><Link to={{ pathname: "/terms-of-service" }} target="_blank" style={{ textDecoration: "none", color: "#F440BD" }}>Terms and Conditions.</Link>
        </Typography>
      </Box>
      <DialogButton
        handleSubmit={handleSubmit}
        disabled={isButtonLoading}
        buttonRef={buttonRef}
        text="Confirm Donation"/>
    </Dialog>
  )
}

export default RaffleConfirmationDialog
