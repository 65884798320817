import React, { useMemo } from "react"
import { Box, Button, Container, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import useRemoveHtmlTags from "../../../hooks/useRemoveHtmlTags"
import useItemSponsor from "hooks/useItemSponsor"
import useStyles from "./styles"

const HomeHeaderItem = ({ item }) => {
  const classes = useStyles()
  const sponsor = useItemSponsor(item)
  const isMobile = window.innerWidth < 600
  const description = useRemoveHtmlTags(item.description)

  return (
    <Box>
      <img src={isMobile ? item.image[0].headerUrlMobile : item.image[0].headerUrl} alt={item.name} />
      <Container className={`legend ${classes.legend}`}>
        <Box className={classes.content}>
          <Typography variant="overline">{sponsor?.sponsor_name ? `Supporting ${sponsor?.sponsor_name}` : ""}</Typography>
          <Typography className={classes.heading} variant="h3">
            {item.name}
          </Typography>
          <Typography classes={{ root: classes.text }} variant="body2" className={classes.description}>
            {item.subtitle}
          </Typography>
          <Button variant="contained" className={classes.checkButton}component={Link} to={`/item/${item.item_id}`} >
            Check it out
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

export default HomeHeaderItem
