import { useMemo } from "react"
import { useSelector } from "react-redux"


function usePublished() {
  const items = useSelector(state => state?.items?.data || [])
  const publishedItems = items.filter(x => x.published)

  return useMemo(() => {
    return publishedItems
  }, [items])
}

export default usePublished
