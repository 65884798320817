import Record from "./base"
import { PhoneNumberFormat as PNF, PhoneNumberUtil } from "google-libphonenumber"

const phoneUtil = PhoneNumberUtil.getInstance()

export class User extends Record {
  constructor(...props) {
    super(...props)
    if (this.first_name) {
      this.initials = `${this.first_name[0]}${this.last_name[0]}`
    }
    if (this.user_phone) {
      try {
        this.user_phone = phoneUtil.format(phoneUtil.parse(this.user_phone), PNF.NATIONAL).replace(/[^\d]/g, "")
      } catch (err) {
        console.log(err)
      }
    }
    if (this.user_paysources?.length > 0) {
      this.user_paysources = this.user_paysources.map(x => {
        const newPaysource = { ...x, id: x.paysource_id }
        return newPaysource
      })
    }
  }
}
