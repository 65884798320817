import React from "react"
import { SvgIcon } from "@material-ui/core"

const ShoppingBagSvg = ({ color }) => {
  return (
    <SvgIcon viewBox="10 10 30.208 15.59" stroke={color} strokeWidth="1">
      <path className="a" d="M32.007,12.083H29.433V10.212a4.212,4.212,0,1,0-8.423,0v1.872H18.2a.7.7,0,0,0-.7.7v10.3a3.51,3.51,0,0,0,3.51,3.51H29.2a3.51,3.51,0,0,0,3.51-3.51v-10.3A.7.7,0,0,0,32.007,12.083Zm-9.593-1.872a2.808,2.808,0,1,1,5.615,0v1.872H22.414ZM31.3,23.08A2.106,2.106,0,0,1,29.2,25.186H21.01A2.106,2.106,0,0,1,18.9,23.08V13.487H21.01v2.574a.7.7,0,1,0,1.4,0V13.487h5.615v2.574a.7.7,0,1,0,1.4,0V13.487H31.3Z" />
    </SvgIcon>
  )
}

export default ShoppingBagSvg
