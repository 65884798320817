import React from "react"
import { Box, Button, Container, Typography } from "@material-ui/core"
import LogoSecondary from "components/LogoSecondary"
import { Link } from "react-router-dom"
import useStyles from "./styles"
import aboutUs from "assets/about_us.png"
import logoTrail from "assets/logo_trail.svg"
import dottedCircle from "assets/dotted_circle.svg"

const AboutUs = ({ children }) => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Box className={classes.contentContainer}>
        <Box flex="1.1" display="flex" flexDirection="column" zIndex="5">
          <Typography variant="h2">
            Live Your Dream.<br />Make Someone Else’s.
          </Typography>
          <Box>
            <Typography variant="body2">
              Bid and enter raffles to win amazing one of a lifetime experiences,
              and help support charities around the world.
            </Typography>
            <Button
              component={Link}
              to="/about-us"
              variant="contained"
              color="primary">
              About Us
            </Button>
          </Box>
        </Box>
        <Box position="relative" flex="1">
          <Box className={classes.imageContainer}>
            <img className={classes.heartImage} src={aboutUs} />
            <LogoSecondary className={classes.logo} color="#fff" />
            <img className={classes.trail} src={logoTrail} />
            <img className={classes.bottomCircle} src={dottedCircle} />
            <img className={classes.rightCircle} src={dottedCircle} />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default AboutUs
