import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  contentContainer: {
    padding: "0px 50px",
    position: "relative",
    height: "100%",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px"
    }
  },
  bidTitle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4)
  },
  termsOfServiceText: {
    marginTop: theme.spacing(4)
  },
  actionsWrapper: {
    justifyContent: "center",
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      border: "1px solid black",
      pasition: "absolute ",
      width: "100%",
      height: "100%",
      justifyContent: "none",
      marginTop: theme.spacing(0),
      paddingBottom: theme.spacing(0)
    }
  },
  wrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      height: "100%"
    }
  },
  error: {
    position: "absolute",
    top: "100px",
    left: "0px",
    width: "100%"
  },
  enterBidContainer: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    // height: "54px",
    // position: "relative",
    width: "100%"
  },
  buttonGroupContainer: {
    width: "100%"
  },
  textFieldContainer: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "50%"
    }
  },
  buttonContainer: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "50%"
    }
  },
  input: {
    padding: "0px",
    height: "56px",
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem"
    }
  },
  input2: {
    width: "100%"
  },
  inputRadius: {
    "-webkit-border-radius": "4px 0px 0px 4px",
    borderRadius: "4px 0px 0px 4px !important",
    height: "100%",
    width: "100%"
  },
  bidButton: {
    width: "100%",
    "-webkit-border-radius": "0px 4px 4px 0px",
    borderRadius: "0px 4px 4px 0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem"
    }
  },
  bidButtonShadow: {
    boxShadow: "none",
    height: "56px",
    width: "100%"
  }

}))

export default styles
