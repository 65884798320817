import React from "react"
import useStyles from "./styles"
import { DialogContent, IconButton, Box, Container } from "@material-ui/core"
import logo from "assets/kindboost.svg"
import CloseIcon from "@material-ui/icons/HighlightOff"


const DialogHeader = ({ onClose }) => {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.container}>
        <Box display="flex">
          <img src={logo} />
          <Box flexGrow={1}/>
          <IconButton onClick={onClose}>
            <CloseIcon color="primary" />
          </IconButton>
        </Box>
      </Box>


    </>
  )
}

export default DialogHeader
