import {
  EVENT_SET,
  EVENT_LOADING,
  EVENT_UPDATE
} from "types/actions/event"
import API from "store/api"
import serialize from "store/serialize"

export const setEvent = data => ({
  type: EVENT_SET,
  data
})

export const updateEventData = data => ({
  type: EVENT_UPDATE,
  data
})

export const _setEventLoading = (loading = false) => ({
  type: EVENT_LOADING,
  loading
})

export const getEvent = eventId => {
  return dispatch => {
    dispatch(_setEventLoading(true))
    const deferred = new Promise((resolve, reject) => {
      API.get(`/events/${eventId}`)
        .set("Content-Type", "application/json")
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          if (response.body && response.body.msg) {
            if (response.body.msg === "Event not found") {
              window.location.href = "/"
            }
          }
          return response
        })
        .then(response => response.body)
        .then(response => {
          return response[0]
        })
        .then(data => {
          resolve(
            serialize("event", data).then(response => {
              dispatch(setEvent(response))
              return response
            })
          )
        })
        .catch(err => {
          reject(err)
          console.log(err)
        })
        .finally(() => {
          dispatch(_setEventLoading(false))
        })
    })
    return deferred
  }
}
