import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from "./styles"
import {
  Dialog,
  Box,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Link,
  Hidden
} from "@material-ui/core"
import { closeDialog } from "actions/dialogs"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import FormTextField from "components/FormTextField"
import MaskedMobileInput from "components/MaskedPhoneInput"
import { sendPin } from "actions/authentication"
import cleanPhone from "utils/cleanPhone"
import PinInput from "./PinInput"
import { openDialog } from "actions/dialogs"
import { login } from "actions/authentication"
import { openSnackBar } from "actions/snack-bar"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"
import classnames from "clsx"
import { updateUser } from "actions/users"
import DialogHeader from "components/DialogHeader"
import DialogButton from "components/DialogButton"

const phoneSchema = Yup.object().shape({
  phone: Yup.string().phone("Please enter a valid phone number").required("Phone is required")
})

const pinSchema = Yup.object().shape({
  pin: Yup.string().required("Required")
})

const passwordSchema = Yup.object().shape({
  user_pass: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
  passwordConfirmation: Yup.string().oneOf([Yup.ref("user_pass"), null], "Passwords must match")
})

const ForgotPasswordDialog = () => {
  const classes = useStyles()
  const buttonRef = useRef(null)
  const formRef = useRef()
  const open = useSelector(state => state.dialogs.forgotPassword?.open || false)
  const data = useSelector(state => state.dialogs.forgotPassword?.data || {})
  const authenticatedUser = useSelector(state => state.authentication.authenticatedUser)
  const [initialPhone, setInitialPhone] = useState("")
  const [phoneValue, setPhoneValue] = useState("")
  const [currentStep, setCurrentStep] = useState(1)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    setInitialPhone(data.phone)
  }, [data])

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeDialog("forgotPassword"))
  }

  const handlePinSubmit = (values, actions) => {
    dispatch(
      login({
        user_phone: cleanPhone(phoneValue),
        user_pin: values.pin
      })
    )
      .then(res => {
        setShowPassword(true)
      })
      .catch(err => {
        console.error(err)
        dispatch(
          openSnackBar({
            message: "Pin number was incorrect"
          })
        )
      })
      .finally(() => actions.setSubmitting(false))
  }

  const handlePhoneSubmit = values => {
    setPhoneValue(values.phone)
    buttonRef.current.setWorking(true)
    dispatch(sendPin(cleanPhone(values.phone))).then(() => {
      setTimeout(() => {
        setCurrentStep(2)
      }, 150)
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      buttonRef.current.setWorking(false)
    })
  }
  const handlePassword = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const handlePasswordSubmit = values => {
    buttonRef.current.setWorking(true)
    dispatch(updateUser(authenticatedUser.user_id, {
      user_pass: values.user_pass
    })).then(() => {
      setTimeout(() => {
        handleClose()
        dispatch(openSnackBar({
          message: "Your password has been successfully updated"
        }))
        setTimeout(() => {
          setCurrentStep(1)
          setShowPassword(false)
        }, 360)
      }, 150)
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      buttonRef.current.setWorking(false)
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogHeader
        onClose={handleClose}/>
      {currentStep === 1 && (
        <Formik validationSchema={phoneSchema} initialValues={{
          phone: initialPhone
        }} onSubmit={handlePhoneSubmit}>
          <Form>
            <Box className={classes.containerStep1}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4" color="primary" className={classes.title}>
                    Forgot Password
                  </Typography>
                  <Typography>Please enter your phone number to get a reset code texted to you</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                  <FormTextField name="phone" type="tel" label="Phone" InputProps={{ inputComponent: MaskedMobileInput }} />
                </Grid>
              </Grid>
              <Hidden smUp>
                <Box>
                  <Typography color="secondary" className={classes.registerText}>
                    Already have an account?
                    <Link
                      color="primary"
                      onClick={() => {
                        handleClose()
                        dispatch(openDialog("login"))
                      }}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    >
                      Login here
                    </Link>
                  </Typography>
                </Box>
              </Hidden>
            </Box>

            <DialogButton
              buttonRef={buttonRef}
              text="Send Pin"/>
          </Form>
        </Formik>
      )}
      {currentStep === 2 && (
        <>
          <Box className={classes.containerStep2}>
            <Grid item xs={12}>
              <Typography variant="h4" color="primary" className={classes.title}>
                  Forgot Password
              </Typography>
              <Typography>Please enter the four-digit code texted to your phone number</Typography>
            </Grid>
            <Grid item xs={12}>
              <Formik validationSchema={pinSchema}
                initialValues={{
                  pin: ""
                }} onSubmit={handlePinSubmit}>
                {({ isSubmitting }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={8} style={{ marginTop: "20px" }}>
                        <PinInput disabled={showPassword} />
                      </Grid>
                      <Grid item xs={4}>
                        <Box height="100%" display="flex" alignItems="center" justifyContent="center" className={classnames(classes.verified, {
                          [classes.verifiedSuccess]: showPassword
                        })} >
                          {isSubmitting && <CircularProgress className={classes.progress} />}
                          <VerifiedUserIcon style={{ marginRight: 8 }} /> <Typography>Verified</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Box>
          <Grid item xs={12}>
            <Formik validationSchema={passwordSchema} initialValues={{
              user_pass: "",
              passwordConfirmation: ""
            }}
            onSubmit={handlePasswordSubmit}
            >
              {({ values, ...formikProps }) => (
                <Form>
                  <Box className={classes.containerStep2}>
                    <Grid className={classes.passwordInputs} container spacing={1}>
                      <Grid item sm={6} xs={12}>
                        <FormTextField name="user_pass" label="Enter New Password" type="password" fullWidth disabled={!showPassword} />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <FormTextField name="passwordConfirmation" label="Confirm New Password" type="password" fullWidth disabled={!showPassword} />
                      </Grid>
                      <Grid item xs={12}>
                        <Box marginTop={3} marginBottom={2} textAlign="center">
                          <Hidden smUp>
                            <Box>
                              <Typography color="secondary" className={classes.registerText}>
                                  Already have an account?
                                <Link
                                  color="primary"
                                  onClick={() => {
                                    handleClose()
                                    dispatch(openDialog("login"))
                                  }}
                                  style={{ cursor: "pointer", marginLeft: "5px" }}
                                >
                                  Login here
                                </Link>
                              </Typography>
                            </Box>
                          </Hidden>

                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <DialogButton
                    buttonRef={buttonRef}
                    disabled={!showPassword}
                    text={"Submit"}
                  />
                </Form>
              )}
            </Formik>
          </Grid>

        </>

      )}
      <Box>
        <Hidden smDown>
          <Box textAlign="center">
            <Typography color="secondary" className={classes.registerText}>
            Already have an account?{" "}
              <Button
                color="primary"
                onClick={() => {
                  handleClose()
                  dispatch(openDialog("login"))
                }}
                style={{ cursor: "pointer" }}
              >
              Login here
              </Button>
            </Typography>
          </Box>
        </Hidden>
      </Box>
    </Dialog>
  )
}

export default ForgotPasswordDialog
