import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  container: {
    padding: "0px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
      height: "50vh",
      overflow: "scroll"
    }
  },

  headerTextWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  actionsWrapper: {
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4)
  },
  saveButton: {
    marginBottom: theme.spacing(4)
  },
  removeButton: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    }
  },
  creditCardContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  numberCardContainer: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  removeContainer: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      width: "100%"

    }
  },
  popover: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "200px",
    paddingTop: "5px"
  },

  cardElement: {
    marginTop: "30px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px"
    }
  }
}))

export default styles
