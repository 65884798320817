import Background from "assets/404_background.png"

const styles = theme => ({
  notFoundContainer: {
    position: "relative",
    background: `url(${Background})`,
    height: "100vh",
    overflowY: "hidden",
    minWidth: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "120%",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "300%"
    },
    "&:before": {
      height: "100%",
      content: "''",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: "linear-gradient(360deg, #D83AA8 0%, #7B2D27 100%) 0% 0% no-repeat padding-box",
      opacity: "0.7",
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(0),
        marginRight: theme.spacing(0)
      }
    }
  },
  hartImage: {
    position: "absolute",
    bottom: "-4%",
    left: "-5%",
    [theme.breakpoints.down("md")]: {
      bottom: "-20%"
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "20%",
      width: "85%"
    }
  },
  pathImage: {
    position: "absolute",
    bottom: "-20%",
    right: "45%",
    [theme.breakpoints.down("sm")]: {
      top: "10%",
      right: "0%"
    }
  },
  title: {
    color: "white",
    fontSize: "22rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(15),
    [theme.breakpoints.down("md")]: {
      fontSize: "10rem"
    }
  },
  text: {
    color: "white"
  },
  textContainer: {
    position: "absolute",
    zIndex: 1,
    width: "500px",
    top: "40%",
    left: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    }

  }
})

export default styles
