import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    background: "linear-gradient(45deg, #F440BD 30%, #FF4E40 90%)",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(5)
    }
  },
  borderImage: {
    width: "101%",
    verticalAlign: "top",
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      left: -10,
      width: "200%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "300%"
    }
  },
  linkContainer: {
    color: "white",
    margin: "0 auto",
    paddingBottom: "100px",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "30px",
      flexWrap: "wrap",
      paddingTop: theme.spacing(8)
    }
  },
  link1: {
    marginRight: theme.spacing(4),
    color: "white",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: theme.spacing(3)
    }
  },
  link2: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  restContentContainer: {
    margin: "0 auto",
    width: "80%",
    paddingBottom: theme.spacing(3)

  },


  footerContainer: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4)
  },
  // logoContainer: {
  //   [theme.breakpoints.down("sm")]: {
  //     width: "100%",
  //     marginBottom: theme.spacing(3),
  //     display: "flex",
  //     justifyContent: "center"
  //   }
  // },
  logoContainer: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      marginBottom: theme.spacing(3)
    }
  },
  logo: {
    width: "190px",
    height: "auto",
    color: "#fff"
  },
  iconContainer: {
    marginLeft: "-12px",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      justifyContent: "center",
      width: "100%",
      zIndex: 10
    }
  },
  socialMediaIcons: {
    color: "#fff",
    width: "28px",
    height: "28px"
  },
  iconButtonContianer: {
    width: "45px",
    height: "45px",
    marginRight: theme.spacing(1)
  },
  footerTitle: {
    color: "#fff",
    opacity: 0.8,
    fontSize: "1.4rem",
    fontWeight: "700"
  },
  footerLinkWrapper: {
    display: "block",
    width: "33.333%",
    margin: theme.spacing(0.75, 0),
    lineHeight: "1.2rem",
    [theme.breakpoints.down("md")]: {
      width: "50%"
    }
  },
  footerLink: {
    color: "#fff",
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
    fontWeight: "500"
  },
  copyrightContainer: {
    marginTop: theme.spacing(10),
    textAlign: "center",
    fontSize: "1.2rem"
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    //   marginTop: theme.spacing(3),
    //   display: "flex",
    //   justifyContent: "center"
    // }
  },
  copyrightText: {
    color: "#fff",
    fontSize: "1.2rem"
  },
  tcLink: {
    color: "#fff",
    textDecoration: "none"
  }
}))

export default styles
