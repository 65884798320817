const str = `
container {
  width: 60%;
	margin: 50px auto;
	font-size: 16px;
}

ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c4 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2.5rem;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c1 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2.5rem;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}
.c14 {
  padding-top: 24pt;
  padding-bottom: 6pt;
  line-height: 2.5rem;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c26 {
  padding-top: 0pt;
  padding-bottom: 18pt;
  line-height: 2.5rem;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c31 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-style: normal;
}
.c20 {
  padding-top: 12pt;
  padding-bottom: 18pt;
  line-height: 2.5rem;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c32 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2.5rem;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c8 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 2.5rem;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c12 {
  color: #1a1a1a;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c28 {
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.c6 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c19 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c5 {
  font-size: 7pt;
  font-weight: 700;
}
.c29 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c3 {
  font-size: 12pt;
  font-weight: 400;
}
.c21 {
  font-weight: 700;
  font-size: 12pt;
  font-family: Montserrat,sans-serif;
}
.c17 {
  font-size: 14pt;
  color: #1a1a1a;
}
.c25 {
  color: inherit;
  text-decoration: inherit;
}
.c9 {
  font-weight: 400;
  font-family: Montserrat,sans-serif;
}
.c7 {
  font-weight: 400;
  font-family: Montserrat,sans-serif;
}
.c15 {
  background-color: #ffffff;
  font-size: 11pt;
}
.c24 {
  font-weight: 400;
  font-family: Montserrat,sans-serif;
}
.c2 {
  margin-left: 36pt;
}
.c18 {
  color: #1a1a1a;
}
.c11 {
  margin-left: 72pt;
}
.c13 {
  color: #ff0000;
}
.c22 {
  font-size: 11pt;
}
.c23 {
  font-size: 12pt;
}
.c27 {
  background-color: #fff2cc;
}
.c10 {
  font-size: 7pt;
}
.c30 {
  font-size: 14pt;
}
.c16 {
  font-size: 18pt;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  line-height: 2.5rem;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  line-height: 2.5rem;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: Montserrat,sans-serif;
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: Montserrat,sans-serif;
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  line-height: 2.5rem;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  line-height: 2.5rem;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  line-height: 2.5rem;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  line-height: 2.5rem;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  line-height: 2.5rem;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  line-height: 2.5rem;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}


`
export default str
