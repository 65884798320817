import React, { useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from "./styles"
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Grid,
  TextField,
  Box,
  Popover
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/HighlightOff"
import logo from "assets/kindboost.svg"
import * as Yup from "yup"
import "yup-phone"
import { closeDialog } from "actions/dialogs"
import { Formik, Form } from "formik"
import FormTextField from "components/FormTextField"
import MaskedMobileInput from "components/MaskedPhoneInput"
// import FormAddressField from "components/FormAddressField"
import LoaderButton from "@bit/c_t.components.loader-button"
import { updateUser } from "actions/users"
import { CardElement, injectStripe } from "react-stripe-elements"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
// import grey from "@material-ui/core/colors/grey"
// import orange from "@material-ui/core/colors/orange"
// import blue from "@material-ui/core/colors/blue"
// import FormError from "components/FormError"
import { addCard, removeCard } from "actions/users"
import { openSnackBar } from "actions/snack-bar"
import useUserPaysource from "hooks/useUserPaysource"
import DialogHeader from "components/DialogHeader"
import DialogButton from "components/DialogButton"

const profileSchema = Yup.object().shape({
  user_first_name: Yup.string().required("First name is required"),
  user_last_name: Yup.string().required("Last name is required"),
  user_email: Yup.string().email("Invalid email").required("Email is required"),
  user_phone: Yup.string()
    .phone("Please enter a valid phone number")
    .required("Phone is required")
  // address: Yup.object().test("valid-address", "Please choose a valid street address", x => {
  //   const p = x.place
  //   return x && p && p.address && p.city && p.state && p.zip
  // })
})

// const createUserAddress = user => {
//   if (!user.user_address) {
//     return {}
//   } else {
//     return {
//       place: {
//         address: user.user_address,
//         city: user.user_city,
//         state: user.user_state,
//         zip: user.user_zip
//       },
//       value: `${user.user_address}, ${user.user_city}, ${user.user_state}`
//     }
//   }
// }

const ProfileDialog = ({ stripe, elements }) => {
  const classes = useStyles()
  const buttonRef = useRef()
  // const authenticatedUser = useSelector(state => state.authentication.authenticatedUser)
  // const open = useSelector(state => state.dialogs.profile?.open || false)
  const { authenticatedUser, open = false, cardRequired = false } = useSelector((state) => ({
    authenticatedUser: state.authentication.authenticatedUser,
    open: state.dialogs.profile?.open,
    cardRequired: state.dialogs.profile?.data?.cardRequired
  }))
  const [cardError, setCardError] = useState(null)
  const userPaysource = useUserPaysource()
  const [shouldRemoveCard, setShouldRemoveCard] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const initialValues = useMemo(
    () => ({
      user_first_name: authenticatedUser.user_first_name,
      user_last_name: authenticatedUser.user_last_name,
      user_email: authenticatedUser.user_email,
      user_phone: authenticatedUser.user_phone,
      user_pass: "",
      passwordConfirmation: ""
      // address: createUserAddress(authenticatedUser)
    }),
    [authenticatedUser]
  )
  // const data = useSelector(state => state.dialogs.profile?.data || {})

  const dispatch = useDispatch()

  const handleClose = () => {
    setShouldRemoveCard(false)
    dispatch(closeDialog("profile", true))
  }


  const removePaysource = () => {
    return new Promise((resolve, reject) => {
      if (!shouldRemoveCard) {
        return resolve(null)
      } else {
        dispatch(
          removeCard({
            user_id: authenticatedUser.user_id,
            user_pay_id: authenticatedUser.user_pay_id,
            paysource_id: userPaysource.id
          })
        )
          .then(resolve)
          .catch(reject)
      }
    })
  }

  const handleStripe = () => {
    return new Promise((resolve, reject) => {
      const card = elements.getElement("card")
      if ((!card || card._empty) && !shouldRemoveCard) {
        return resolve(null)
      }
      stripe
        .createToken()
        .then((res) => {
          if (res.error) {
            setCardError(res.error.message)
            return reject(res)
          }
          return res
        })
        .then((result) =>
          dispatch(
            addCard({
              user_id: authenticatedUser.user_id,
              user_name: `${authenticatedUser.user_first_name} ${authenticatedUser.user_last_name}`,
              user_pay_id: authenticatedUser.user_pay_id,
              token: result.token.id,
              user_email: authenticatedUser.user_email,
              user_zip: result.token.card.address_zip
            })
          )
        )
        .then(resolve)
        .catch(reject)
    })
  }

  const handleSubmit = (formData) => {
    buttonRef.current.setWorking(true)
    setCardError(null)
    handleStripe()
      .then(removePaysource)
      .then(() => dispatch(updateUser(authenticatedUser.user_id, formData)))
      .then(() => {
        dispatch(openSnackBar({ message: "Profile Updated" }))
        handleClose()
      })
      .catch(console.log)
      .finally(() => {
        buttonRef.current.setWorking(false)
        setShowPassword(false)
      })
  }
  return (
    <Dialog open={open}>
      <Formik
        validationSchema={profileSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Form>
          <DialogHeader
            onClose={handleClose}/>
          <Box className={classes.container}>
            <Box className={classes.headerTextWrapper}>
              <Typography variant="h4" color="primary">
              Edit Profile
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormTextField name="user_first_name" label="First Name" />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormTextField name="user_last_name" label="Last Name" />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField name="user_email" label="Email" />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    name="user_phone"
                    label="Phone"
                    type="tel"
                    InputProps={{ inputComponent: MaskedMobileInput }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Box display="flex" justifyContent="flex-end" style={{ marginTop: "10px" }}>
                <Button color="primary" onClick={() => setShowPassword(!showPassword)}>
                      change password {" "}
                  <KeyboardArrowDownIcon
                    style={{ transform: showPassword ? "rotate(180deg)" : "", transition: "all 300ms" }}/>
                </Button>
              </Box>
              {showPassword &&
                  <Grid className={classes.passwordInputs} container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <FormTextField name="user_pass" label="Enter New Password" type="password" fullWidth disabled={!showPassword} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormTextField name="passwordConfirmation" label="Confirm New Password" type="password" fullWidth disabled={!showPassword} />
                    </Grid>
                  </Grid>}
            </Box>
            <Box className={classes.headerTextWrapper}>
              <Typography variant="h4" color="primary">
                  Primary Credit Card
              </Typography>
              {cardRequired && <Typography variant="subtitle2" style={{ color: "red" }}>Please add a credit card</Typography>}
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {userPaysource && !shouldRemoveCard ? (
                    <Box className={classes.creditCardContainer} >
                      <Box className={classes.numberCardContainer}>
                        <TextField
                          value={`**** - **** - **** - ${userPaysource.last4}`}
                          variant="outlined"
                          style={{ pointerEvents: "none" }}
                          fullWidth
                        />
                      </Box>

                      <Box className={classes.removeContainer} >
                        <Button
                          size="large"
                          color="secondary"
                          className={classes.removeButton}
                          onClick={(e) => setAnchorEl(e.currentTarget)}
                        >
                      Change Card
                        </Button>
                      </Box>

                      <Popover
                        classes={{ paper: classes.popover }}
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                      >
                        <Typography>Are you sure?</Typography>
                        <Box display="flex">


                          <Button onClick={() => setAnchorEl(null)}>No</Button>
                          <Button
                            onClick={() => {
                              setShouldRemoveCard(true)
                              setAnchorEl(null)
                            }}
                          >
                        Yes
                          </Button>
                        </Box>
                      </Popover>
                    </Box>
                  ) : (
                    <Box style={cardRequired ? { padding: 20, borderRadius: 4 } : {}}>
                      <Box className={classes.cardElement}>
                        <CardElement />
                      </Box>

                      {cardError && (
                        <Typography color="primary" variant="caption">
                          {cardError}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
          <DialogButton
            buttonRef={buttonRef}
            text="Save Changes"/>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default injectStripe(ProfileDialog)
