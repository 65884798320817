import { Itemimage } from "store/serializers/itemimage"
import model from "./itemimage"
import moment from "moment"

export default {
  auction_id: "number",
  cost: "number",
  high_bid: "number",
  raffle_price: "number",
  raffle_qty: "number",
  bid_increment: "number",
  buy_price: "number",
  high_bid_id: "number",
  high_bid_user_id: "number",
  item_id: "number",
  item_qty: "number",
  reserve: "number",
  sort_position: "number",
  sponsor_id: "number",
  status: "number",
  tickets_purchased: "number",
  value: "number",
  value_display: "number",
  is_live: "boolean",

  description: "string",
  full_description: "string",
  item_type: "string",
  marketplace_provider_id: "number",
  active: "boolean",
  at_event_ticketing: "boolean",
  is_raffle: "boolean",
  live_auction: "boolean",
  paid: "boolean",
  published: "boolean",
  refunded: "boolean",
  open_item: "boolean",
  user_high_bid: "number",

  image: (data, item) => {
    if (typeof data === "string") {
      data = JSON.parse(data)
    }
    if (data.length === 0) {
      data = [
        {
          original_image_name: "eg_placeholder_xhn1xm"
        }
      ]
    }
    return data.map(image => {
      return new Itemimage(model, {
        ...image,
        auctionCode: item.auction_id,
        isMarketplaceItem:
          item.marketplace_provider_id &&
          Number(item.marketplace_provider_id) > 1
      })
    })
  },

  tags: (data, item) => {
    if (typeof data === "string" && data.length > 0) {
      data = JSON.parse(data)
    } else if (data.length === 0) {
      data = []
    }
    return data.map(tag => typeof tag === "object" ? (tag.text) : tag)
  },

  close_time: (data, item) => {
    if (!moment(data).isValid()) {
      return null
    }
    return data
  },

  categories: "array",

  high_bid_data: "object"
}
