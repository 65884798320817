import React from "react"
import { Box, FormControl, Grid, IconButton, Input } from "@material-ui/core"
import RemoveIcon from "@material-ui/icons/Remove"
import AddIcon from "@material-ui/icons/Add"
import useStyles from "./styles"

const Incrementer = ({ defaultValue = 1, value, maxValue = null, minValue = 1, setter }) => {
  const classes = useStyles()

  const handleChange = (e) => {
    const newValue = e.currentTarget.value
    const regEx = /^\d+$/
    if (newValue !== "" && Number(newValue) < Number(minValue) || Number(newValue) > Number(maxValue)) {
      return
    }
    if (newValue !== "" && !regEx.test(String(newValue))) {
      return
    }
    setter(newValue)
  }

  const handleBlur = (e) => {
    const blurValue = e.currentTarget.value
    if (blurValue === "") {
      setter(defaultValue)
    }
  }

  const handleClick = (value, stepper) => {
    const newValue = Number(value) + Number(stepper)
    if (maxValue && newValue < minValue || newValue > maxValue) {
      return
    }
    setter(newValue)
  }

  return (
    <Box>
      <FormControl>
        <label className={classes.label} htmlFor="qty">Quantity</label>
        <Grid container alignItems="flex-end">
          <Grid item>
            <IconButton className={classes.iconButton} aria-label="Subtract" color="primary" disabled={value <= minValue} onClick={() => handleClick(value, -1)}>
              <RemoveIcon className={classes.icon} />
            </IconButton>
          </Grid>
          <Grid item>
            <Input
              inputProps={{ className: classes.input }}
              disableUnderline
              id="qty"
              type="number"
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item>
            <IconButton className={classes.iconButton} aria-label="Add" color="primary" disabled={value >= maxValue} onClick={() => handleClick(value, 1)}>
              <AddIcon className={classes.icon} />
            </IconButton>
          </Grid>
        </Grid>
      </FormControl>
    </Box>
  )
}

export default Incrementer
