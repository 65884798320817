import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  container: {
    padding: "0px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px"
    }
  },

  headerTextWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  actionsWrapper: {
    justifyContent: "center"
  },
  registerTextPhone: {
    fontSize: "1.2rem",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4)
    }
  },
  registerText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4)
    }
  },
  textPhone: {
    [theme.breakpoints.down("sm")]: {
      display: "flex"
    }
  }
}))

export default styles
