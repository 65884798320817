import React, { useState } from "react"
import { FormControl, OutlinedInput } from "@material-ui/core"
import useStyles from "./styles"
import { useFormikContext } from "formik"
import FormError from "components/FormError"

const PinInput = ({ disabled }) => {
  const classes = useStyles()

  const formik = useFormikContext()

  const [pin, setPin] = useState("")
  const [focused, setfocused] = useState(false)

  const handlePinChange = e => {
    const value = e.currentTarget.value
    if (value.length <= 4) {
      setPin(value)
    }
    if (value.length === 4) {
      formik.setFieldValue("pin", value)
      setTimeout(() => {
        formik.handleSubmit()
      }, 150)
    }
    if (value.length < 4) {
      formik.setFieldValue("pin", "")
    }
  }

  const handleFocus = () => {
    setfocused(true)
  }

  const handleBlur = () => {
    setfocused(false)
  }

  return (
    <>
      <div className={classes.pinInputWrapper}>
        <input
          className={classes.hiddenInput}
          value={pin}
          onChange={handlePinChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type="tel"
          autoFocus
        />
        <FormControl fullWidth className={classes.pinInput}>
          {focused && pin.length === 0 && (
            <span className={classes.blinkingCursor}>|</span>
          )}
          <OutlinedInput
            type="text"
            fullWidth
            inputProps={{
              required: true,
              className: classes.pinInputInput
            }}
            name="0"
            value={pin[0] || ""}
            id="pinInput0"
            autoComplete="nope"
            disabled={disabled}
          />
        </FormControl>
        <FormControl fullWidth className={classes.pinInput}>
          {focused && pin.length === 1 && (
            <span className={classes.blinkingCursor}>|</span>
          )}
          <OutlinedInput
            type="text"
            fullWidth
            inputProps={{
              required: true,
              className: classes.pinInputInput
            }}
            name="1"
            value={pin[1] || ""}
            id="pinInput1"
            autoComplete="nope"
            disabled={disabled}
          />
        </FormControl>
        <FormControl fullWidth className={classes.pinInput}>
          {focused && pin.length === 2 && (
            <span className={classes.blinkingCursor}>|</span>
          )}
          <OutlinedInput
            type="text"
            fullWidth
            inputProps={{
              required: true,
              className: classes.pinInputInput
            }}
            name="2"
            value={pin[2] || ""}
            id="pinInput2"
            autoComplete="nope"
            disabled={disabled}
          />
        </FormControl>
        <FormControl fullWidth className={classes.pinInput}>
          {focused && pin.length === 3 && (
            <span className={classes.blinkingCursor}>|</span>
          )}
          <OutlinedInput
            type="text"
            fullWidth
            inputProps={{
              required: true,
              className: classes.pinInputInput
            }}
            name="3"
            value={pin[3] || ""}
            id="pinInput3"
            autoComplete="nope"
            disabled={disabled}
          />
        </FormControl>
      </div>
      <FormError name="pin" />
    </>
  )
}

export default PinInput
