import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  container: {
    minHeight: "1200px"
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  list: {
    "& li": {
      fontSize: "1.6rem",
      lineHeight: "2rem",
      marginBottom: theme.spacing(2)
    }
  }
}))

export default styles
