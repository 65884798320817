import { useMemo } from "react"
import { useSelector } from "react-redux"

function useItemSponsor(item = {}) {
  const event = useSelector(state => state.event.data)
  return useMemo(() => {
    return event?.sponsors?.find((x) => x.sponsor_id === item.sponsor_id) || {}
  }, [
    item.sponsor_id,
    event
  ])
}

export default useItemSponsor
