import Record from "./base"
import { isEmpty } from "lodash"
import slugify from "slugify"
export class Item extends Record {
  constructor(...props) {
    super(...props)
    if (!isEmpty(this.high_bid_data)) {
      this.high_bid_data.high_bid_id = parseInt(
        this.high_bid_data.high_bid_id,
        10
      )
    }
    this.slug = slugify(this.name, {
      lower: "true"
    })
  }
  get bidAmount() {
    if (this.item_type === "fixed") {
      return this.cost
    } else if (this.item_type === "raffle") {
      return this.raffle_price
    } else {
      if (this.high_bid === 0) {
        return this.reserve
      } else {
        return this.high_bid + this.bid_increment
      }
    }
  }
  get formattedDescription() {
    if (this.full_description && this.full_description !== "...") {
      return this.full_description.replace(/\n/g, "<br>")
    } else if (this.description) {
      return this.description.replace(/\n/g, "<br>")
    } else {
      return ""
    }
  }

  get maxAvailable() {
    if (this.item_type === "fixed") {
      return this.item_qty - this.items_purchased
    } else {
      return this.raffle_qty - this.tickets_purchased
    }
  }

  get itemType() {
    if (this.item_type === "auction") {
      if (this.live_auction) {
        return "live"
      } else {
        return "slient"
      }
    } else if (this.item_type === "fixed") {
      if (this.open_item) {
        return "open"
      } else {
        return "fixed"
      }
    } else {
      return this.item_type
    }
  }
}
