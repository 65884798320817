import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  label: {
    // visibility: "hidden",
    // height: 0,
    marginBottom: 5,
    fontSize: "1.4rem",
    lineHeight: "1.4rem",
    fontWeight: "bold",
    textAlign: "center",
    color: theme.palette.text.primary,
    opacity: 0.6,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem"
    }
  },
  iconButton: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.5, 0)
    }
  },
  icon: {
    fontSize: "1.6rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem"
    }
  },
  input: {
    padding: 0,
    width: 35,
    fontSize: "2.4rem",
    fontWeight: "700",
    textAlign: "center",
    /* Chrome, Safari, Edge, Opera */
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    /* Firefox */
    "&::[type=number]": {
      "-moz-appearance": "textfield"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem"
    }
  }
}))

export default styles
