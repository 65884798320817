import { makeStyles } from "@material-ui/core/styles"
import hart from "assets/background_heart.svg"

const styles = makeStyles(theme => ({
  container: {
    position: "relative",
    backgroundImage: `url(${hart})`,
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "1800px",
    [theme.breakpoints.down("sm")]: {
      bakcgrounImage: "none"
    }
  },
  carouselText: {
    marginTop: theme.spacing(12),
    fontWeight: 700,
    textAlign: "left"
  }
}))

export default styles
