import Record from "./base"
import moment from "moment-timezone"

export class Event extends Record {
  constructor(...props) {
    super(...props)
    if (this.digitalsign_image) {
      if (this.digitalsign_image.indexOf("{") > -1) {
        this.digitalsign_image = JSON.parse(this.digitalsign_image)
        this.digitalsign_image = `//res.cloudinary.com/bidr/image/upload/d_defaultbrand.png/${this.digitalsign_image.image_version}/${this.auction_code}/${this.digitalsign_image.original_image_name}`
      }
    }

    if (this.event_logo) {
      if (this.event_logo.indexOf("{") > -1) {
        this.event_logo = JSON.parse(this.event_logo)
        this.event_logo = `//res.cloudinary.com/bidr/image/upload/d_eg_placeholder_xhn1xm.png/${this.event_logo.image_version}/${this.auction_code}/${this.event_logo.original_image_name}`
      } else if (this.event_logo === null) {
        this.event_logo = ""
      }
    }

    if (this.hasOwnProperty("event_date")) {
      if (this.time_zone) {
        this.startTimeLocal = moment
          .tz(`${this.event_date} ${this.start_time}`, this.time_zone)
          .local()
          .format("HH:mm:ss")
      } else {
        this.startTimeLocal = this.start_time
      }
    }

    if (this.hasOwnProperty("end_event_date")) {
      if (this.time_zone) {
        this.endTimeLocal = moment
          .tz(`${this.end_event_date} ${this.end_time}`, this.time_zone)
          .local()
          .format("HH:mm:ss")
      } else {
        this.endTimeLocal = this.end_time
      }
    }
  }
  get isTestMode() {
    if (
      typeof this.event_tracking === "string" &&
      this.event_tracking.indexOf("[") > -1
    ) {
      this.event_tracking = JSON.parse(this.event_tracking)
      if (
        this.event_tracking.filter(test => test.id === "isTestMode").length > -1
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
