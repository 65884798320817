import React from "react"
import useStyles from "./styles"
import { Box, Container, Link, Grid, Typography, IconButton, CircularProgress } from "@material-ui/core"
import footerBorder from "assets/footer_top.svg"
import Logo from "components/Logo"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import TwitterIcon from "@material-ui/icons/Twitter"
import phoneBorder from "assets/phone_footer_border.svg"
import { useSelector } from "react-redux"
import { Link as RouterLink } from "react-router-dom"

const Footer = ({ children }) => {
  const classes = useStyles()
  const isMobile = window.innerWidth < 600
  const { currentPage, sponsors = [] } = useSelector(state => ({ currentPage: state.page.data, sponsors: state.event.data.sponsors }))
  const showFooter = currentPage !== "404"


  if (!showFooter) {
    return null
  }

  return (
    <>
      {currentPage === {} ? <CircularProgress/> :
        <Box className={classes.container}>
          {/* <img src={footerBorder} alt="footer border" className={classes.borderImage}/> */}
          <svg className={classes.borderImage} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1279.001 29.944">
            <path id="Exclusion_1" data-name="Exclusion 1" d="M1279,29.944h0c-.2-.017-21.886-1.9-59.085-4.708-33.643-2.536-87.163-6.357-151.08-10.155C996.225,10.767,924.919,7.329,856.9,4.863,771.877,1.781,691.788.219,618.857.219S468.008,1.781,387.266,4.863c-64.6,2.466-131.126,5.9-197.744,10.218C130.256,18.919,81.879,22.756,51.732,25.3,19.578,28.011.787,29.865,0,29.943L0,0H1279V29.943Z" transform="translate(0)" fill="#fff"/>
          </svg>
          <Container className={classes.footerContainer}>
            <Grid container>
              <Grid className={classes.logoContainer} item sm={12} md={3}>
                <Logo className={classes.logo} />
                <Box display="flex" className={classes.iconContainer}>
                  <IconButton
                    className={classes.iconButtonContianer}
                    onClick={() => window.open("https://www.facebook.com/kindboost")} >
                    <FacebookIcon classes={{ root: classes.socialMediaIcons }} />
                  </IconButton>
                  <IconButton
                    className={classes.iconButtonContianer}
                    onClick={() => window.open("https://www.instagram.com/kindboost/")} >
                    <InstagramIcon classes={{ root: classes.socialMediaIcons }} />
                  </IconButton>
                  <IconButton
                    className={classes.iconButtonContianer}
                    onClick={() => window.open("https://twitter.com/kindboosts")} >
                    <TwitterIcon classes={{ root: classes.socialMediaIcons }} />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item sm={12} md={9}>
                <Typography className={classes.footerTitle}>
                  Charities we proudly support
                </Typography>
                <Box display="flex" flexWrap="wrap" marginTop={1}>
                  {sponsors.length && (
                    sponsors.map(sponsor => {
                      return (
                        <Box className={classes.footerLinkWrapper}>
                          <Link className={classes.footerLink} href={sponsor.link} target="_blank">{sponsor.sponsor_name}</Link>
                        </Box>
                      )
                    })
                  )}
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Box className={classes.copyrightContainer}>
                <Typography variant="body1" className={classes.copyrightText}>Copyright 2020 kindboost | <RouterLink className={classes.tcLink} to={{ pathname: "/terms-of-service" }}>Terms and Conditions</RouterLink></Typography>
              </Box>
            </Box>
          </Container>
        </Box>}
    </>
  )
}

export default Footer
