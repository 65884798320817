import React, { useState } from "react"
import { AppBar, Box, Button, Container, IconButton } from "@material-ui/core/"
import ShoppingBagSvg from "components/ShoppingBagSvg"
import { Link } from "react-router-dom"
import Logo from "components/Logo"
import useStyles from "./styles"
import { useSelector } from "react-redux"
import MenuIcon from "@material-ui/icons/Menu"
import UserMenu from "./UserMenu"
import { isEmpty } from "lodash"
import clsx from "clsx"
import PhoneDrawer from "components/PhoneDrawer"

const NavBar = ({ children }) => {
  const classes = useStyles()
  const authenticatedUser = useSelector(state => state.authentication.authenticatedUser)
  const whiteTheme = (window.location.pathname === "/" || window.location.pathname.includes("/item/") || window.location.pathname === "/about-us")
  const [open, setOpen] = useState(false)

  return (
    <AppBar className={classes.root} position="absolute">
      <Container className={classes.container}>
        <Box>
          <Link to="/">
            <Logo className={clsx(classes.logo, whiteTheme && classes.logoWhite)} />
          </Link>
        </Box>
        <Box className={classes.actions}>
          <Button className={clsx(classes.button, whiteTheme && classes.buttonWhite)} component={Link} to="/">
            Home
          </Button>
          <Button className={clsx(classes.button, whiteTheme && classes.buttonWhite)} component={Link} to="/items">
            Items
          </Button>
          <Button className={clsx(classes.button, whiteTheme && classes.buttonWhite)} component={Link} to="/about-us">
            About Us
          </Button>
          <UserMenu whiteTheme={whiteTheme} />
          {!isEmpty(authenticatedUser) && (
            <Button className={clsx(classes.button, whiteTheme && classes.buttonWhite)} component={Link} to="/my-items">
              <ShoppingBagSvg color={whiteTheme ? "#fff" : "#071334E6"} />
            </Button>
          )}
        </Box>
        <IconButton className={classes.mobileActions}
          edge="start" color="inherit" aria-label="menu"
          onClick={() => setOpen(true)}
        >
          <MenuIcon />
        </IconButton>
      </Container>
      <PhoneDrawer open={open} setOpen={setOpen}/>

    </AppBar>
  )
}

export default NavBar
