import React from "react"
import { Box, Button, Typography } from "@material-ui/core"
import ItemCarousel from "components/ItemCarousel"
import MobileCarousel from "components/MobileCarousel"
import useStyles from "./styles"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { useHistory } from "react-router-dom"
import useSessionStorage from "hooks/useSessionStorage"

const FeaturedItems = ({ featuredTitle, featuredButton, category, tag, itemsDisplayed, items, showActions = true }) => {
  const classes = useStyles()
  const history = useHistory()
  const isMobile = window.innerWidth < 600
  const [, setFilters] = useSessionStorage("items_filters", {})

  const handleClick = () => {
    const categories = category !== "" ? [category] : []
    const tags = tag !== "" ? [tag] : []
    setFilters(() => ({ categories: categories, itemTypeCheckboxes: tags, showAdvanced: true }))

    setTimeout(() => history.push("/items"), 250)
  }
  return (
    <Box marginBottom={12}>
      { showActions && <Box className={classes.title} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">
          {featuredTitle}
        </Typography>
        { isMobile && itemsDisplayed > 2 &&
          <Button color="primary" variant="text" onClick={() => handleClick()}><span>SEE ALL</span><ArrowForwardIcon /></Button>
        }
        { !isMobile &&
          <Button color="primary" variant="contained" onClick={() => handleClick()}>{featuredButton}</Button>
        }
      </Box>}
      { !isMobile && <ItemCarousel itemsDisplayed={itemsDisplayed} items={items} />}
      { isMobile && <MobileCarousel items={items} />}
      { showActions && itemsDisplayed === 2 && (
        <Box className={classes.bottomButton} display="flex" justifyContent="center" marginTop={5}>
          <Button variant="contained" color="primary" onClick={() => handleClick()}>
            {featuredButton}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default FeaturedItems
