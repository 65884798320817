import React from "react"

import createNumberMask from "utils/numberFormat"
import MaskedInput from "react-text-mask"
const numberMask = createNumberMask({
  prefix: "$",
  integerLimit: 9
})


const MaskedBid = ({ classes, inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={
        numberMask
      }
      guide={false}
      showMask={false}
      keepCharPositions
    />
  )
}
export default MaskedBid
