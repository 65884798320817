import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  papper: {
    minWidth: "920px",
    background: "linear-gradient(45deg, #F440BD 30%, #FF4E40 90%)",
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
      overflowY: "hidden "
    }
  },

  closeButton: {
    color: "white"
  },
  title: {
    fontSize: "6.5rem",
    fontWeight: "bold",
    lineHeight: "8rem",
    color: "white",
    textAlign: "center",
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(15),
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
      lineHeight: "4rem",
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4)

    }
  },
  titleContainer: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "95%"
    }
  },
  subtitleContainer: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "95%"
    }
  },
  subtitle: {
    color: "white",
    textAlign: "center",
    fontSize: "2rem",
    lineHeight: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
      lineHeight: "2.2rem"
    }
  },
  buttonContainer: {
    justifyContent: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    }
  },
  exploreButton: {
    backgroundColor: "white",
    color: theme.palette.secondary.main
  },
  hartPath: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      top: "40%",
      width: "90%"
    }
  }
}))

export default styles
