export const CREATE_PACK_DIALOG_CLOSE = "CREATE_PACK_DIALOG_CLOSE"
export const LOGGING_IN = "AUTHENTICATION::LOGGING_IN"
export const LOGIN_ERROR = "AUTHENTICATION::LOGIN_ERROR"
export const LOGIN_SUCCESS = "AUTHENTICATION::LOGIN_SUCCESS"
export const SET_USER = "AUTHENTICATION::SET_USER"
export const UPDATE_LOGGED_IN_USER = "AUTHENTICATION::UPDATE_LOGGED_IN_USER"
export const REGISTERING = "AUTHENTICATION::REGISTERING"
export const REGISTER_ERROR = "AUTHENTICATION::REGISTER_ERROR"
export const REGISTER_SUCCESS = "AUTHENTICATION::REGISTER_SUCCESS"
export const LOGOUT = "AUTHENTICATION::LOGOUT"
