import React, { useState, useEffect, useLayoutEffect, useMemo } from "react"
import useStyles from "./styles"
import Router from "router"
import {
  Container,
  Typography,
  Tabs,
  Tab,
  Grid,
  Box,
  Divider,
  Hidden,
  Zoom
} from "@material-ui/core"
import MyItemCard from "components/MyItemCard"
import { useDispatch, useSelector } from "react-redux"
import { getMyItems } from "actions/items"
import { isEmpty } from "lodash"
import { openDialog } from "actions/dialogs"
import isClosed from "utils/isClosed"
import getPublishedItems from "utils/getPublishedItems"
import usePageTitle from "hooks/usePageTitle"

const MyItems = ({ routes = [], ...rest }) => {
  const [itemStatusTab, setItemStatusTab] = useState(0)
  const [itemTypeTab, setItemTypeTab] = useState(0)
  const [loginPrompt, setLoginPrompt] = useState(false)
  const authenticatedUser = useSelector(
    (state) => state.authentication.authenticatedUser
  )
  const myItems = useSelector((state) => state.items.myItems)

  // Update page title
  usePageTitle("My Items")

  const hasItemsClosed = useMemo(() => {
    return myItems.filter((x) => x.status === 2).length > 0
  }, [myItems])

  useEffect(() => {
    if (hasItemsClosed) {
      setItemStatusTab(1)
    }
  }, [hasItemsClosed])

  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    if (myItems.length === 0) {
      if (!isEmpty(authenticatedUser)) {
        dispatch(
          getMyItems(process.env.REACT_APP_EVENT_ID, authenticatedUser.user_id)
        )
        setLoginPrompt(true)
      } else if (!loginPrompt) {
        setLoginPrompt(true)
        dispatch(openDialog("login"))
      }
    }
  }, [authenticatedUser])

  return (
    <Box
      flexDirection="column"
      display="flex"
      className={classes.myItemsContainer}
    >
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" className={classes.title}>
            My Items
          </Typography>
        </Box>

        <Grid container spacing={4}>
          <Grid item lg={12} md={12}>
            <Box display="flex">
              <Tabs
                classes={{ indicator: classes.vartTabIndicator2 }}
                orientation="horizontal"
                value={itemStatusTab}
                onChange={(_, val) => setItemStatusTab(val)}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  classes={
                    itemStatusTab === 1 ?
                      {
                        textColorPrimary: classes.color,
                        root: classes.vartTabRoot
                      } :
                      { root: classes.vartTabRoot }
                  }
                  value={0}
                  color="primary"
                  label={"Active"}
                />

                <Tab
                  classes={
                    itemStatusTab === 0 ?
                      {
                        textColorPrimary: classes.color,
                        root: classes.vartTabRoot
                      } :
                      { root: classes.vartTabRoot }
                  }
                  value={1}
                  color="primary"
                  label={"Closed / Won"}
                />
              </Tabs>
            </Box>
            {isEmpty(authenticatedUser) ? (
              <Box
                display="flex"
                justifyContent="center"
                style={{ marginTop: "20px" }}
              >
                <Typography>Please sign in to view My Items</Typography>
              </Box>
            ) : (
              <Box className={classes.itemsWrapper}>
                <Hidden mdDown>
                  <Grid container>
                    <Grid item sm={9}>
                      <Typography className={classes.itemText}>Item</Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Typography className={classes.endedText}>
                        {itemStatusTab === 0 ? "Ends" : "Ended"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                </Hidden>
                {
                  myItems
                    .filter((item) =>
                      Number(itemStatusTab) ?
                        isClosed(item) || item.item_type === "fixed" :
                        !isClosed(item) && item.published && item.item_type !== "fixed"
                    )
                    .map((item) => (
                      <MyItemCard
                        key={item.item_id}
                        item={item}
                        itemStatusTab={itemStatusTab}
                        itemTypeTab={itemTypeTab}
                      />
                    ))}
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
      <Box flexGrow={1} />
      <Router routes={routes} {...rest} />
    </Box>
  )
}

export default MyItems
