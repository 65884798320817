const getItemAmount = item => {
  if (item.item_type === "fixed") {
    return item.cost
  } else if (item.item_type === "raffle") {
    return item.raffle_price
  } else {
    if (item.high_bid === 0) {
      return item.reserve
    } else {
      return item.high_bid + item.bid_increment
    }
  }
}

export default getItemAmount
