import { useEffect } from "react"
import { useLocation } from "react-router-dom"

export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    const app = document.querySelector("#app-container")
    if (app) {
      app.scrollTo(0, 0)
    }
  }, [pathname])

  return null
}
