import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(32),
    marginBottom: theme.spacing(28),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(16),
      marginBottom: theme.spacing(48)
    }
  },
  contentContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  imageContainer: {
    position: "absolute",
    top: "-120px",
    left: "100px",
    width: "530px",
    height: "530px",
    [theme.breakpoints.down("sm")]: {
      top: 35,
      left: "unset",
      right: -62,
      width: 280
    }
  },
  heartImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 3,
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(45deg)"
    }
  },
  logo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 170,
    height: "100%",
    color: "#fff",
    zIndex: 5,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      top: "20%"
    }
  },
  trail: {
    position: "absolute",
    left: "-25%",
    bottom: "24%",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      top: 0,
      width: "50%",
      transform: "rotate(45deg)"
    }
  },
  bottomCircle: {
    position: "absolute",
    left: "15%",
    bottom: "-11%",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      top: 120,
      left: -25,
      width: "50%"
    }
  },
  rightCircle: {
    position: "absolute",
    right: "-12%",
    bottom: "15%",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      top: 150,
      right: 20,
      width: "50%"
    }
  }
}))

export default styles
