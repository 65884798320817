import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  root: {
    position: "relative",
    height: "80vh",
    // width: "100%",
    [theme.breakpoints.down("sm")]: {
      // marginTop: 72
    }
  },
  contentContainer: {
    height: "100%"
  },
  content: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    width: "50%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  heading: {
    color: "#fff",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  description: {
    color: "#fff",
    marginBottom: theme.spacing(2),
    opacity: 1
  },
  text: {
    display: "-webkit-box",
    "-webkit-line-clamp": 6,
    "-webkit-box-orient": "vertical",
    overflow: "hidden"
  },
  bottomBorder: {
    position: "absolute",
    bottom: "-1px",
    left: 0,
    width: "100%",
    pointerEvents: "none"
  },
  carouselContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden"
  },
  carouselRoot: {
    "&.carousel.carousel-slider": {
      height: "100%"
    },
    height: "100%",
    "& .slide": {
      height: "100%",
      "&:after": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        display: "block",
        width: "100%",
        height: "100%",
        background: "transparent linear-gradient(181deg, #D83AA8 0%, #7B2D27 100%) 0% 0% no-repeat padding-box",
        opacity: 0.76
      }
    },
    "& .carousel .slide .legend": {
      bottom: "unset",
      top: 0,
      padding: theme.spacing(3),
      marginLeft: 0,
      transform: "translateX(-50%)",
      background: "transparent",
      textAlign: "left",
      opacity: 1,
      zIndex: 10,
      [theme.breakpoints.down("md")]: {
        width: "100%"
      }
    },
    "& .control-dots": {
      position: "absolute",
      height: "auto",
      left: "50%",
      transform: "translateX(-50%)",
      width: "auto",
      bottom: 150,
      [theme.breakpoints.down("sm")]: {
        bottom: 80
      },
      "& .dot": {
        outline: "none"
      }
    }
  },
  checkButton: {
    backgroundColor: "white",
    color: theme.palette.secondary.main
  }
}))

export default styles
