import {
  EVENT_SET,
  EVENT_LOADING,
  EVENT_UPDATE
} from "types/actions/event"
import update from "immutability-helper"

const eventInitialState = {
  data: {},
  loading: true
}

export default (state = eventInitialState, action) => {
  switch (action.type) {
    case EVENT_SET:
      return update(state, {
        data: {
          $set: action.data
        }
      })
    case EVENT_LOADING:
      return update(state, {
        loading: {
          $set: action.loading
        }
      })
    case EVENT_UPDATE:
      return update(state, {
        event: {
          $set: {
            ...state.data, ...action.data
          }
        }
      })
    default:
      return state
  }
}
