import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Menu, MenuItem, Button } from "@material-ui/core"
import AccountCircle from "@material-ui/icons/AccountCircleOutlined"
import { isEmpty } from "lodash"
import { openDialog } from "actions/dialogs"
import { logout } from "actions/authentication"
import { setMyItems } from "actions/items"

const UserMenu = ({ whiteTheme }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const dispatch = useDispatch()


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handeMenuItemClick = item => {
    if (item === "edit-profile") {
      dispatch(openDialog("profile"))
        .then(res => console.log(res)).catch(err => console.log(err))
    } else if (item === "logout") {
      dispatch(logout())
      dispatch(setMyItems([]))
    }
    handleClose()
  }

  const authenticatedUser = useSelector(state => state.authentication.authenticatedUser)

  return (
    <>
      {isEmpty(authenticatedUser) ?
        <Button
          onClick={() => {
            dispatch(openDialog("login"))
          }}
          style={whiteTheme ? { color: "#fff" } : { color: "#071334E6" }}
        >
          Sign In
        </Button> :
        <Button
          onClick={handleClick}
          style={whiteTheme ? { color: "#fff" } : { color: "#071334E6" }}
        >
          <AccountCircle />
        </Button>
      }
      <Menu
        id="simple-menu"
        MenuListProps={{
          disablePadding: true
        }}
        // sets getContentAnchorEl to null to fix changing menu Popover position
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem onClick={() => handeMenuItemClick("edit-profile")}>Edit Profile</MenuItem>
        <MenuItem onClick={() => handeMenuItemClick("logout")}>Logout</MenuItem>
      </Menu>
    </>
  )
}

export default UserMenu
