export default {
  sponsors: "hasMany",
  require_cc: "boolean",
  event_tracking: "array",
  is_live: "boolean",
  show_date: "boolean",
  show_start_time: "boolean",
  show_end_date: "boolean",
  show_end_time: "boolean",
  show_address: "boolean"
}
