import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(20)
  },
  title: {
    marginBottom: theme.spacing(10)
  }
}))

export default styles
