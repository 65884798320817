import useCountdown from "hooks/useCountdown"

const timer = (time, type) => {
  if (!isNaN(time.remaining?.days)) {
    if (type !== "countdown") {
      return "Opening soon!"
    }
    if (time.remaining?.months > 0) {
      return type === "countdown" ?
        `Ends in ${time.remaining?.months}mo ${time.remaining?.days}d` :
        `Opens in ${time.remaining?.months}mo ${time.remaining?.days}d`
    } else if (time.remaining?.days > 0) {
      return type === "countdown" ?
        `Ends in ${time.remaining?.days}d ${time.remaining?.hours}h ${time.remaining?.minutes}m` :
        `Opens in ${time.remaining?.days}d ${time.remaining?.hours}h ${time.remaining?.minutes}m`
    } else if (time.remaining?.days === 0 && time.remaining?.hours === 0 && time.remaining?.minutes === 0) {
      return type === "countdown" ?
        `Ends in ${time.remaining?.seconds}s` :
        `Opens in ${time.remaining?.seconds}s`
    } else if (time.remaining?.days === 0 && time.remaining?.hours === 0) {
      return type === "countdown" ?
        `Ends in ${time.remaining?.minutes}m ${time.remaining?.seconds}s` :
        `Opens in ${time.remaining?.minutes}m ${time.remaining?.seconds}s`
    } else {
      return type === "countdown" ?
        `Ends in ${time.remaining?.hours}h ${time.remaining?.minutes}m ${time.remaining?.seconds}s` :
        `Opens in ${time.remaining?.hours}h ${time.remaining?.minutes}m ${time.remaining?.seconds}s`
    }
  }

  return ""
}

export default timer
