import React, { useEffect, useLayoutEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import useStyles from "./styles"
import { Box, Button, Container } from "@material-ui/core"
import moment from "moment"
import HomeHeader from "components/HomeHeader"
import AboutUs from "components/AboutUs"
import SponsorCarousel from "components/SponsorCarousel"
import FeaturedItems from "components/FeaturedItems"
import logoTrail from "assets/logo_trail.svg"
import circle from "assets/dotted_circle.svg"
import usePublished from "hooks/usePublished"
import useSessionStorage from "hooks/useSessionStorage"

const Index = () => {
  const classes = useStyles()
  const history = useHistory()
  const items = usePublished()
  const [featuredItems, setFeaturedItems] = useState({})
  const [endingSoonItems, setEndingSoonItems] = useState({})
  const [newItems, setNewItems] = useState({})
  const [travelItems, setTravelItems] = useState({})
  const [experienceItems, setExperienceItems] = useState({})
  const [thisTooShallPassItems, setThisTooShallPassItems] = useState({})
  const [, setFilters] = useSessionStorage("items_filters", {})

  // Update page title
  useLayoutEffect(() => {
    if (document.title !== "kindboost") {document.title = "kindboost"}
  }, [])

  useEffect(() => {
    setFeaturedItems(() => {
      return getSortedItems(items, "featured", "tags")
    })

    setEndingSoonItems(() => {
      return items.filter(item => {
        const timeDiff = moment(item.close_time).diff(moment(), "days")
        return timeDiff < 3 && timeDiff > 0
      })
    })

    setNewItems(() => {
      return items.filter(item => {
        const timeDiff = moment().add(1, "days").diff(moment(item.created), "days")
        return timeDiff < 3 && timeDiff > 0
      })
    })

    setTravelItems(() => {
      return getSortedItems(items, "travel")
    })

    setExperienceItems(() => {
      return getSortedItems(items, "experience")
    })

    setThisTooShallPassItems(() => {
      return getSortedItems(items, "this-too-shall-pass", "tags")
    })
  }, [items])

  function getSortedItems(items, term, field = "categories") {
    return items.filter(item => {
      const noSpace = term.replaceAll("-", "")
      const noSpaceRegex = new RegExp(noSpace, "i")
      const regex = new RegExp(term, "i")

      return regex.test(item[field].join(",")) || noSpaceRegex.test(item[field].join(","))
    })
  }

  return (
    <>
      <HomeHeader items={featuredItems} />
      <AboutUs />
      <Container className={classes.container} maxWidth={false} style={{ overflow: "hidden", position: "relative" }}>
        <Container >
          <Box marginBottom={16}>
            <SponsorCarousel />
          </Box>
          {thisTooShallPassItems.length > 0 && <FeaturedItems
            featuredTitle="This Too Shall Pass"
            featuredButton="See all This Too Shall Pass items"
            category=""
            tag="this_too_shall_pass"
            itemsDisplayed={3}
            items={thisTooShallPassItems}
          />}
          {endingSoonItems.length > 0 && <FeaturedItems
            featuredTitle="Items Ending Soon"
            featuredButton="See items ending soon"
            category=""
            tag="ending"
            itemsDisplayed={3}
            items={endingSoonItems}
          />}
          { newItems.length > 0 && <FeaturedItems
            featuredTitle="New Items Just Added"
            featuredButton="See all new items"
            category=""
            tag="new"
            itemsDisplayed={3}
            items={newItems}
          />}
          { travelItems.length > 0 && <FeaturedItems
            featuredTitle="Travel"
            featuredButton="See all travel items"
            category="travel"
            itemsDisplayed={3}
            items={travelItems}
          /> }
          {experienceItems.length > 0 && <FeaturedItems
            featuredTitle="Experiences"
            featuredButton="See all experiences items"
            category="experiences"
            itemsDisplayed={3}
            items={experienceItems}
          />}
          <Box display="flex" justifyContent="center" marginBottom={12}>
            <Button variant="contained" color="primary" onClick={() => {
              setFilters(() => ({}))
              setTimeout(() => history.push("/items"), 250)
            }}>See all items</Button>
          </Box>

        </Container>
        <img src={logoTrail} className={classes.logoTrail} />
        <img src={circle} className={classes.circle1} />
        <img src={circle} className={classes.circle2} />
      </Container>
    </>
  )
}

export default Index
