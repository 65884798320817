import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  container: {
    backgroundColor: "#FFCFCB",
    position: "absolute",
    top: -15,
    right: 30,
    borderRadius: "5%",
    padding: theme.spacing(1)
  },
  text: {
    fontWeight: 700
  }
}))

export default styles
