import Record from "./base"

export class Itemimage extends Record {
  constructor(...props) {
    super(...props)
    const data = props[1]
    const lossy = "fl_lossy,f_auto"
    const headerCrop = "c_fill,g_face,w_2000,h_1000," + lossy + ",d_Kindboost_background_2x_cma66h.png"
    const headerCropMobile = "c_scale,w_800," + lossy + ",d_Kindboost_background_2x_cma66h.png"

    if (!data.image_crop) {
      data.image_crop = `${lossy},d_Kindboost_background_2x_cma66h.png`
    } else {
      data.image_crop += `,${lossy},d_Kindboost_background_2x_cma66h.png`
    }

    if (!data.image_version) {
      data.image_version = ""
    }

    this.url = encodeURI(
      `//res.cloudinary.com/bidr/image/upload/${data.image_crop}/${
        data.image_version
      }/${data.isMarketplaceItem ? "marketplace" : data.auctionCode}/${
        data.original_image_name
      }`
    )

    this.headerUrl = encodeURI(
      `//res.cloudinary.com/bidr/image/upload/${headerCrop}/${
        data.image_version
      }/${data.isMarketplaceItem ? "marketplace" : data.auctionCode}/${
        data.original_image_name
      }`
    )

    this.headerUrlMobile = encodeURI(
      `//res.cloudinary.com/bidr/image/upload/${headerCropMobile}/${
        data.image_version
      }/${data.isMarketplaceItem ? "marketplace" : data.auctionCode}/${
        data.original_image_name
      }`
    )
  }
}
