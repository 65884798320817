import React from "react"
import useStyles from "./styles"
import { DialogContent, IconButton, Box, Container } from "@material-ui/core"

import LoaderButton from "@bit/c_t.components.loader-button"


const DialogButton = ({ handleSubmit, buttonRef, disabled, text = "" }) => {
  const classes = useStyles()
  const props = {
    type: handleSubmit ? "button" : "submit"
  }

  if (props.type === "button") {
    props.onClick = handleSubmit
  }

  return (
    <>
      <Box display="flex" className={classes.actionsWrapper}>
        <LoaderButton color="primary"
          variant="contained"
          ref={buttonRef}
          className={classes.loaderButton}
          classoverrides={{ wrapper: classes.wrapper, button: { root: classes.root } }}
          {...props}
        >
          {text}
        </LoaderButton>
      </Box>

    </>
  )
}

export default DialogButton
