import {
  ITEMS_LOADING,
  ITEMS_UPDATE_ITEM,
  ITEMS_SET,
  MY_ITEMS_LOADING,
  MY_ITEMS_SET
} from "types/actions/items"
import API from "store/api"
import serialize from "store/serialize"
import param from "jquery-param"
import { omitBy, isNil } from "lodash"

export const setItems = data => ({
  type: ITEMS_SET,
  data
})

export const _setItemsLoading = (loading = false) => ({
  type: ITEMS_LOADING,
  loading
})

export const setMyItems = data => ({
  type: MY_ITEMS_SET,
  data
})

export const _setMyItemsLoading = (loading = false) => ({
  type: MY_ITEMS_LOADING,
  loading
})

export const updateItem = (itemId, updates) => ({
  type: ITEMS_UPDATE_ITEM,
  itemId,
  updates
})

export const getItems = (eventId) => {
  return dispatch => {
    dispatch(_setItemsLoading(true))
    return API.get(`/items/event/${eventId}`)
      .set("Content-Type", "application/json")
      // .retry(1, 500)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText)
        }

        return response
      })
      .then(response => response.body)
      .then(data => {
        const filteredData = data.filter(item => item.item_type === "auction" || item.item_type === "raffle" || item.item_type === "fixed")
        serialize(
          "item",
          filteredData
        ).then(response => {
          dispatch(
            setItems(
              response
                .sort((a, b) => a.sort_position - b.sort_position)
            )
          )
          return response
        })
      })
      .catch(err => {
        console.log(err.status)
      })
      .finally(() => {
        dispatch(_setItemsLoading(false))
      })
  }
}

export const getMyItems = (eventId, userId) => {
  return dispatch => {
    dispatch(_setItemsLoading(true))
    return API.get(`/items/event/${eventId}`)
      .query({
        user_id: userId,
        myitems: 1
      })
      // .retry(1, 500)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText)
        }

        return response
      })
      .then(response => response.body)
      .then(data => {
        serialize(
          "item",
          data.map(d => {
            d = {
              ...d,
              image: JSON.parse(d.image)
            }
            if (d.image.length === 0) {
              d.image = [
                {
                  original_image_name: "eg_placeholder_xhn1xm"
                }
              ]
            }
            return d
          })
        ).then(response => {
          dispatch(
            setMyItems(
              response
                .sort((a, b) => a.sort_position - b.sort_position)
            )
          )
          return response
        })
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(_setItemsLoading(false))
      })
  }
}

// const bidAMount = useItemAmount(item)
// dispatch(
//   placeBid({
//     item_id: item.item_id,
//     user_id: authenticatedUser.user_id,
//     bid_amt: customAmount || bidAMount,
//     send_sms: false
//   })

export const placeBid = data => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      API.post("/bid")
        .set("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8")
        .send(data)
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText)
          }

          return response
        })
        .then(response => {
          if (response.body.msg === "too low") {
            throw new Error("Bid too low")
          }
          return serialize("item", getState().items.data.map(x => {
            if (data.item_id === x.item_id) {
              return Object.assign(x, {
                high_bid: response.body.item_high_bid
              })
            } else {
              return x
            }
          }))
        })
        .then(items => {
          dispatch(setItems(items))
          resolve(items)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

// const itemAmount = useItemAmount(item)
// let data = {
//   user_id: authenticatedUser.user_id,
//   currency: "usd",
//   items: [],
//   pay_type: "cash",
//   paid: 0
// }
// if (item.open_item) {
//   data.items.push({
//     item_id: item.item_id,
//     amount: values.customAmount // custom donation amount typed in by the user
//   })
// } else {
//   for (let i = 0; i < values.quantity; i++) {
//     data.items.push({
//       item_id: item.item_id,
//       amount: itemAmount
//     })
//   }
// }
// dispatch(purchaseFixed(data))

export const purchaseFixed = data => {
  return dispatch => {
    const deferred = new Promise((resolve, reject) => {
      return API.post("/purchaseitem")
        .set("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8")
        .send(param(omitBy(data, isNil)))
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText)
          }

          return response
        })
        .then(response => response.body)
        .then(response => {
          if (
            response.msg === "item purchase" ||
            response.msg === "item purchased" ||
            response.msg === "item purchase paysession created"
          ) {
            resolve(response)
          } else {
            reject(response)
          }
        })
        .catch(reject)
    })
    return deferred
  }
}

// dispatch(purchaseRaffle({
//   item_id: item.item_id,
//   user_id: authenticateduser.user_id,
//   bid_amt: quantity, // custom quantity amount selected by the user
//   user_pay_id: authenticateduser.user_pay_id,
//   paysource: paysource, // paysource selected by the user
//   currency: "usd"
// }))

export const purchaseRaffle = (data) => {
  return (dispatch) => {
    const deferred = new Promise((resolve, reject) => {
      return API.post("/purchaseraffle")
        .set("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8")
        .send(param(omitBy(data, isNil)))
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText)
          }

          return response
        })
        .then((response) => response.body)
        .then(response => {
          if (
            response.msg === "raffle purchase" ||
            response.msg === "raffle purchase paysession created"
          ) {
            resolve(response)
          } else {
            reject(response)
          }
        })
    })
    return deferred
  }
}
