import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  poweredBy: {
    marginBottom: theme.spacing(1),
    fontSize: "1rem",
    lineHeight: "1rem",
    color: "#797F91"
  },
  logo: {
    width: "7.4rem"
  }
}))

export default styles
