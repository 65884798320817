import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  container: {
    padding: "0px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
      marginBottom: theme.spacing(4)
    }
  },
  bidTitle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4)
  },
  actionsWrapper: {
    justifyContent: "center",
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(5)

  },
  termsOfServiceText: {
    marginTop: theme.spacing(4)
  }
}))

export default styles
