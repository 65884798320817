import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  container: {
    minHeight: "1200px"
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  }
}))

export default styles
