import { createMuiTheme } from "@material-ui/core/styles"

let theme = createMuiTheme()

const primary = {
  main: "#FF4E40"
}
const secondary = {
  main: "#F440BD"
}

const text = {
  primary: "#071334E6",
  secondary: "#071334E6"
}

const success = {
  main: "#45CC3A"
}

// const error = {}

// const breakpoints = {
//   values: {
//     xs: 0,
//     sm: 600,
//     md: 960,
//     lg: 1280,
//     xl: 1920
//   }
// }

// const spacing = factor => factor * 8

theme = createMuiTheme({
  ...theme,
  // breakpoints,
  // spacing,
  palette: {
    primary,
    secondary,
    text,
    success
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    htmlFontSize: 10,
    h1: {
      fontSize: "5.4rem",
      lineHeight: "6.6rem",
      fontWeight: 800
    },
    h2: {
      fontSize: "5.4rem",
      lineHeight: "6.6rem",
      fontWeight: 800,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.8rem",
        lineHeight: "3.4rem"
      }
    },
    h3: {
      fontSize: "3.6rem",
      lineHeight: "4.4rem",
      fontWeight: 800,
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.1rem",
        lineHeight: "2.5rem"
      }
    },
    h4: {
      fontSize: "1.8rem",
      lineHeight: "2.2rem",
      fontWeight: 700
    },
    body1: {
      fontSize: "1.6rem",
      lineHeight: "2.0rem",
      fontWeight: 500
    },
    body2: {
      fontSize: "2.4rem",
      lineHeight: "3.2rem",
      fontWeight: 500,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
        lineHeight: "2.2rem"
      }
    },
    subtitle1: {
      fontSize: "1.2rem",
      lineHeight: "1.5rem",
      fontWeight: 300,
      color: "#FF4E40"
    },
    subtitle2: {
      fontSize: "1.2rem",
      lineHeight: "1.5rem",
      fontWeight: 500,
      opacity: ".6"
    },
    overline: {
      fontSize: "1.2rem",
      lineHeight: "1.5rem",
      fontWeight: 700,
      color: "#fff"
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        fontSize: "1.4rem",
        lineHeight: "1.8rem",
        fontWeight: 700
      },
      containedPrimary: {
        background: `transparent linear-gradient(255deg, ${secondary.main} 0%, ${primary.main} 100%)`,
        overflow: "hidden",
        "&:after": {
          content: "''",
          // background: `transparent linear-gradient(255deg, ${secondary.main} 0%, ${primary.main} 100%) 0% 0% no-repeat padding-box`,
          background: secondary.main,
          width: "100%",
          height: "100%",
          position: "absolute",
          right: "-100%",
          transition: "right .3s ease-in-out",
          zIndex: 5
        },
        "& .MuiButton-label": {
          position: "relative",
          zIndex: 10
        },
        "&:hover": {
          "&:after": {
            right: 0
          }
        },
        "&.Mui-disabled": {
          opacity: 0.5,
          color: "#fff"
        }
      },
      containedSecondary: {
        backgroundColor: "#fff",
        color: primary.main,
        transition: "background-color .3s ease-out, color .3s ease-out",
        "&:hover": {
          backgroundColor: primary.main,
          color: "#fff"
        }
      }
    },
    MuiDialog: {
      paper: {
        width: "100%"
        // height: '50%'
      }
    },
    MuiMenuItem: {
      root: {
        padding: [theme.spacing(2), "!important"]
      }
    },
    MuiFormLabel: {
      root: {
        "&$disabled": {
          color: "rgba(0,0,0,0.18)"
        }
      }

    },
    MuiInputBase: {
      root: {
        "&$disabled": {
          opacity: 0.3
        }
      }
    }
    // MuiInputLabel: {
    //   root: {
    //     fontSize: "1.5rem"
    //   }
    // }
  }


})

export default theme
