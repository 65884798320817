import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  containerStep1: {
    padding: "0px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px"

    }
  },
  containerStep2: {
    padding: "0px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px"
    }
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  closeWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: 0
  },
  actions: {
    justifyContent: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  registerText: {
    marginBottom: theme.spacing(4),
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2rem"
      }

    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem"
    }
  },
  passwordInputs: {
    marginTop: theme.spacing(3)
  },
  verified: {
    color: "rgba(0,0,0,0.3)"
  },
  verifiedSuccess: {
    color: theme.palette.success.main
  },
  progress: {
    position: "absolute",
    height: "30px",
    width: "30px"
  }
}))

export default styles
