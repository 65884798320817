import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  itemHeaderContainer: {
    position: "relative",
    paddingLeft: "74px",
    height: "640px",
    minWidth: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    "&:before": {
      height: "100%",
      content: "''",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: "transparent linear-gradient(180deg, #000000AC 0%, #000000 100%) ",
      opacity: "0.6"
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      height: "495px"
    }
  },
  daysLeftContainer: {
    // maxWidth: "169px",
    display: "inline-flex",
    padding: theme.spacing(0, 2),
    height: "44px",
    textAlign: "center",
    color: "white",
    background: "linear-gradient(45deg, #F440BD 30%, #FF4E40 90%)",
    marginBottom: theme.spacing(4),
    overflow: "hidden",
    position: "relative",
    zIndex: 10
  },
  contentContainer: {
    paddingTop: "200px",
    width: "867px",
    color: "#fff",
    overflow: "hidden",
    position: "relative",
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  titleText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.8rem",
      lineHeight: "3rem"
    }
  }

}))

export default styles
