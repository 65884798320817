import { ITEMS_SET, ITEMS_LOADING, MY_ITEMS_SET, MY_ITEMS_LOADING, ITEMS_UPDATE_ITEM } from "types/actions/items"
import update from "immutability-helper"

const itemsInitialState = {
  data: [],
  loading: false,
  loadingMyItems: false,
  myItems: [],
  updates: {}
}

export default (state = itemsInitialState, action) => {
  switch (action.type) {
    case ITEMS_SET:
      return update(state, {
        data: {
          $set: action.data
        }
      })
    case MY_ITEMS_SET:
      return update(state, {
        myItems: {
          $set: action.data
        }
      })
    case ITEMS_LOADING:
      return update(state, {
        loading: {
          $set: action.loading
        }
      })
    case MY_ITEMS_LOADING:
      return update(state, {
        loadingMyItems: {
          $set: action.loadingMyItems
        }
      })
    case ITEMS_UPDATE_ITEM:
      return update(state, {
        data: {
          $set: state.data.map(x => {
            if (action.itemId === x.item_id) {
              return { ...x, ...action.updates }
            } else {
              return x
            }
          })
        }
      })
    default:
      return state
  }
}
