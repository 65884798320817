import React from "react"
// import useStyles from "./styles"
import Router from "router"
import KitchenSink from "components/KitchenSink"

const Styles = ({ routes = [], ...rest }) => {
  // const classes = useStyles()

  return (
    <>
      <KitchenSink />
      <Router routes={routes} {...rest} />
    </>
  )
}

export default Styles
