import React, { Fragment, useState, useEffect, useLayoutEffect, useMemo } from "react"
import useStyles from "./styles"
import ItemHeader from "components/ItemHeader"
import ItemInformation from "components/ItemInformation"
import BidInsideItem from "components/BidInsideItem"
import { Container, Grid, CircularProgress, Box, Typography } from "@material-ui/core"
import FeaturedItems from "components/FeaturedItems"
import numberWithCommas from "utils/numberWithCommas"
import { useSelector } from "react-redux"
import Raffles from "components/Raffles"
import usePublished from "hooks/usePublished"
import usePageTitle from "hooks/usePageTitle"

let shippingText = {
  title: "Why do we use it?",
  text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
}

const ItemPage = ({ routes = [], match, history, ...rest }) => {
  const classes = useStyles()
  const itemId = +match.params.id
  const [loading, setLoading] = useState(true)
  const items = useSelector(state => state?.items?.data)
  const currentItem = useMemo(() => items.find(x => x.item_id === itemId), [items, itemId])
  const [carouselItems, setCarouselItems] = useState({})

  // Update page title
  usePageTitle(`Item ${currentItem?.name ? `| ${currentItem?.name}` : ""}`)

  useEffect(() => {
    setLoading(items.length === 0)
  }, [items])
  useEffect(() => {
    if (items.length > 0 && !currentItem) {
      history.push("/items")
    }
  }, [currentItem, items])

  useEffect(() => {
    let sortedItems = []


    if (currentItem) {
      if (currentItem.categories.length !== 0) {
        sortedItems = getSortedItems(items, currentItem.categories[0])
      } else {
        sortedItems = getSortedItems(items, "featured", "tags")
      }
    }

    setCarouselItems(sortedItems.filter(x => x.published))
  }, [currentItem])


  function getSortedItems(items, term, field = "categories") {
    return items.filter(item => {
      const regex = new RegExp(term, "i")
      return regex.test(item[field].join(","))
    })
  }
  return (
    <Fragment>
      {loading ?
        (<Box display="flex" justifyContent="center" height="100vh" style={{ marginTop: "100px" }}>
          <CircularProgress />
        </Box>) : (
          <Box>
            <ItemHeader currentItem={currentItem}/>
            <Grid container className={classes.container} wrap="wrap-reverse">
              <Grid item lg={8} xs={12}>
                <ItemInformation
                  currentItem={currentItem}
                  shippingText={shippingText}
                />
                {currentItem?.item_type === "raffle" && <Raffles currentItem={currentItem}/>}

              </Grid>
              <Grid item lg={4} xs={12}>
                <BidInsideItem
                  currentItem={currentItem}
                  numberWithCommas={numberWithCommas}
                />
              </Grid>
            </Grid>
            <Container>
              <Typography className={classes.carouselText} variant="body2">Other items you might be interested in</Typography>
              { carouselItems.length > 0 && <FeaturedItems
                featuredTitle={currentItem?.categories[0]}
                featuredButton={`See all ${currentItem.categories[0]} items`}
                category={currentItem.categories[0]}
                showActions={false}
                itemsDisplayed={3}
                items={carouselItems}
              /> }
            </Container>

            {/* <Router routes={routes} {...rest} /> */}
          </Box>
        )}
    </Fragment>
  )
}

export default ItemPage
