import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  container: {
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0)
    }
  },
  rafflesBox: {
    width: "361px",
    height: "295px",
    border: "1px solid rgb(112, 112, 112, 0.3)",
    padding: theme.spacing(4),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(5),
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "0 auto",
      marginBottom: theme.spacing(2)
    }
  },
  chancesText: {
    fontSize: "1.8rem",
    fontWeight: "bold"
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "0 auto",
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3)
    }
  },
  subtitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "0 auto",
      marginBottom: theme.spacing(5)
    }
  },
  divider: {
    width: "100%"
  },
  donateButton: {
    width: "60%"
  },
  numberText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    fontSize: "6.5rem",
    fontWeight: "bold"
  },
  entriesText: {
    marginBottom: theme.spacing(2),
    fontSize: "3rem",
    fontWeight: "bold"
  },
  popularImage: {
    width: "80px",
    height: "80px",
    position: "absolute",
    top: -20,
    right: -20
  },
  carouselText: {
    marginTop: theme.spacing(10),
    fontWeight: 700,
    textAlign: "center"
  }
}))

export default styles
