import moment from "moment"


const isClosed = (item) => {
  if (item.status === 2) {
    return true
  } else if (item.status !== 2 &&
    item.close_Time !== null &&
    moment().diff(item.close_time) > 0) {
    return true
  }
  return false
}

export default isClosed
