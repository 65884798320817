import React, { useRef, useMemo } from "react"
import useStyles from "./styles"
import {
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  ButtonGroup
} from "@material-ui/core"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { openDialog } from "actions/dialogs"
import { openSnackBar } from "actions/snack-bar"
import useItemAmount from "utils/getItemAmount"
import FormTextField from "components/FormTextField"
import MaskedBid from "components/MaskedBid"
import useCountdown from "hooks/useCountdown"
import accounting from "accounting"
import useItemSponsor from "hooks/useItemSponsor"
import scrollIntoView from "smooth-scroll-into-view-if-needed"
import numberWithCommas from "utils/numberWithCommas"
import getUserPaysource from "utils/getUserPaysource"
import isClosed from "utils/isClosed"
import getCurrentUserId from "utils/getCurrentUserId"
import timer from "utils/timer"
import FixedItem from "components/BidInsideItem/FixedItem"

const BidInsideItem = ({ currentItem }) => {
  const classes = useStyles()
  const buttonRef = useRef(null)
  const formRef = useRef()
  const dispatch = useDispatch()
  const userId = useSelector(
    (state) => state?.authentication?.authenticatedUser?.user_id
  )
  const timeLeft = useCountdown(currentItem?.close_time)
  const sponsor = useItemSponsor(currentItem)
  const timeLeftToOpen = useCountdown(currentItem.open_time)
  const userIsWinning = useMemo(() => {
    return +userId === +currentItem.high_bid_data.high_bid_user_id
  }, [currentItem, userId])

  const initialValues = useMemo(() => {
    const bid = useItemAmount(currentItem)
    /* eslint-disable */
    formRef?.current?.setFieldValue?.("bid", bid);
    /* eslint-enable */
    return { bid }
  }, [currentItem, formRef, userId])

  const bidSchema = Yup.object().shape({
    bid: Yup.mixed().test(
      "bid",
      `Your bid needs to be at least $${numberWithCommas(initialValues.bid)}`,
      (bid) => {
        bid = +bid.toString().replace(/[^\d]/g, "")
        return bid >= initialValues.bid
      }
    )
  })

  // Checks for paysource. If none, will prompt user for CC info.
  const checkForPaySource = (bidData) => {
    const userPaysource = getUserPaysource()

    if (userPaysource) {
      dispatch(openDialog("bidConfirmation", bidData)).catch((err) =>
        console.log(err)
      )
    } else {
      dispatch(
        openSnackBar({
          message: "Please add a credit card."
        })
      )
      dispatch(openDialog("profile", { cardRequired: true })).then((res) => {
        const userPaysource = getUserPaysource()

        if (userPaysource) {
          dispatch(openDialog("bidConfirmation", bidData)).catch((err) =>
            console.log(err)
          )
        }
      })
    }
  }
  const bid = (values, dialog) => {
    let itemId = currentItem.item_id
    let userID = userId
    let bidAmt = values.bid
    let sendSms = false
    const bidData = { itemId, userID, bidAmt, sendSms }

    if (userId) {
      checkForPaySource(bidData)
    } else {
      dispatch(openDialog(dialog))
        .then((res) => {
          if (res === "success") {
            const currentUserId = getCurrentUserId()
            const isHighBid =
              +currentUserId === +currentItem.high_bid_data.high_bid_user_id

            if (isHighBid === false) {
              // Update userId and check for pay source
              checkForPaySource({ ...bidData, userID: currentUserId })
            } else {
              dispatch(
                openSnackBar({
                  message: "You are already the top bid!"
                })
              )
            }
          } else if (res === "change") {
            return bid(values, dialog === "login" ? "register" : "login")
          }
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <Box className={classes.containter}>
      <Box display="flex" flexDirection="column">
        {sponsor.sponsor_name ? (
          <img
            src={sponsor.image_url.replace(/\/$/, "")}
            className={classes.sponsorImage}
          />
        ) : (
          <Box style={{ height: "50px" }} />
        )}
        <Typography variant="body2" className={classes.title}>
          {currentItem.name}
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          {currentItem.subtitle}
        </Typography>
        {!isClosed(currentItem) && /* currentItem.published && */ currentItem.item_type === "fixed" && (
          <FixedItem currentItem={currentItem} timeLeftToOpen={timeLeftToOpen} timeLeft={timeLeft} />
        )}


        {!isClosed(currentItem) &&
        /* currentItem.published && */ currentItem.item_type === "auction" && (
          <>
            {!userIsWinning ? (
              <>
                <Typography
                  variant="subtitle2"
                  className={classes.currentBidText}
                >
                  Minimum Bid
                </Typography>
                <Box display="flex">
                  <Typography
                    variant="body2"
                    className={classes.currentBidNumber}
                  >
                    {accounting.formatMoney(initialValues.bid, "$", 0)}
                  </Typography>
                  {/* <Typography variant="body1" color="primary"> {item.bids === 1 ? "bid" : "bids"}</Typography>  */}
                </Box>
              </>
            ) : (
              <Box>
                <Typography className={classes.highBidText} color="secondary">
                  Congrats! You are the high bidder!
                </Typography>
                <Typography
                  className={classes.highBidNumber}
                  variant="body2"
                  color="secondary"
                >
                  {accounting.formatMoney(currentItem.high_bid, "$", 0)}
                </Typography>
              </Box>
            )}
            <Box flexGrow={1} />

            <Box
              display="flex"
              style={{ width: "100%" }}
              className={classes.enterBidContainer}
            >
              <Formik
                validationSchema={bidSchema}
                initialValues={initialValues}
                onSubmit={(values) => bid(values, "register")}
                innerRef={formRef}
              >
                <Form style={{ width: "100%" }}>
                  <>
                    {timeLeftToOpen.isClosed ? (
                      <ButtonGroup
                        classes={{ root: classes.buttonGroupContainer }}
                      >
                        <Box className={classes.textFieldContainer}>
                          <FormTextField
                            bid
                            type="tel"
                            name="bid"
                            inputStyle={{ backgroundColor: "red" }}
                            variant="outlined"
                            errorClass={classes.error}
                            InputProps={{
                              inputComponent:
                                formRef?.current?.value?.bid !== "undefined" ?
                                  MaskedBid :
                                  null,
                              classes: {
                                root: classes.inputRadius,
                                input: classes.input,
                                focused: classes.focused
                              }
                            }}
                          />
                        </Box>
                        <Box className={classes.buttonContainer}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            classes={{ contained: classes.bidButtonShadow }}
                            className={classes.bidButton}
                            ref={buttonRef}
                          >
                            Bid Now
                          </Button>
                        </Box>
                      </ButtonGroup>
                    ) : null}
                    {currentItem.buy_price > 0 && (
                      <Typography style={{ marginTop: "20px" }}>
                        Buy Price: ${currentItem.buy_price}
                      </Typography>
                    )}
                  </>
                </Form>
              </Formik>
            </Box>
          </>
        )}
        {!isClosed(currentItem) && /* currentItem.published && */ currentItem.item_type === "raffle" && (
          <Button
            variant="contained"
            color="primary"
            classes={{ root: classes.enterButton }}
            onClick={() =>
              scrollIntoView(document.getElementById("raffle-wrapper"))
            }
          >
            Donate Now
          </Button>
        )}

        <h1>
          {currentItem.close_time !== null && (
            <Divider className={classes.divider} />
          )}
          {currentItem.open_time !== null ||
            (timeLeftToOpen.isClosed && (
              <Divider className={classes.divider} />
            ))}
          {currentItem.close_time !== null && (
            <Box
              className={classes.timerContainer}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              {timeLeftToOpen.isClosed ? (
                <Typography
                  variant="subtitle2"
                  className={classes.timeRemainningText}
                >
                  Time Remaining
                </Typography>
              ) : null}
              <Typography variant="body2" className={classes.timerText}>
                {isClosed(currentItem) /* || !currentItem.published */ ?
                  "Item Closed" :
                  currentItem.close_time === null || !timeLeftToOpen.isClosed ?
                    null :
                    timer(timeLeft, "countdown")}
              </Typography>
            </Box>
          )}
          {currentItem.open_time !== null && !timeLeftToOpen.isClosed && (
            <Box
              className={classes.timerContainer}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              {/* <Typography
                variant="subtitle2"
                className={classes.timeRemainningText}
              >
                Item Opens In
              </Typography> */}
              <Typography variant="body2" className={classes.timerText}>
                {timeLeftToOpen.isClosed ?
                  null :
                  timer(timeLeftToOpen, "coundtUp")}
              </Typography>
            </Box>
          )}
        </h1>
      </Box>
    </Box>
  )
}

export default BidInsideItem
