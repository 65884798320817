import React from "react"
import useStyles from "./styles"
import { Container, IconButton } from "@material-ui/core"
import { Carousel } from "react-responsive-carousel"
import { Box } from "@material-ui/core"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
const Details = ({ currentItem }) => {
  const classes = useStyles()
  const isMobile = window.innerWidth < 600
  let newImageArray = [...currentItem.image]
  // let clickHandler = ArrowBackIosIcon

  return (
    <Container classes={{ root: classes.container }}>
      <Box className={classes.carouselContainer}>
        {currentItem.image.length > 1 ?
          <Carousel
            className={classes.carouselRoot}
            showArrows
            showStatus={false}
            showThumbs={false}
            autoPlay
            infiniteLoop
            swipeable={false}
            interval={8000}
            renderArrowPrev={(clickHandler) => {
              return (
                <IconButton className={classes.arrowPrev}>
                  <ChevronLeftIcon
                    onClick={clickHandler}
                    className={classes.prev}/>
                </IconButton>
              )
            }}
            renderArrowNext={(clickHandler) => {
              return (
                <IconButton className={classes.arrowNext}>
                  <ChevronRightIcon
                    onClick={clickHandler}
                    className={classes.next}/>
                </IconButton>
              )
            }}

          >
            {newImageArray.reverse()?.map((image, i) => (
              <Box key={i}>
                <img src={isMobile ? image.headerUrlMobile : image.headerUrl}/>
              </Box>
            ))}
          </Carousel> : <img src={isMobile ? currentItem.image[0].headerUrlMobile : currentItem.image[0].headerUrl} className={classes.headerImage}/>
        }
      </Box>
      {/* <Typography >{currentItem.description}</Typography> */}
      <div className={classes.itemText} dangerouslySetInnerHTML={{ __html: currentItem.full_description }} />
    </Container>
  )
}

export default Details
