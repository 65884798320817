import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      margin: 0
    }
  },

  carouselContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    width: "100%"
  },
  carouselRoot: {
    height: "400px",
    [theme.breakpoints.down("sm")]: {
      height: "200px"
    },
    "& .control-dots": {
      position: "absolute",
      height: "auto",
      left: "50%",
      transform: "translateX(-50%)",
      width: "auto",
      bottom: 10,
      "& .dot": {
        outline: "none"
      }
    }
  },
  headerImage: {
    width: "100%",
    height: "600px",
    objectFit: "cover",
    // objectPosition: "top",
    [theme.breakpoints.down("sm")]: {
      height: "200px"
    }

  },
  arrowPrev: {
    position: "absolute",
    top: "50%",
    borderRadius: "50%",
    zIndex: 1,
    left: 10,
    cursor: "pointer"
  },
  arrowNext: {
    position: "absolute",
    top: "50%",
    borderRadius: "50%",
    zIndex: 1,
    right: 10,
    cursor: "pointer"
  },
  prev: {
    color: "white"
  },
  next: {
    color: "white"
  },
  itemText: {
    whiteSpace: "pre-wrap"
  }
}))

export default styles
