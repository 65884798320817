import { makeStyles } from "@material-ui/core/styles"
const styles = makeStyles(theme => ({
  container: {
    paddingLeft: "74px",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px"
    }
  },
  labelText: {
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  },
  logoTrail: {
    position: "absolute",
    top: "200px",
    left: "-90px",
    width: "200px",
    height: "500px",
    transform: "rotate(90deg) scaleY(-1)",
    opacity: "0.3"
  },
  circle1: {
    position: "absolute",
    top: "490px",
    left: "-200px",
    opacity: "0.3"
  },
  circle2: {
    position: "absolute",
    top: "680px",
    left: "-200px",
    opacity: "0.3"
  }
}))

export default styles
