import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  containter: {
    backgroundColor: "#FFF9F9",
    textAlign: "left",
    // minHeight: "695px",
    opacity: "100%",
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    position: "sticky",
    top: "20px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  sponsorImage: {
    margin: "0 auto",
    width: "195px",
    height: "64px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      width: "90%",
      height: "200px",
      objectFit: "cover"
    },
    [theme.breakpoints.down("sm")]: {
      height: "100px"
    }
  },
  title: {
    marginBottom: theme.spacing(5),
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px"
    }
  },
  subtitle: {
    marginBottom: theme.spacing(5)
  },
  currentBidText: {
    marginBottom: theme.spacing(0.1),
    fontSize: "1.4rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem"
    }
  },
  currentBidNumber: {
    fontWeight: "bold"
  },
  enterBidContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5)
  },
  highBidText: {
    fontSize: "1.4rem",
    fontWeight: "bold"
  },
  highBidNumber: {
    fontWeight: "bold"
  },

  enterButton: {
    fontSize: "2.5rem",
    height: "54px",
    marginBottom: theme.spacing(5)
  },
  timerContainer: {
    marginTop: theme.spacing(5)
  },
  timeRemainningText: {
    marginBottom: theme.spacing(1),
    fontSize: "1.4rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem"
    }
  },
  timerText: {
    fontWeight: "bold"
  },
  progress: {
    color: "white"
  },
  buttonGroupContainer: {
    width: "100%"
  },
  textFieldContainer: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "60%"
    }
  },
  buttonContainer: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "40%"
    }
  },
  input: {
    padding: "0px",
    height: "56px",
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem"
    }
  },
  input2: {
    width: "100%"
  },
  inputRadius: {
    "-webkit-border-radius": "4px 0px 0px 4px",
    borderRadius: "4px 0px 0px 4px !important",
    height: "100%",
    width: "100%"
  },
  bidButton: {
    width: "100%",
    "-webkit-border-radius": "0px 4px 4px 0px",
    borderRadius: "0px 4px 4px 0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem"
    }
  },
  bidButtonShadow: {
    boxShadow: "none",
    height: "56px",
    width: "100%"
  },
  focused: {
    height: "55px",
    marginTop: "1px"
  }


}))

export default styles
