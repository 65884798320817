import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  "@keyframes blink": {
    "from, to": {
      color: theme.palette.text.primary
    },
    "50%": {
      color: "transparent"
    }
  },
  blinkingCursor: {
    fontWeight: "100",
    fontSize: "30px",
    color: "#2E3D48",
    animation: "1s $blink step-end infinite",
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  pinInputWrapper: {
    display: "flex",
    justifyContent: "space-around",
    margin: `0 -${theme.spacing() / 2}px`,
    position: "relative"
  },
  hiddenInput: {
    position: "absolute",
    margin: "0",
    padding: "0",
    backgroundColor: "transparent",
    border: "none",
    width: "100%",
    height: "100%",
    zIndex: "1",
    outline: "none",
    color: "transparent"
  },
  pinInput: {
    margin: `0 ${theme.spacing() / 2}px`
  },
  pinInputInput: {
    textAlign: "center",
    padding: "16px 0",
    fontSize: "20px"
  }
}))

export default styles
