import React from "react"
import { Carousel } from "react-responsive-carousel"
import useStyles from "./styles"
import { Box, Container, IconButton } from "@material-ui/core"
import CarouselCard from "components/CarouselCard"
import ArrowBack from "@material-ui/icons/ArrowBackIos"
import ArrowForward from "@material-ui/icons/ArrowForwardIos"
import clsx from "clsx"
import _ from "lodash"

const ItemCarousel = ({ items, itemsDisplayed = 3 }) => {
  const classes = useStyles()
  const isSwipeable = window.innerWidth < 600
  let itemsSorted = []

  itemsSorted = _.chunk(items, itemsDisplayed)
  const lastItemGroup = itemsSorted.length - 1
  const diff = Number(itemsDisplayed) - Number(itemsSorted[lastItemGroup].length)

  // if (lastItemGroup > 0) {
  if (diff > 0) {
    for (let i = 0; i < diff; i++) {
      itemsSorted[itemsSorted.length - 1].push({})
    }
  }
  // }

  return (
    <Container>
      <Carousel
        className={clsx(classes.carouselRoot, (isSwipeable && itemsDisplayed > 2) && classes.mobileCarousel)}
        showIndicators={isSwipeable && itemsDisplayed === 2}
        showArrows={!isSwipeable}
        showStatus={false}
        showThumbs={false}
        emulateTouch
        autoPlay={!isSwipeable}
        infiniteLoop
        interval={8000}
        swipeable={isSwipeable}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <IconButton className={classes.backButton} onClick={onClickHandler} title={label} aria-label={label}>
              <ArrowBack className={classes.backArrow}/>
            </IconButton>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <IconButton className={classes.forwardButton} onClick={onClickHandler} title={label} aria-label={label}>
              <ArrowForward className={classes.forwardArrow}/>
            </IconButton>
          )
        }
      >
        {itemsSorted && itemsSorted.map((items, index) => (
          <Box className={clsx(classes.contentSlide, itemsDisplayed === 2 && classes.mobileStacked)} key={`${Math.ceil(Math.random() * 1000)}`}>
            {items.map((item, index) => (<CarouselCard key={`${item.item_id}-${index * Math.ceil(Math.random() * 100)}`} item={item} />))}
          </Box>
        ))}
      </Carousel>
    </Container>
  )
}

export default ItemCarousel
