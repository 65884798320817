import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  root: {
    background: "transparent",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      background: "transparent linear-gradient(98deg, #FF4E40 0%, #F440BD 100%);"
    }
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: `${theme.spacing(4)}px`,
    paddingBottom: `${theme.spacing(4)}px`,
    [theme.breakpoints.down("sm")]: {
      paddingTop: `${theme.spacing(2)}px`,
      paddingBottom: `${theme.spacing(2)}px`
    }
  },
  logo: {
    fill: "url(#logoGradient)",
    width: 150,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      fill: "#fff",
      width: 125
    }
  },
  logoWhite: {
    fill: "#fff"
  },
  button: {
    color: theme.palette.text.primary
  },
  buttonWhite: {
    color: "#fff"
  },
  actions: {
    display: "flex",
    "& a, & button": {
      fontSize: "1.2rem",
      lineHeight: "1.4rem",
      letterSpacing: ".015rem",
      fontWeight: 800,
      minWidth: "initial",
      padding: "6px 16px"
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  mobileActions: {
    display: "none",
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  }
}))

export default styles
