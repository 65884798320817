import { isEmpty } from "lodash"
import { User } from "store/serializers/user"
import userModel from "store/models/user"
import {
  LOGGING_IN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  SET_USER,
  UPDATE_LOGGED_IN_USER,
  REGISTERING,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  LOGOUT
} from "types/actions/authentication"
import update from "immutability-helper"

export const authenticationReducerDefaultState = {
  authenticatedUser: {},
  loggingIn: false,
  loginError: false,
  registering: false,
  registerError: false,
  token: "",
  location: {}
}

let newState

export default (state = authenticationReducerDefaultState, action) => {
  switch (action.type) {
    case LOGGING_IN:
      return {
        ...state,
        loggingIn: action.loggingIn
      }
    case LOGIN_ERROR:
      return {
        ...state,
        loginError: action.error
      }
    case LOGIN_SUCCESS:
      newState = {
        ...state,
        authenticatedUser: !isEmpty(action.user) ?
          action.user :
          state.authenticatedUser
      }
      if (action.token) {
        newState.token = action.token
      }
      return newState
    case REGISTERING:
      return {
        ...state,
        registering: action.registering
      }
    case REGISTER_ERROR:
      return {
        ...state,
        registerError: action.error
      }
    case REGISTER_SUCCESS:
      newState = {
        ...state,
        authenticatedUser: action.user
      }
      if (action.token) {
        newState.token = action.token
      }
      return newState
    case UPDATE_LOGGED_IN_USER:
      return {
        ...state,
        authenticatedUser: new User(userModel, {
          ...state.authenticatedUser,
          ...action.updates
        })
      }
    case SET_USER:
      return {
        ...state,
        authenticatedUser: action.user
      }
    case LOGOUT:
      localStorage.clear()
      return update(state, {
        authenticatedUser: {
          $set: {}
        },
        token: {
          $set: ""
        }
      })
    default:
      return state
  }
}
