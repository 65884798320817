import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  priceDetails: {
    maxWidth: 400
  },
  priceHeading: {
    marginBottom: 5,
    fontSize: "1.4rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem"
    }
  },
  price: {
    fontWeight: "700",
    marginBottom: 0
  },
  buyButton: {
    fontSize: "2.5rem",
    height: "54px",
    width: "100%"
  }
}))

export default styles
