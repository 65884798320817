const sliderStyles = theme => ({
  root: {
    color: theme.palette.secondary.main,
    height: 8
  },
  thumb: {
    height: 20,
    width: 20,
    color: theme.palette.secondary.main,
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    top: -28,
    left: "calc(-50% + 4px)",
    "& span": {
      backgroundColor: "transparent"
    },
    "& span span": {
      padding: theme.spacing(1, 1, 0, 1),
      background: "#F0F0F2",
      color: theme.palette.secondary.main,
      fontSize: "1.6rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.3rem"
      }
    }
  },
  track: {
    height: 3,
    borderRadius: 2,
    marginTop: 1,
    color: theme.palette.secondary.main
  },
  rail: {
    height: 1,
    borderRadius: 1,
    marginTop: 2
  }
})

export default sliderStyles
