import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: "40px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px"
    }
  }
}))

export default styles
