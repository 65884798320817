import React from "react"
import useStyles from "./styles"
import { Container, Typography } from "@material-ui/core"

const RulesStaff = () => {
  const classes = useStyles()

  return (
    <Container className={classes.container}>
      <Typography className={classes.title} variant="h3">Rules & Regulations</Typography>
      <ul className={classes.list}>
        <li>Experience cannot be resold or re-auctioned.</li>
        <li>Cannot be transferred.</li>
        <li>Travel and accommodations are not included.</li>
        <li>We expect all winning bidders and their guests to conduct themselves appropriately when attending an experience won at KindBoost. Polite manners and respect for the generous donor and adherence to any rules or parameters are a must.</li>
        <li>To be scheduled at a mutually agreed upon date, based on the donor's availability.</li>
      </ul>
    </Container>
  )
}

export default RulesStaff
