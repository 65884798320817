import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  container: {
    padding: "0px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
      height: "50vh",
      overflow: "scroll"
    }
  },
  headerTextWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  actionsWrapper: {
    justifyContent: "center"
  },
  registerButton: {
    marginBottom: theme.spacing(4)
  },
  loginText: {

    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4)
    }

  },
  loginTextPhone: {
    fontSize: "1.2rem",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4)
    }
  }
}))

export default styles
