import { PAGE_DEFAULT } from "types/actions/page"

const pageInitialState = {
  data: "index"
}

export default (state = pageInitialState, action) => {
  switch (action.type) {
    case PAGE_DEFAULT:
      return {
        ...state,
        data: action.data
      }
    default:
      return state
  }
}
