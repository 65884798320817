import React, { Component } from "react"
import { withRouter } from "react-router"
import { withStyles } from "@material-ui/core/styles"
import withWidth from "@material-ui/core/withWidth"
import styles from "./styles"
import { connect } from "react-redux"
import { compose } from "recompose"
import Snackbar from "@material-ui/core/Snackbar"
import { closeSnackBar } from "../../actions/snack-bar"
import { getItems } from "actions/items"
import Router, { routeConfig } from "router"
import RegisterDialog from "dialogs/Register"
import LoginDialog from "dialogs/Login"
import BidConfirmationDialog from "dialogs/BidConfirmation"
import FixedConfirmationDialog from "dialogs/FixedConfirmation"
import ProfileDialog from "dialogs/Profile"
import { Typography } from "@material-ui/core"
import BidWinnerDialog from "dialogs/BidWinner"
import NavBar from "components/NavBar"
import Footer from "components/Footer"
import ForgotPasswordDialog from "dialogs/ForgotPassword"
import { getEvent } from "actions/events"
import PubNub from "pubnub"
import { v4 as uuidv4 } from "uuid"
import store from "store"
import serialize from "store/serialize"
import { setItems } from "actions/items"
import RaffleConfirmationDialog from "dialogs/RaffleConfirmation"
import { StripeProvider, Elements } from "react-stripe-elements"
import { setMyItems } from "actions/items"
// import items from "reducers/items"

class App extends Component {
  componentDidMount() {
    if (process.env.NODE_ENV === "production") {
    // Setup listener for Google gtag
      this.props.history.listen((location) => {
        setTimeout(() => {
          window.gtag("config", process.env.REACT_APP_EVENT_ID, {
            page_path: location.pathname,
            page_title: document.title
          })
        }, 500)
      })

      // Capture intial page load for Google gtag
      window.gtag("config", process.env.REACT_APP_EVENT_ID, {
        page_path: this.props.location.pathname,
        page_title: document.title
      })
    }

    this.props.dispatch(getItems(process.env.REACT_APP_EVENT_ID))
    this.props.dispatch(getEvent(process.env.REACT_APP_EVENT_ID))
    const pubnub = new PubNub({
      subscribeKey: "sub-336f40b5-96ec-11e1-905f-6fdff2e9e58b",
      uuid: uuidv4()
    })
    pubnub.addListener({
      message: function(msg) {
        try {
          if (["updateItem", "updateItems"].includes(msg.message.action)) {
            const data = msg.message.data
            const items = store.getState().items.data
            const myItems = store.getState().items.myItems
            let itemIds = []
            if (msg.message.action === "updateItems") {
              itemIds = data.item_ids
              delete data.item_ids
            } else {
              itemIds = [data.item_id]
            }
            serialize("item", items.map(x => {
              if (itemIds.map(y => +y).includes(+x.item_id)) {
                return Object.assign(x, data)
              } else {
                return x
              }
            })).then(items => {
              store.dispatch(setItems(items))
            })

            serialize("item", myItems.map(x => {
              if (itemIds.map(y => +y).includes(+x.item_id)) {
                return Object.assign(x, data)
              } else {
                return x
              }
            })).then(items => {
              store.dispatch(setMyItems(items))
            })
          } else if (msg.message.action === "createItem") {
            const items = store.getState().items.data
            const newItem = msg.message.data
            serialize("item", [...items, newItem]).then(newItems => {
              store.dispatch(setItems(newItems))
            })
          }
        } catch (err) {
          console.log(err)
        }
        console.log({ msg })
      }
    })
    pubnub.subscribe({
      channels: [`ember-event-${process.env.REACT_APP_EVENT_ID}`]
    })
  }


  render() {
    const { classes } = this.props
    const snackBarOrigin =
      this.props.width === "sm" || this.props.width === "xs" ?
        { vertical: "bottom", horizontal: "left" } :
        { vertical: "bottom", horizontal: "left" }
    return (
      <div className={classes.app} id="app-container">
        {/* <div className={`${classes.app}, mui-fixed`}> */}
        <NavBar />
        <Router routes={routeConfig} />
        <Footer />
        <div id="dialog-holder">
          {/* LEAVE THIS IN PLACE FOR DIALOGS TO POPULATE INTO */}
          <RegisterDialog />
          <LoginDialog />
          <BidConfirmationDialog />
          <FixedConfirmationDialog />
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
            <Elements>
              <ProfileDialog />
            </Elements>
          </StripeProvider>
          <BidWinnerDialog />
          <ForgotPasswordDialog />
          <RaffleConfirmationDialog />
        </div>
        <Snackbar
          anchorOrigin={snackBarOrigin}
          open={this.props.snackBar.open}
          onClose={() => this.props.dispatch(closeSnackBar())}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          autoHideDuration={this.props.snackBar.autoHideDuration}
          message={<Typography variant="body1" id="message-id">{this.props.snackBar.message}</Typography>}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    snackBar: state.snackBar
  }
}

export default withRouter(
  compose(connect(mapStateToProps), withStyles(styles), withWidth())(App)
)
