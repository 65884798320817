import { useState, useEffect } from "react"
import countdown from "utils/countdown"
import moment from "moment"

const useCountdown = (closeTime) => {
  const [timeLeft, setTimeLeft] = useState({ isClosed: false, remaining: {} })
  const utcTime = moment.utc(closeTime).format()

  useEffect(() => {
    const countdownId = countdown(moment(utcTime).toDate(),
      function(ts) {
        setTimeLeft({ isClosed: Math.sign(ts.value) !== -1, remaining: { ...ts } })
      })
    return function cleanup() {
      window.clearInterval(countdownId)
    }
  }, [closeTime !== null])

  return timeLeft
}


export default useCountdown
