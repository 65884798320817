import React from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from "./styles"
import {
  Dialog,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
  Checkbox,
  Box,
  Link,
  Hidden
} from "@material-ui/core"
import { closeDialog } from "actions/dialogs"
import CloseIcon from "@material-ui/icons/HighlightOff"
import logo from "assets/kindboost.svg"
import * as Yup from "yup"
import "yup-phone"
import { Formik, Form, useFormikContext } from "formik"
import { openDialog } from "actions/dialogs"
import FormTextField from "components/FormTextField"
import FormError from "components/FormError"
import MaskedMobileInput from "components/MaskedPhoneInput"
import { register } from "actions/authentication"
import cleanPhone from "utils/cleanPhone"
import { openSnackBar } from "actions/snack-bar"
import DialogHeader from "components/DialogHeader"
import DialogButton from "components/DialogButton"
import { useRef } from "react"
import PoweredBy from "components/PoweredBy"

const registerSchema = Yup.object().shape({
  user_first_name: Yup.string()
    .required("First name is required"),
  user_last_name: Yup.string()
    .required("Last name is required"),
  user_email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  user_phone: Yup.string().phone("Please enter a valid phone number").required("Phone is required"),
  user_password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
  terms: Yup.bool().oneOf([true], "Agree to terms of Service before registering")
})

const initialValues = {
  user_first_name: "",
  user_last_name: "",
  user_email: "",
  user_phone: "",
  user_password: "",
  terms: false
}

const FormCheckbox = ({ name }) => {
  const formikProps = useFormikContext()
  return (
    <>
      <Box display="flex" alignItems="center">
        <Checkbox color="primary" value={formikProps.values[name]} onChange={() => formikProps.setFieldValue(name, !formikProps.values[name])}/>
        <Typography
          variant="caption">
          Agree to{" "}
          <Link
            color="primary"
            href="/terms-of-service"
            target="_blank">
            Terms and Conditions
          </Link>
        </Typography>
      </Box>
      <FormError name="terms" />
    </>
  )
}

const RegisterDialog = () => {
  const classes = useStyles()
  const open = useSelector(state => state.dialogs.register?.open || false)
  // const data = useSelector(state => state.dialogs.register?.data || {})
  const buttonRef = useRef(null)

  const dispatch = useDispatch()

  const handleClose = (state) => {
    dispatch(closeDialog("register", state))
  }

  const handleSubmit = (formData) => {
    buttonRef.current.setWorking(true)
    const { ...restFormData } = formData
    dispatch(register({
      ...restFormData,
      user_phone: cleanPhone(restFormData.user_phone)
    })).then(() => {
      handleClose("success")
      dispatch(openSnackBar({
        message: "Successfully registered account"
      }))
    }).catch(err => {
      console.log(err)
      dispatch(openSnackBar({
        message: err
      }))
    }).finally(() => {
      buttonRef.current.setWorking(false)
    })
  }

  return (
    <Dialog open={open}>
      <Formik validationSchema={registerSchema} initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <DialogHeader
            onClose={handleClose}/>
          <Box className={classes.container}>

            <Box className={classes.headerTextWrapper}>
              <Typography variant="h4" color="primary">
              Register
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormTextField name="user_first_name" label="First Name" />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormTextField name="user_last_name" label="Last Name" />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField name="user_email" label="Email" />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField name="user_phone" type="tel" label="Phone" InputProps={{ inputComponent: MaskedMobileInput }} />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField name="user_password" label="Create Password" password/>
                </Grid>
                <Grid item xs={12}>
                  <FormCheckbox name="terms" />
                </Grid>
              </Grid>
            </Box>
            <Hidden smUp>
              <Box display="flex"justifyContent="center">
                <Typography color="secondary" className={classes.loginTextPhone}>
                Already have an account?{" "}
                  <Link
                    color="primary"
                    onClick={() => {
                      handleClose("change")
                      dispatch(openDialog("login"))
                    }}
                    style={{ cursor: "pointer" }}
                  >
                  Login Here
                  </Link>
                </Typography>
              </Box>
            </Hidden>
          </Box>
          <DialogButton
            buttonRef={buttonRef}
            text="Register"/>
          <Hidden smDown>
            <Box display="flex"justifyContent="center">
              <Typography color="secondary" className={classes.loginText}>
                Already have an account?{" "}
                <Link
                  color="primary"
                  onClick={() => {
                    handleClose("change")
                    dispatch(openDialog("login"))
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Login Here
                </Link>
              </Typography>
            </Box>
          </Hidden>
        </Form>
      </Formik>
      <PoweredBy />
    </Dialog>
  )
}

export default RegisterDialog
