import React from "react"
import useStyles from "./styles"
import { Box } from "@material-ui/core"
import eventGives from "assets/event_gives.png"

const PoweredBy = () => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop={3} marginBottom={4}>
      <span className={classes.poweredBy}>Powered by</span>
      <img className={classes.logo} src={eventGives} alt="Event.gives" />
    </Box>
  )
}

export default PoweredBy
