import { useLayoutEffect } from "react"

function usePageTitle(title = "") {
  useLayoutEffect(() => {
    if (title) {
      document.title = `kindboost | ${title}`
    } else {
      document.title = "kindboost"
    }
  }, [])
}

export default usePageTitle
