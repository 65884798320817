import React from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from "./styles"
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Box,
  IconButton
} from "@material-ui/core"
import { closeDialog } from "actions/dialogs"
import CloseIcon from "@material-ui/icons/HighlightOff"
import { useHistory } from "react-router-dom"
import hartPath from "assets/winner_hart.svg"
import Confetti from "react-confetti"
import numberWithCommas from "utils/numberWithCommas"
const phoneView = window.innerWidth < 650

const BidWinnerDialog = () => {
  const classes = useStyles()
  let history = useHistory()
  const open = useSelector(state => state?.dialogs.bidWinner?.open || false)
  const { type, chances, closed } = useSelector(state => state?.dialogs?.bidWinner?.data || {})
  const dispatch = useDispatch()
  // const { width, height } = useWindowSize()

  const handleClose = () => {
    dispatch(closeDialog("bidWinner"))
  }


  return (
    <Dialog
      classes={{ paperWidthSm: classes.papper }}
      open={open}
    >
      <DialogContent>
        <Box
          display="flex"
          justifyContent="flex-end" >
          <IconButton>
            <CloseIcon onClick={handleClose}
              classes={{ root: classes.closeButton }}/>
          </IconButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column">

          <Box className={classes.titleContainer} >

            <Typography
              className={classes.title}>
              {type !== "fixed" ? (type === "raffle" ? `Boom, baby. You now have ${numberWithCommas(chances)} chances to win!` :
                !closed ? "Boom, baby. You are the high bidder!" :
                  closed ? "Boom, baby. You bought it!" : null) : null
              }
              {type === "fixed" && "Boom, baby. You bought it!"}
            </Typography>
          </Box>
          <Box className={classes.subtitleContainer} >
            <Typography
              className={classes.subtitle}>
              {type !== "fixed" ? (type === "raffle" && type !== "fixed" ? "Your entries have been submitted. We'll let you know if you are the winner. Good luck!" :
                !closed ? "Your bid has been submitted. We'll let you know when you've won or been outbid. Good luck!" :
                  closed ? "You submitted the buy price for this item. It has been placed in your cart" : null) : null
              }
              {type === "fixed" && "Your purchase was successful!"}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        classes={{ root: classes.buttonContainer }}>
        <Button
          classes={{
            root: classes.exploreButton }}
          variant="contained"
          onClick={() => {
            handleClose()
            if (closed) {
              history.push("/my-items")
            } else {
              history.push("/items")
            }
          }}
        >
          {closed ? "Go to Cart" : "Explore More"}
        </Button>
      </DialogActions>
      <img src={hartPath} className={classes.hartPath}/>
      <Confetti
        numberOfPieces={500}
        recycle={false}
        style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0, width: "100%", height: "100%" }}
      />
    </Dialog>
  )
}

export default BidWinnerDialog
