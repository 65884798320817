import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  actionsWrapper: {
    justifyContent: "center",
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      justifyContent: "none",
      marginTop: theme.spacing(0),
      paddingBottom: theme.spacing(0)
    }
  },
  wrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      height: "42px",
      borderRadius: "0px"
    }
  }
}))

export default styles
