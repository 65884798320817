import React from "react"
import useStyles from "./styles"
import { Box, Typography } from "@material-ui/core"
import path from "assets/about_us_path.svg"

const OurMission = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column">
        <Typography variant="h3" className={classes.text}>
          2020 Passes Thrown and Missions Completed
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Is there a more quintessentially American activity than tossing a football?
          A time-honored ritual in which the arching rainbow of a perfectly tossed spiral leads
          to that proverbial Pot O’ Gold known as the end zone.
        </Typography>
        <Typography variant="body2" className={classes.text}>
          And, what of the end zone itself?
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Whether it be that precious space between a bus stop and a parked car, the leaf littered field where you wowed ‘em in high school, the tent lines of Normandy or Phu Bai, the glittering flash bulb popping spectacle of a Super Bowl, or a Thanksgiving backyard where three generations turn back the clock amongst laughter and cheers, the act of tossing a football rises above all that divides us.
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Tis an act that abounds with remarkable connections creating memories whose freeze-framed recollections are the stuff of which dreams are made.
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Imagine achieving such a memory with football’s greatest quarterback and having that memory preserved in a manner  which you, your friends and family can share forever.
        </Typography>
        <Typography variant="body2" className={classes.text}>
          All in the context of helping the “This too shall pass charity fund”  prevail in the battle against COVID19 and all the other life obstacles that the funds are supporting and battling... for this truly is the battle of our lifetime.
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Tom Brady and you.
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Going long for charity.
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Scoring a touchdown.
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Literally hitting pay dirt.
        </Typography>
        <Typography variant="body2" className={classes.text}>
          This Too Shall Pass.
        </Typography>
      </Box>
      {/* <Box display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Typography variant="h4"
          className={classes.subtitle}
        >
          How it works
        </Typography>
      </Box>
      <Box display="flex"
        justifyContent="space-between"
        className={classes.imagesContainer}>
        <Box className={classes.stepContainer}>
          <Box className={classes.images}/>
          <Typography
            className={classes.stepText}
            color="primary">
            STEP 1
          </Typography>
          <Typography variant="h4">
            Amazing items get donated to Kindboost
          </Typography>
        </Box>
        <Box className={classes.stepContainer}>
          <Box className={classes.images}/>
          <Typography
            className={classes.stepText}
            color="primary">
            STEP 2
          </Typography>          <Typography variant="h4">
            Items are open to the public to be bid on
          </Typography>
        </Box>
        <Box className={classes.stepContainer}>
          <Box className={classes.images}/>
          <Typography
            className={classes.stepText}
            color="primary">
            STEP 3
          </Typography>
          <Typography variant="h4">
           A winner is drawn and proceeds go to a great cause
          </Typography>

        </Box>
        <img src={path} alt="path image" className={classes.pathImage}/>

      </Box> */}
    </Box>
  )
}

export default OurMission
