import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    backgroundColor: "#F0F0F2",
    padding: theme.spacing(2, 2),
    boxSizing: "border-box",
    position: "relative"
  },
  margin: {
    height: theme.spacing(3)
  },
  sliderWidth: {
    width: "100%",
    display: "flex",
    marginTop: theme.spacing(4.5),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2.5),
      width: "100%"
    }
  },
  sectionLabel: {
    fontSize: "1.2rem",
    fontWeight: 700
  },
  clearButtonContainer: {
    position: "absolute",
    top: 26,
    right: 26,
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      top: "unset",
      right: "unset",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%"
    }
  },
  clearButton: {
    fontSize: "1.2rem",
    color: "#797F91",
    opacity: 0.44
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%"
  },
  textProperties: {
    color: "#F440BD",
    fontSize: "1.4rem",
    width: "180px",
    [theme.breakpoints.down("xs")]: {
      width: "48%"
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "1.4rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.3rem"
      }
    }
  },
  buttonMargins: {
    marginTop: "30px"
  },
  checkboxRoot: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      padding: 7
    },
    "& .MuiIconButton-label": {
      color: theme.palette.secondary.main,
      "& .MuiSvgIcon-root": {
        [theme.breakpoints.down("sm")]: {
          width: 15,
          height: 15
        }
      }
    }
  },
  // rootValueLabel: {
  //   "& .PrivateValueLabel-circle-31": {
  //     color: "transparent !important"
  //   }
  // },
  advancedFilterContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 !important"
    }
  },
  priceRange: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.3rem"
    }
  },
  spacer: {
    padding: "0px !important"
  }
}))

export default styles
