
import React from "react"
import useStyles from "./styles"
import { Box, Button, Card, CardContent, CardHeader, CardMedia, Typography } from "@material-ui/core"
// import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import useCountdown from "hooks/useCountdown"
import useItemSponsor from "hooks/useItemSponsor"
import isClosed from "utils/isClosed"
import usePublished from "hooks/usePublished"
import timer from "utils/timer"

const ItemCard = ({ item }) => {
  // const event = useSelector(state => state.event.data)
  const history = useHistory()
  // const sponsor = useMemo(() => event?.sponsors?.find(x => x.sponsor_id === item.sponsor_id), [event])
  const timeLeft = useCountdown(item?.close_time)
  const classes = useStyles()
  const sponsor = useItemSponsor(item)
  const timeLeftToOpen = useCountdown(item.open_time)

  return (
    <Card className={classes.root} onClick={() => history.push(`/item/${item.item_id}/${item.slug}`)}>
      <CardHeader
        action={
          <Box className={`${classes.tag} ${item.item_type === "raffle" ? classes.colorPrimary : classes.colorSecondary}`}>
            {item.item_type === "auction" ? "Bid" : item.item_type === "fixed" ? "Buy" : "Donate"}
          </Box>
        }
        className={classes.header}
      />
      <CardMedia
        className={classes.media}
        image={item.image[0].url}
        title={item.name}
      />
      <CardContent className={classes.content}>
        <Box>
          {sponsor.sponsor_name ?
            <Typography
              variant="subtitle1"
              component="span">
          Supporting {sponsor?.sponsor_name}
            </Typography> :
            <Box className={classes.noSponsorBox}/>}
          <Typography
            variant="h4"
            component="h4">
            {item?.name}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" component="span">
            { isClosed(item) ?
              "Item Closed" : item.close_time === null || !timeLeftToOpen.isClosed ? null :
                timer(timeLeft, "countdown")}
            {timeLeftToOpen.isClosed ? null : timer(timeLeftToOpen, "coundtUp")}

          </Typography>
          <Button className={classes.viewButton} variant="text" color="primary">VIEW</Button>
        </Box>

      </CardContent>
    </Card>
  )
}
export default ItemCard
