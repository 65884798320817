import React, { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from "./styles"
import { Dialog, Typography, Box } from "@material-ui/core"
import { closeDialog } from "actions/dialogs"
import DialogHeader from "components/DialogHeader"
import accounting from "accounting"
import LoaderButton from "@bit/c_t.components.loader-button"
import { purchaseFixed } from "actions/items"
import { openDialog } from "actions/dialogs"
import DialogButton from "components/DialogButton"
import { openSnackBar } from "actions/snack-bar"
import { Link } from "react-router-dom"

const FixedConfirmation = () => {
  const classes = useStyles()
  let buttonRef = useRef()
  const open = useSelector(
    (state) => state.dialogs.fixedConfirmation?.open || false
  )
  const { itemId, quantity, itemName, userId, paysource, currency, cost, userPayId, sendSms } = useSelector(
    (state) => state.dialogs.fixedConfirmation?.data || {}
  )
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeDialog("fixedConfirmation"))
  }

  const handleSubmit = () => {
    buttonRef.current.setWorking(true)
    setIsButtonLoading(true)
    console.log("quantity", quantity)
    const items = Array(quantity).fill(null).map(item => ({ item_id: itemId, amount: cost }))
    console.log("items:", items)
    console.log("USERID", userId)
    dispatch(
      purchaseFixed({
        user_id: userId,
        pay_type: "card",
        user_pay_id: userPayId,
        paysource,
        currency,
        items,
        send_sms: sendSms
        // stripe_test: 1
      })
    )
      .then((res) => {
        setTimeout(() => {
          handleClose()
          dispatch(
            openDialog("bidWinner", {
              type: "fixed"
            })
          )
          buttonRef.current.setWorking(false)
          setIsButtonLoading(false)
        }, 500)
      })
      .catch((err) => {
        console.log(err)
        dispatch(
          openSnackBar({
            message: "Item is sold-out."
          })
        )
        buttonRef.current.setWorking(false)
        setIsButtonLoading(false)
        handleClose()
      })
  }

  return (
    <Dialog open={open}>
      <DialogHeader onClose={handleClose} />
      <Box className={classes.container}>
        <Typography color="primary" className={classes.bidTitle} variant="h4">
          Purchase confirmation
        </Typography>
        <Typography className={classes.confirmationText}>
          You are purchasing <strong>{quantity} {itemName}</strong> for <strong>${quantity * cost}</strong>.
        </Typography>
        <Typography className={classes.termsOfServiceText}>
          By purchasing this item you are agreeing to our{" "}
          <Link
            to={{ pathname: "/terms-of-service" }}
            target="_blank"
            style={{ textDecoration: "none", color: "#F440BD" }}
          >
            Terms and Conditions.
          </Link>
        </Typography>
      </Box>
      <DialogButton
        handleSubmit={handleSubmit}
        disabled={isButtonLoading}
        buttonRef={buttonRef}
        text="Confirm Purchase"
      />
    </Dialog>
  )
}

export default FixedConfirmation
