import React from "react"
import useStyles from "./styles"
import { Box, Button, Typography, Divider } from "@material-ui/core"
import { useSelector, useDispatch } from "react-redux"
import { openDialog } from "actions/dialogs"
import { openSnackBar } from "actions/snack-bar"
import numberWithCommas from "utils/numberWithCommas"
import PopularRaffle from "./PopularRaffle"
import getUserPaysource from "utils/getUserPaysource"
import getCurrentUserId from "utils/getCurrentUserId"
import isClosed from "utils/isClosed"

let rafflesOptions = [
  {
    chances: 100,
    donation: 10,
    popular: false
  },
  {
    chances: 250,
    donation: 25,
    popular: true
  },
  {
    chances: 1000,
    donation: 50,
    popular: false
  },
  {
    chances: 2000,
    donation: 100,
    popular: false
  }

]


const Raffles = ({ currentItem }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const authenticatedUser = useSelector(state => state.authentication.authenticatedUser)
  // Checks for paysource. If none, will prompt user for CC info.
  const checkForPaySource = (raffleItem) => {
    const userPaysource = getUserPaysource()

    if (userPaysource) {
      dispatch(openDialog("raffleConfirmation", raffleItem))
        .catch((err) => console.log(err))
    } else {
      dispatch(
        openSnackBar({
          message: "Please add a credit card."
        })
      )
      dispatch(openDialog("profile", { cardRequired: true }))
        .then((res) => {
          const userPaysource = getUserPaysource()
          if (userPaysource) {
            dispatch(openDialog("raffleConfirmation", raffleItem))
              .catch((err) => console.log(err))
          }
        })
    }
  }
  const handleSubmit = (values, dialog) => {
    let itemId = currentItem.item_id
    let userID = authenticatedUser?.user_id
    let bidAmt = values.chances
    let userPayId = authenticatedUser?.user_pay_id
    let paysource = authenticatedUser?.user_paysources?.[0]
    let currency = "usd"
    let price = values.donation
    let chances = values.chances
    const raffleItem = { itemId, userID, bidAmt, userPayId, paysource, currency, price, chances }

    if (userID) {
      checkForPaySource(raffleItem)
    } else {
      dispatch(openDialog(dialog))
        .then(res => {
          if (res === "success") {
            const currentUserId = getCurrentUserId()
            // Update current UserID and check for pay source
            checkForPaySource({ ...raffleItem, userID: currentUserId })
          } else if (res === "change") {
            return handleSubmit(values, dialog === "login" ? "register" : "login")
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  return (
    <>
      {!isClosed(currentItem) && currentItem.published &&
    <Box className={classes.container} id="raffle-wrapper">

      <Typography variant="h2" className={classes.title}>Entry Levels</Typography>
      <Typography variant="body1" className={classes.subtitle}>
        Choose the amount below. No donation or payment is necessary to enter or win this sweepstakes.
      </Typography>
      <Box display="flex" flexWrap="wrap">
        {rafflesOptions.map((option, i) => {
          return (
            <Box
              key={i}
              className={classes.rafflesBox}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between">
              <Typography className={classes.chancesText}>
                {numberWithCommas(option.chances)} chances to win
              </Typography>
              <Divider
                className={classes.divider}
                orientation="horizontal"
                variant="fullWidth"/>
              <Typography
                color="secondary"
                classes={{ root: classes.numberText }}>
                {numberWithCommas(option.chances)}
              </Typography>
              <Typography
                color="secondary"
                className={classes.entriesText}> entries
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit(option, "register")}
                classes={{ root: classes.donateButton }}>
                Donate ${option.donation}
              </Button>
              {option.popular &&
              <PopularRaffle />}
            </Box>
          )
        })}
      </Box>
    </Box>}
    </>
  )
}

export default Raffles
