import React, { useRef, useState, useMemo, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import useStyles from "./styles"
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  Button,
  ButtonGroup
} from "@material-ui/core"
import DialogHeader from "components/DialogHeader"
import { closeDialog } from "actions/dialogs"
import LoaderButton from "@bit/c_t.components.loader-button"
import { openDialog } from "actions/dialogs"
import { placeBid } from "actions/items"
import useItemAmount from "utils/getItemAmount"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import FormTextField from "components/FormTextField"
import MaskedBid from "components/MaskedBid"
import accounting from "accounting"
import DialogButton from "components/DialogButton"
import { Link } from "react-router-dom"

const BidConfirmationDialog = () => {
  const classes = useStyles()
  const buttonRef = useRef()
  const formRef = useRef()
  const open = useSelector(state => state.dialogs.bidConfirmation?.open || false)
  const { itemId, userID, bidAmt, sendSms } = useSelector(state => state.dialogs.bidConfirmation?.data || {})
  const [losing, setLosing] = useState(false)
  const dispatch = useDispatch()
  const items = useSelector(state => state?.items?.data)
  const currentItem = useMemo(() => items.find(x => x.item_id === itemId), [items, itemId])
  const [, setHighBid] = useState(currentItem?.high_bid)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [commingFromLosing, setCommingFromLosing] = useState(false)
  const [newBid, setNewBid] = useState()
  useEffect(() => {
    setHighBid(currentItem?.high_bid)
  }, [currentItem?.high_bid])

  const initialValues = useMemo(() => {
    if (currentItem) {
      const bid = useItemAmount(currentItem) + currentItem.bid_increment
      /* eslint-disable */
      formRef?.current?.setFieldValue?.("bid", bid)
      /* eslint-enable */
      return { bid }
    } else {
      return { bid: 0 }
    }
  }, [currentItem, formRef, losing])
  const bidSchema = Yup.object().shape({
    bid: Yup.number().min(initialValues.bid)
  })
  const handleClose = () => {
    dispatch(closeDialog("bidConfirmation"))
  }
  const handleSubmit = () => {
    let price
    if (typeof bidAmt === "string") {
      price = bidAmt?.replace(/[^\d\.\-eE+]/g, "")
    }

    buttonRef.current.setWorking(true)
    setIsButtonLoading(true)
    dispatch(placeBid({
      item_id: itemId,
      user_id: userID,
      bid_amt: commingFromLosing ? newBid : price || bidAmt,
      send_sms: sendSms
    }))
      .then(res => {
        setTimeout(() => {
          handleClose()
          dispatch(openDialog("bidWinner", { closed: currentItem.status === 2 }))
          buttonRef.current.setWorking(false)
          setIsButtonLoading(false)
          if (commingFromLosing) {
            setCommingFromLosing(false)
          }
        }, 500)
      })
      .catch((err) => {
        setLosing(true)
        console.log(err)
      })
  }
  const handleSubmitLosing = (values) => {
    setLosing(false)
    setCommingFromLosing(true)
    setNewBid(values.bid)

    // buttonRef.current.setWorking(true)
    // setIsButtonLoading(true)
    // dispatch(placeBid({
    //   item_id: itemId,
    //   user_id: userID,
    //   bid_amt: values.bid,
    //   send_sms: sendSms
    // }))
    //   .then(res => {
    //     setTimeout(() => {
    //       handleClose()
    //       dispatch(openDialog("bidWinner"))
    //       buttonRef.current.setWorking(false)
    //       setIsButtonLoading(false)
    //       setLosing(false)
    //     }, 500)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  }


  return (
    <Dialog
      className={classes.dialogContainer}
      open={open}
    >
      <DialogHeader
        onClose={handleClose}/>
      <DialogContent className={classes.contentContainer}>
        {!losing ? (
          <>
            <Typography color="primary" className={classes.bidTitle} variant="h4">{`You are placing a bid of ${commingFromLosing ? newBid : accounting.formatMoney(bidAmt, "$", 0)}`}</Typography>
            <Typography className={classes.confirmationText}>
        Please confirm your bid. If you are the highest bidder when this item closes, your credit card on file will be charged.
            </Typography>
            <Typography className={classes.termsOfServiceText}>
            By placing a bid you are agreeing to our <Link to={{ pathname: "/terms-of-service" }} target="_blank" style={{ textDecoration: "none", color: "#F440BD" }}>Terms and Conditions.</Link>
            </Typography>

          </>) : (
          <>
            <Typography color="primary" className={classes.bidTitle} variant="h4">Sorry. There is another bid higher than yours</Typography>
            <Box display="flex" justifyContent="center" className={classes.enterBidContainer}>
              <Formik
                validationSchema={bidSchema}
                initialValues={initialValues}
                onSubmit={handleSubmitLosing}
                innerRef={formRef}
              >
                <Form>
                  <ButtonGroup classes={{ root: classes.buttonGroupContainer }}>
                    <Box className={classes.textFieldContainer}>
                      <FormTextField
                        bid
                        name="bid"
                        type="tel"
                        inputStyle={{ backgroundColor: "red" }}
                        variant="outlined"
                        errorClass={classes.error}
                        InputProps={{
                          inputComponent: MaskedBid,
                          classes: {
                            root: classes.inputRadius,
                            input: classes.input }
                        }}
                      />
                    </Box>
                    <Box className={classes.buttonContainer}>
                      <LoaderButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        classoverrides={{ button: { contained: classes.bidButtonShadow, root: classes.bidButton } }}
                        ref={buttonRef}
                      >
                            Enter Now
                      </LoaderButton>
                    </Box>
                  </ButtonGroup>

                </Form>
              </Formik>
            </Box>
          </>
        )}
      </DialogContent>

      {!losing &&
        <DialogButton
          handleSubmit={handleSubmit}
          disabled={isButtonLoading}
          buttonRef={buttonRef}
          text="Confirm Bid"/>}
    </Dialog>
  )
}

export default BidConfirmationDialog
