import React, { useEffect, useState } from "react"
import useStyles from "./styles"
import { Box, Grid, Typography, Button, Divider, Zoom } from "@material-ui/core"
import moment from "moment"
import clsx from "clsx"
import { useSelector } from "react-redux"
import numberWithCommas from "utils/numberWithCommas"
import useItemSponsor from "hooks/useItemSponsor"
import { useHistory } from "react-router-dom"
import useCountdown from "hooks/useCountdown"
import isClosed from "utils/isClosed"
import usePublished from "hooks/usePublished"

const MyItemCard = ({ item, itemStatusTab }) => {
  const classes = useStyles()
  const closed = isClosed(item)
  const authenticatedUser = useSelector(state => state.authentication.authenticatedUser)
  const winning = item.high_bid_user_id === authenticatedUser.user_id
  const won = (winning && closed) || item.item_type === "fixed"
  const sponsor = useItemSponsor(item)
  let history = useHistory()
  const timeLeft = useCountdown(item.close_time)
  const [showItems, setShowItems] = useState(false)


  useEffect(() => {
    setShowItems(false)
    setTimeout(() => {
      setShowItems(true)
    }, 500)
  }, [item])

  const bidAmount = (item) => {
    if (item.item_type === "auction") {
      if (won) {
        return `Closing bid $${numberWithCommas(item.high_bid)}`
      } else if (winning && !closed) {
        return `High Bid: $${numberWithCommas(item.high_bid)}`
      } else if (!winning && closed) {
        return `Ending bid: $${numberWithCommas(item.high_bid)}`
      } else if (!winning && !closed) {
        return `High Bid: $${numberWithCommas(item.high_bid)}`
      }
    } else if (item.item_type === "raffle") {
      return `${numberWithCommas(+item.tickets_purchased_user)} ${Number(item.tickets_purchased_user) === 1 ? "entry" : "entries"}`
    } else if (item.item_type === "fixed") {
      return `${item.items_purchased} for $${numberWithCommas(Number(item.items_purchased) * Number(item.cost))}`
    } else {
      return null
    }
  }

  const bidClasses = (item) => {
    let className = ""
    if (!won) {
      className = item.item_type === "auction" ? classes.highBid : classes.entriesText
    } else {
      className = item.item_type === "auction" || item.item_type === "fixed" ? classes.whiteTextHighBid : classes.whiteTextEntriesText
    }
    return className
  }

  const bidText = (item) => {
    if (item.item_type === "auction") {
      if (won) {
        return "You won!"
      } else if (winning && !closed) {
        return "You are the highest bid!"
      } else if (!winning && !closed) {
        return "Your are not the highest bid"
      } else if (!winning && closed) {
        return "You were not the lucky winner"
      }
    } else if (item.item_type === "raffle") {
      if (won) {
        return "You won"
      } else if (!winning && closed) {
        return "You were not the lucky winner"
      }
    } else if (item.item_type === "fixed") {
      return "Purchased!"
    } else {
      return null
    }
  }


  return (
    <Zoom in={showItems}>
      <Grid container
        spacing={2}
        className={won ? classes.wonWrapper : classes.wrapper}>
        <Grid item sm={12} md={12} lg={4}>
          <Box position="relative">
            <img src={item.image[0]?.url}
              className={classes.itemImg}
              onClick={() => {
                history.push(`/item/${item.item_id}/${item.slug}`)
              }
              } />
          </Box>
        </Grid>
        <Grid item sm={6} md={6} lg={5} >
          <Box height="100%" display="flex" flexDirection="column" >
            {sponsor.sponsor_name ?
              <Typography
                variant="caption"
                component="p"
                color="primary"
                className={won ? classes.whiteText : ""}>Suporting: {sponsor.sponsor_name}
              </Typography> : <Box style={{ height: "10px" }}/>}
            <Typography variant="h4"
              onClick={() => {
                history.push(`/item/${item.item_id}/${item.slug}`)
              }}
              className={won ? classes.whiteNameText : classes.nameText}>
              {item.name}
            </Typography>
            {won ? (
              <>
                <Typography variant="caption" color="secondary" component="p" className={won ? classes.wonWhiteText : ""}>
                  {bidText(item)}
                </Typography>
                <Typography variant="h4" color="secondary"
                  className={bidClasses(item)}>
                  {bidAmount(item)}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h4" color="secondary"
                  className={bidClasses(item)}>
                  {bidAmount(item)}
                </Typography>
                <Typography variant="caption" color="secondary" component="p" className={won ? classes.wonWhiteText : ""}>
                  {bidText(item)}
                </Typography>
              </>
            )}
            {!closed && item.item_type !== "fixed" && (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.bidButton}
                  disabled={winning}
                  onClick={() => {
                    history.push(`/item/${item.item_id}/${item.slug}`)
                  }}
                >
                  {item.item_type === "auction" && "Bid Now"}
                  {item.item_type === "raffle" && "Donate More"}
                </Button>
              </Box>
            )}
            <Box flexGrow={1}/>
            {won && <Typography variant="caption"
              className={classes.contactText}>*You will recive an email to collect your item</Typography>}
          </Box>
        </Grid>
        <Grid item sm={6} md={6} lg={3} className={classes.dateGrid}>
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            marginTop={"15%"}
            className={classes.dateContainer}>
            {item.item_type !== "fixed" && (
              <>
                <Typography
                  className={won ? classes.remainingWhiteTime : classes.remainginTime}>
                  {item.close_time === null ? null :
                    isClosed(item) ? moment().diff(item.close_time, "days") + " days ago" :
                      timeLeft.remaining.days !== 0 ?
                        `${timeLeft.remaining.days}d ${timeLeft.remaining.hours}h ${timeLeft.remaining.minutes}m` :
                        `${timeLeft.remaining.hours}h ${timeLeft.remaining.minutes}m ${timeLeft.remaining.seconds}s`}
                </Typography>
                <Typography
                  className={clsx({ [classes.remainingDate]: true, [classes.remainingWhiteDate]: won })}>
                  {
                    item.close_time === null ? null :
                      moment(item.close_time).format("MMM DD LT")
                  }
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        {!won &&
        <Divider style={{ width: "100%", marginBottom: "10px" }}/>
        }

      </Grid>
    </Zoom>
  )
}

export default MyItemCard
