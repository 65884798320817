import React, { useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import styles from "./styles"
import { Typography, Box } from "@material-ui/core/"
import hart from "assets/404_hart.svg"
import path from "assets/404_path.svg"
import { useDispatch } from "react-redux"
import { setPage } from "actions/page"
import usePageTitle from "hooks/usePageTitle"
const NotFound = ({ classes }) => {
  const dispatch = useDispatch()

  // Update page title
  usePageTitle("404")

  useEffect(() => {
    dispatch(setPage("404"))
  }, [])
  return (
    <Box className={classes.notFoundContainer}>
      <Box className={classes.textContainer}>
        <Typography className={classes.title}>404</Typography>
        <Typography variant="body2" className={classes.text}>Uhhoh, you boosted a little too high…we can’t find this page</Typography>
      </Box>
      <img src={hart} className={classes.hartImage}/>
      <img src={path} className={classes.pathImage}/>
    </Box>
  )
}

export default withStyles(styles)(NotFound)
