import React, { useState } from "react"
import useStyles from "./styles"
import ItemsCarousel from "react-items-carousel"
import CarouselCard from "components/CarouselCard"

const MobileCarousel = ({ items }) => {
  const classes = useStyles()
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  return (
    <ItemsCarousel
      classes={{ wrapper: classes.wrapper }}
      requestToChangeActive={setActiveItemIndex}
      activeItemIndex={activeItemIndex}
      numberOfCards={2}
      infiniteLoop
      gutter={16}
      // showSlither
      // leftChevron={<button>{"<"}</button>}
      // rightChevron={<button>{">"}</button>}
      // outsideChevron
      chevronWidth={0}
    >
      {items.map((item, i) => (
        <CarouselCard item={item} key={i}/>
      ))}
    </ItemsCarousel>
  )
}

export default MobileCarousel
