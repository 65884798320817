import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from "./styles"
import {
  Dialog,
  Typography,
  Button,
  Grid,
  Box,
  Link,
  Hidden
} from "@material-ui/core"
import { closeDialog } from "actions/dialogs"
import * as Yup from "yup"
import "yup-phone"
import { Formik, Form } from "formik"
import { openDialog } from "actions/dialogs"
import FormTextField from "components/FormTextField"
import MaskedMobileInput from "components/MaskedPhoneInput"
import { login } from "actions/authentication"
import { openSnackBar } from "actions/snack-bar"
import cleanPhone from "utils/cleanPhone"
import DialogHeader from "components/DialogHeader"
import DialogButton from "components/DialogButton"
import PoweredBy from "components/PoweredBy"

const loginSchema = Yup.object().shape({
  user_phone: Yup.string().phone("Please enter a valid phone number").required("Phone is required"),
  user_pass: Yup.string().required("Password is required")
})

const initialValues = {
  user_phone: "",
  user_pass: ""
}

const LoginDialog = () => {
  const classes = useStyles()
  const open = useSelector(state => state.dialogs.login?.open || false)
  const buttonRef = useRef(null)
  const dispatch = useDispatch()

  const handleClose = (state) => {
    dispatch(closeDialog("login", state))
  }

  const handleSubmit = (formData) => {
    buttonRef.current.setWorking(true)
    dispatch(login({
      ...formData,
      user_phone: cleanPhone(formData.user_phone)
    })).then(() => {
      handleClose("success")
      dispatch(openSnackBar({
        message: "Successfully logged in"
      }))
    }).catch(err => {
      console.log(err)
      dispatch(openSnackBar({
        message: "There was an error logging in"
      }))
    }).finally(() => {
      buttonRef.current.setWorking(false)
    })
  }

  const handleForgotPassword = phone => {
    handleClose()
    dispatch(openDialog("forgotPassword", {
      phone
    }))
  }

  return (
    <Dialog open={open}
      classes={{ paper: classes.paper }}>
      <Formik validationSchema={loginSchema} initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values }) => (
          <Form>
            <DialogHeader
              onClose={handleClose}/>
            <Box className={classes.container}>
              <Box className={classes.headerTextWrapper}>
                <Typography variant="h4" color="primary">
                Login
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormTextField name="user_phone" type="tel" label="Phone" InputProps={{ inputComponent: MaskedMobileInput }} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField
                      name="user_pass"
                      label="Password"
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      onClick={() => handleForgotPassword(values.user_phone)}
                    >
                    Forgot Password
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Hidden smUp>
                <Typography color="secondary" className={classes.registerTextPhone}>
                  <Box className={classes.textPhone} >
                  Don't have an account?
                    <Link
                      color="primary"
                      onClick={() => {
                        handleClose()
                        dispatch(openDialog("register"))
                      }}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    >
                      Register Here
                    </Link></Box>
                </Typography>
              </Hidden>
            </Box>
            <DialogButton
              buttonRef={buttonRef}
              text="Login"
            />
            <Hidden smDown>
              <Box display="flex"justifyContent="center">
                <Typography color="secondary" className={classes.registerText}>
                  <Box className={classes.textPhone}> Don't have an account?{" "}
                    <Link
                      color="primary"
                      onClick={() => {
                        handleClose("change")
                        dispatch(openDialog("register"))
                      }}
                      style={{ cursor: "pointer" }}
                    >
                    Register Here
                    </Link></Box>
                </Typography>
              </Box>
            </Hidden>


          </Form>
        )}
      </Formik>
      <PoweredBy />
    </Dialog>
  )
}

export default LoginDialog
