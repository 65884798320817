import { makeStyles } from "@material-ui/core/styles"
import heart from "assets/background_heart.svg"

const styles = makeStyles(theme => ({
  container: {
    background: `url(${heart}) no-repeat center`,
    backgroundSize: "contain"
  },
  logoTrail: {
    position: "absolute",
    top: "200px",
    left: "-90px",
    width: "200px",
    height: "500px",
    transform: "rotate(90deg) scaleY(-1)",
    opacity: "0.3",
    pointerEvents: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  circle1: {
    position: "absolute",
    top: "490px",
    left: "-200px",
    opacity: "0.3",
    pointerEvents: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  circle2: {
    position: "absolute",
    top: "680px",
    left: "-200px",
    opacity: "0.3",
    pointerEvents: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}))

export default styles
