import API from "store/api"
import { getUser } from "actions/users"
import {
  LOGGING_IN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  SET_USER,
  UPDATE_LOGGED_IN_USER,
  REGISTERING,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  LOGOUT
} from "types/actions/authentication"

export const loggingIn = (loggingIn = true) => ({
  type: LOGGING_IN,
  loggingIn
})

export const loginError = (error = false) => ({
  type: LOGIN_ERROR,
  error
})

export const _loginSuccess = (user = {}, token = "") => ({
  type: LOGIN_SUCCESS,
  user,
  token
})

export const setUser = (user = {}) => ({
  type: SET_USER,
  user
})

export const updateLoggedInUser = (updates = {}) => {
  return ({
    type: UPDATE_LOGGED_IN_USER,
    updates
  })
}

export const registering = (registering = true) => ({
  type: REGISTERING,
  registering
})

export const registerError = (error = false) => ({
  type: REGISTER_ERROR,
  error
})

export const registerSuccess = (user = {}, token = "") => ({
  type: REGISTER_SUCCESS,
  user,
  token
})

export const logout = () => ({
  type: LOGOUT
})

export const loginSuccess = (user = {}, token = "") => {
  return dispatch => {
    dispatch(_loginSuccess(user, token))
  }
}

export const login = data => {
  return dispatch => {
    dispatch(loggingIn())
    const deferred = new Promise((resolve, reject) => {
      API.post("/auth")
        .set("Content-Type", "application/json")
        .send(data)
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText)
          }

          return response
        })
        .then(response => response.body)
        .then(data => {
          if (data.msg === "pass") {
            API.set("Authorization", data.token)
            dispatch(getUser(data.user[0].user_id, true))
              .then(getUserResponse => {
                dispatch(loggingIn(false))
                dispatch(loginSuccess(getUserResponse, data.token))
                resolve(getUserResponse)
              })
              .catch(reject)
          } else {
            dispatch(loggingIn(false))
            dispatch(loginError(true))
            reject(data)
          }
        })
        .catch(err => {
          dispatch(loggingIn(false))
          dispatch(loginError(true))
          reject(err.response.body)
        })
    })
    return deferred
  }
}

export const register = data => {
  return dispatch => {
    dispatch(registering())
    const deferred = new Promise((resolve, reject) => {
      API.post("/users/create")
        .set("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8")
        .send(data)
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText)
          }

          return response
        })
        .then(response => response.body)
        .then(data => {
          if (data.msg === "user created") {
            API.set("Authorization", data.token)
            dispatch(getUser(data.user_id))
              .then(getUserResponse => {
                dispatch(registering(false))
                dispatch(registerSuccess(getUserResponse, data.token))
                resolve(getUserResponse)
              })
              .catch(err => {
                reject(err)
              })
          } else if (data.msg === "user creation failed") {
            let err
            if (
              data.error &&
              data.error.indexOf("Duplicate entry") > -1
            ) {
              if (data.error.indexOf("'phone'") > -1) {
                // duplicate phone
                err = "That phone number already exists"
              } else {
                // duplicate email
                err = "That email address already exists"
              }
            } else {
              // bad pin
              err = "There was an error registering"
            }
            dispatch(registering(false))
            dispatch(registerError(true))
            reject(err)
          }
        })
        .catch(err => {
          dispatch(registering(false))
          dispatch(registerError(true))
          reject(err.response.body)
        })
    })
    return deferred
  }
}

export const refreshToken = currentToken => {
  return dispatch => {
    return API.get("/auth")
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText)
        }

        return response
      })
      .then(response => response.body)
      .then(data => {
        dispatch(loginSuccess(undefined, data.token))
        API.set("Authorization", data.token)
        return data
      })
      .catch(data => dispatch(loginError(true)))
  }
}

export const sendMagicLink = userId => {
  return dispatch => {
    return API.get(`/users/${userId}/link`)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText)
        }

        dispatch(loggingIn(false))

        return response
      })
      .then(response => response.body)
  }
}

export const sendPin = phone => {
  return dispatch => {
    const deferred = new Promise((resolve, reject) => {
      API.get(`/users/sendpin/phone/${phone}`)
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText)
          }

          dispatch(loggingIn(false))

          return response
        })
        .then(response => response.body)
        .then(response => {
          if (response.msg === "pin sent") {
            resolve(response)
          } else {
            reject(response)
          }
        })
        .catch(err => {
          reject(err)
        })
    })
    return deferred
  }
}

export const magicLink = magicLink => {
  return dispatch => {
    dispatch(loggingIn())
    const deferred = new Promise((resolve, reject) => {
      API.post(`/users/url/${magicLink}`)
        .set("Content-Type", "application/json")
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText)
          }

          return response
        })
        .then(response => response.body)
        .then(data => {
          if (data.msg === "user found") {
            API.set("Authorization", data.token)
            dispatch(getUser(data.user_id, true))
              .then(getUserResponse => {
                dispatch(loggingIn(false))
                dispatch(loginSuccess(getUserResponse, data.token))
                resolve(getUserResponse)
              })
              .catch(reject)
          } else {
            dispatch(loggingIn(false))
            dispatch(loginError(true))
            reject(data)
          }
        })
        .catch(err => {
          dispatch(loggingIn(false))
          dispatch(loginError(true))
          reject(err.response.body)
        })
    })
    return deferred
  }
}

export const checkPin = (userId, pin) => {
  return dispatch => {
    return API.post(`/users/${userId}/pin`)
      .send({
        pin
      })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText)
        }

        return response
      })
  }
}
