import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Button, Grid, Typography } from "@material-ui/core"
import Incrementer from "components/Incrementer"
import accounting from "accounting"
import { openDialog } from "actions/dialogs"
import { openSnackBar } from "actions/snack-bar"
import getUserPaysource from "utils/getUserPaysource"
import getCurrentUserId from "utils/getCurrentUserId"
import getUserPayId from "utils/getUserPayId"
import useStyles from "./styles"

const FixedItem = ({ currentItem, timeLeftToOpen, timeLeft }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [quantity, setQuantity] = useState(1)
  // const userId = useSelector(
  //   (state) => state?.authentication?.authenticatedUser?.user_id
  // )
  const authenticatedUser = useSelector(state => state?.authentication?.authenticatedUser
  )
  const maxQty = Number(currentItem.item_qty) - Number(currentItem.items_purchased || 0)

  // Checks for paysource. If none, will prompt user for CC info.
  const checkForPaySource = (fixedData) => {
    const userPaysource = getUserPaysource()
    const userPayId = getUserPayId()

    if (userPaysource) {
      const updatedFixedData = { ...fixedData, paysource: userPaysource, userPayId }
      dispatch(openDialog("fixedConfirmation", updatedFixedData)).catch((err) =>
        console.log(err)
      )
    } else {
      dispatch(
        openSnackBar({
          message: "Please add a credit card."
        })
      )
      dispatch(openDialog("profile", { cardRequired: true })).then((res) => {
        const userPaysource = getUserPaysource()
        const userPayId = getUserPayId()
        const updatedFixedData = { ...fixedData, paysource: userPaysource, userPayId }

        if (userPaysource) {
          dispatch(openDialog("fixedConfirmation", updatedFixedData)).catch((err) =>
            console.log(err)
          )
        }
      })
    }
  }

  const handleBuyNow = (dialog) => {
    const itemName = currentItem.name
    const itemId = currentItem.item_id
    const userId = authenticatedUser.user_id
    const cost = currentItem.cost
    const userPayId = authenticatedUser?.user_pay_id
    const paysource = getUserPaysource()
    const currency = "usd"
    const sendSms = false
    const fixedData = { itemId, quantity, itemName, userId, userPayId, paysource, currency, cost, sendSms }

    if (userId) {
      checkForPaySource(fixedData)
    } else {
      dispatch(openDialog(dialog))
        .then((res) => {
          if (res === "success") {
            const currentUserId = getCurrentUserId()

            // Update userId and check for pay source
            checkForPaySource({ ...fixedData, userId: currentUserId })
          } else if (res === "change") {
            return handleBuyNow(dialog === "login" ? "register" : "login")
          }
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <Box>
      <Grid className={classes.priceDetails} container alignItems="flex-start" justify="space-between">
        <Grid item>
          <Typography
            variant="subtitle2"
            className={classes.priceHeading}
          >
          Total Amount
          </Typography>
          <Typography
            variant="body2"
            className={classes.price}
          >
            {accounting.formatMoney(Number(currentItem.cost) * Number(quantity), "$", 0)}
          </Typography>
        </Grid>
        { timeLeftToOpen.isClosed && <Grid item>
          {maxQty > 0 &&
            (
              <Incrementer
                value={quantity}
                setter={setQuantity}
                maxValue={maxQty}
              />
            )
          }
        </Grid>
        }
      </Grid>
      {timeLeftToOpen.isClosed && maxQty > 0 &&
        <Box marginTop={2}>
          <Typography variant="subtitle2">Only {maxQty} left!</Typography>
        </Box>
      }
      {timeLeftToOpen.isClosed &&
      (<Box marginTop={4}>
        <Button className={classes.buyButton} variant="contained" color="primary" disabled={maxQty < 1} onClick={() => handleBuyNow("register")}>
          { maxQty > 0 ? "Buy Now" : "Sold-out"}
        </Button>
      </Box>)
      }
    </Box>
  )
}

export default FixedItem
