import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  wonWrapper: {
    margin: theme.spacing(1, 0),
    background: "linear-gradient(105deg, #FF4E40,#F440BD )",
    borderRadius: 4,
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2)
    }
  },
  wrapper: {
    marginBottom: theme.spacing(1),
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2)
    }
  },

  itemImg: {
    height: "210px",
    width: "322px",
    objectFit: "cover",
    borderRadius: 4,
    verticalAlign: "bottom",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },

  highBid: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontSize: "20px",
    fontWeight: 900
  },
  whiteTextHighBid: {
    fontSize: "14px",
    opacity: "100%",
    color: "white"
  },
  entriesText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontSize: "30px",
    fontWeight: 900
  },
  whiteTextEntriesText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontSize: "30px",
    fontWeight: 900,
    color: "white"
  },
  bidButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  contactText: {
    color: "white",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      width: "70%"
    }
  },
  dateGrid: {
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      right: 0,
      bottom: 25
    }
  },
  dateContainer: {
    [theme.breakpoints.down("md")]: {
      alignItems: "flex-end"
    }
  },
  whiteText: {
    color: "white",
    fontWeight: "bold",
    fontSize: "12px",
    marginBottom: theme.spacing(1)
  },
  wonWhiteText: {
    color: "white",
    fontWeight: "bold",
    fontSize: "30px"
  },
  whiteNameText: {
    color: "white",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden"
  },
  nameText: {
    fontWeight: "bold",
    cursor: "pointer",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden"
  },
  dateText: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    zIndex: 1
  },
  remainingWhiteTime: {
    fontSize: "1.4rem",
    color: "#FFFFFFE6",
    opacity: 0.8

  },
  remainingDate: {
    opacity: 0.6,
    fontSize: "1.4rem"
  },
  remainginTime: {
    fontSize: "1.4rem"
  },
  remainingWhiteDate: {
    fontSize: "1.4rem",
    color: "#FFFFFFE6"
  },
  heartImg: {
    width: 75,
    position: "absolute",
    right: -10,
    top: 5,
    [theme.breakpoints.down("md")]: {
      left: 270
    }
  }
}))

export default styles
