import React from "react"
import useStyles from "./styles"
import {
  Box,
  IconButton,
  List, ListItem,
  ListItemText,
  Drawer } from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import logo from "assets/logo.svg"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { openDialog } from "actions/dialogs"
import { logout } from "actions/authentication"
import { setMyItems } from "actions/items"

const PhoneDrawer = ({ open, setOpen }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  let history = useHistory()
  let path = ["/", "/items", "/about-us"]
  const authenticatedUser = useSelector(state => state.authentication.authenticatedUser)
  return (
    <Drawer
      className={classes.drawer}
      variant="temporary"
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton className={classes.claseButtonContianer}>
          <ClearIcon color="primary"
            className={classes.closeButton}
            fontSize="large"
            onClick={() => setOpen(false)}
          />
        </IconButton>
      </Box>
      <List>
        {["Home", "Items", "About Us"].map((text, index) =>
          (<ListItem button key={text}
            onClick={() => {
              history.push(`${path[index]}`)
              setOpen(false)
            }}>
            <ListItemText primary={text}
              classes={{ primary: classes.text }}
            />
          </ListItem>)
        )}
        {!isEmpty(authenticatedUser) && (
          <ListItem button
            onClick={() => {
              history.push("/my-items")
              setOpen(false)
            }}>
            <ListItemText primary="My Items"
              classes={{ primary: classes.text }}
            />
          </ListItem>

        )}


        {!isEmpty(authenticatedUser) && (
          <>
            <ListItem button
              onClick={() => {
                dispatch(openDialog("profile"))
                setOpen(false)
              }}>
              <ListItemText primary="Profile"
                classes={{ primary: classes.text }}
              />
            </ListItem>
            <ListItem button
              onClick={() => {
                dispatch(logout())
                dispatch(setMyItems([]))
                setOpen(false)
              }}>
              <ListItemText primary="Log Out"
                classes={{ primary: classes.text }}
              />
            </ListItem>
          </>

        )}
        {isEmpty(authenticatedUser) && (
          <ListItem button
            onClick={() => {
              dispatch(openDialog("login"))
              setOpen(false)
            }}>
            <ListItemText primary="Log In"
              classes={{ primary: classes.text }}
            />
          </ListItem>
        )}
      </List>
      <Box display="flex" alignItems="flex-end" justifyContent="center" className={classes.logoContainer}>
        <img src={logo} alt="kindboost logo"className={classes.logo}/>
      </Box>
    </Drawer>
  )
}

export default PhoneDrawer
