import React from "react"
import useStyles from "./styles"
import { Typography, Box, Container } from "@material-ui/core"
import HeaderBottom from "assets/border_bottom.svg"

const AboutUsHeader = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  return (
    <Box className={classes.headerContainer} display="flex" alignItems="center">
      <Container>
        <Typography variant="h1" className={classes.title}>How Kind Can You Go?</Typography>
      </Container>
      <img className={classes.bottomBorder} src={HeaderBottom} />
    </Box>
  )
}

export default AboutUsHeader
