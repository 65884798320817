import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  root: {
    // marginRight: theme.spacing(4),
    // marginBottom: theme.spacing(4),
    // width: 350,
    position: "relative",
    height: 325,
    boxShadow: "0px 3px 9px rgba(0, 0, 0, .12)",
    cursor: "pointer"
  },
  header: {
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(1)
  },
  tag: {
    padding: theme.spacing(0.5, 3),
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
    fontWeight: 700,
    borderRadius: 4
  },
  colorPrimary: {
    background: theme.palette.primary.main,
    color: "#fff"
  },
  colorSecondary: {
    background: theme.palette.secondary.main,
    color: "#fff"
  },
  media: {
    minHeight: 175
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 155,
    padding: theme.spacing(2),
    textAlign: "left",
    "&:last-child": {
      paddingBottom: theme.spacing(2)
    }
  },
  noSponsorBox: {
    height: "28px",
    [theme.breakpoints.down("sm")]: {
      height: "22px"
    }
  },
  viewButton: {
    fontSize: "1.2rem"
  }
}))

export default styles
