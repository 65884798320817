const styles = theme => ({
  app: {
    height: "100%",
    position: "relative",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 72
    }
  }
})

export default styles
