import React from "react"
import useStyles from "./styles"
import { Typography, Container } from "@material-ui/core"

const ShippingRedemption = ({ shippingText }) => {
  const classes = useStyles()


  return (
    <Container className={classes.container}>
      <Typography className={classes.title} variant="h3">{shippingText.title}</Typography>
      <Typography className={classes.text} variant="body1">{shippingText.text}</Typography>
    </Container>
  )
}

export default ShippingRedemption
