import React from "react"
import useStyles from "./styles"
import { Box } from "@material-ui/core"
import { Carousel } from "react-responsive-carousel"
import HeaderBottom from "../../assets/border_bottom.svg"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import HomeHeaderItem from "./HomeHeaderItem"

const HomeHeader = ({ items }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.carouselContainer}>
        <Carousel
          className={classes.carouselRoot}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay
          infiniteLoop
          swipeable={false}
          interval={8000}
        >
          {items.length && items.map((item) => (
            <HomeHeaderItem key={`home-${item.item_id}`} item={item} />
          ))}
        </Carousel>
      </Box>
      <img className={classes.bottomBorder} src={HeaderBottom} />
    </Box>
  )
}

export default HomeHeader
