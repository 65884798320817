import API from "store/api"
import serialize from "store/serialize"
import param from "jquery-param"
import { omitBy, isNil } from "lodash"
import { updateLoggedInUser } from "./authentication"

// getuser method loads user data for a given user id
export const getUser = id => {
  return dispatch => {
    const deferred = new Promise((resolve, reject) => {
      API.get(`/users/id/${id}`)
        .then(response => response.body)
        .then(response => {
          if (
            response &&
            (response.msg === "user found" || response.msg === "no card")
          ) {
            serialize("user", response)
              .then(response => {
                resolve(response)
              })
              .catch(err => {
                reject(err)
              })
          } else {
            reject("not found")
          }
        })
        .catch(err => {
          reject(err)
        })
    })
    return deferred
  }
}

export const updateUser = (id, data) => {
  return dispatch => {
    const deferred = new Promise((resolve, reject) => {
      API.post(`/users/update/${id}`)
        .set("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8")
        .send(data)
        .then(response => response.body)
        .then(response => {
          dispatch(updateLoggedInUser(data))
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
    return deferred
  }
}

export const addCard = data => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      API.post("/paysources/create")
        .type("form")
        .send(param(omitBy(data, isNil)))
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          return response
        })
        .then(response => response.body)
        .then(body => {
          dispatch(updateLoggedInUser({ user_paysources: [...getState().authentication.authenticatedUser.user_paysources, body] }))
          resolve(body)
        })
        .catch(reject)
    })
  }
}

export const removeCard = data => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      API.post("/paysources/remove")
        .type("form")
        .send(param(omitBy(data, isNil)))
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          return response
        })
        .then(response => response.body)
        .then(body => {
          if (body.msg === "paysource removed") {
            dispatch(updateLoggedInUser({
              user_paysources: [...getState().authentication.authenticatedUser.user_paysources].filter(x => x.paysource_id !== data.paysource_id)
            }))
          }
          resolve(body)
        })
        .catch(reject)
    })
  }
}
