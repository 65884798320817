import { makeStyles } from "@material-ui/core/styles"
import hart from "assets/background_heart.svg"

const styles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(5),
    backgroundImage: `url(${hart})`,
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    [theme.breakpoints.down("sm")]: {
      bakcgrounImage: "none",
      padding: "5px"

    }
  },
  text: {
    width: "50%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAling: "left",
    [theme.breakpoints.down("sm")]: {
      width: "95%"
    }
  },
  subtitle: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5)
  },
  imagesContainer: {
    width: "50%",
    margin: "0 auto",
    position: "relative",
    paddingBottom: theme.spacing(25),
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      paddingBottom: theme.spacing(5)
    }
  },
  images: {
    backgroundColor: "#D5D5D5",
    width: "200px",
    height: "200px",
    borderRadius: "50%"
  },
  stepContainer: {
    zIndex: 1,
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2)
    }
  },
  stepText: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1)
  },
  pathImage: {
    position: "absolute",
    opacity: "0.8",
    bottom: "4%",
    left: "-25%",
    width: "140%",
    // [theme.breakpoints.down("lg")]: {
    //   bottom: "-20%"
    // },
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  }
}))

export default styles
