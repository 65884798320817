import React, { useState, useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import useStyles from "./styles"
import sliderStyles from "./sliderStyles"
import { Button, Typography, Checkbox, Slider, Box } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { FormControlLabel } from "@material-ui/core"
import numberWithCommas from "utils/numberWithCommas"
import useDebounce from "hooks/useDebounce"

const PrettoSlider = withStyles(sliderStyles)(Slider)

export default function AdvancedFilters({ theme, priceFilter, setPriceFilter, priceFilterRange, itemTypeCheckboxes, setItemTypeCheckboxes, resetFilters, setShowItems }) {
  const classes = useStyles()
  const [sliderValue, setSliderValue] = useState(priceFilter)
  const debouncedSliderValue = useDebounce(sliderValue, 300)

  const handleCheckboxChange = (e, newValue) => {
    setShowItems(false)
    if (newValue) {
      setItemTypeCheckboxes([...itemTypeCheckboxes, e.target.name])
    } else {
      setItemTypeCheckboxes(itemTypeCheckboxes.filter(x => x !== e.target.name))
    }
  }

  const handleSliderChange = (_e, newValue) => {
    setShowItems(false)
    setSliderValue(newValue)
  }

  // only send data every 300ms
  useEffect(() => {
    setPriceFilter(debouncedSliderValue)
  }, [debouncedSliderValue])

  // only for reset
  useEffect(() => {
    setShowItems(false)
    setSliderValue(priceFilter)
  }, [priceFilter])

  return (
    <Grid container spacing={4} className={classes.wrapper}>
      <Grid item xs={12} md={5} container className={classes.advancedFilterContainer} direction="column" justify="flex-start" alignItems="flex-start">
        <Box>
          <Typography className={classes.sectionLabel} variant="body1">Item Price</Typography>
        </Box>
        <Box className={classes.sliderWidth}>
          <Box display="flex" alignItems="center">
            <Typography className={classes.priceRange} style={{ marginRight: 16 }} variant="body1">${numberWithCommas(priceFilterRange[0]) !== "Infinity" ? numberWithCommas(priceFilterRange[0]) : 0}</Typography>
          </Box>
          <PrettoSlider
            // classes={classes.rootValueLabel}
            valueLabelFormat={value => <div>{(value !== priceFilterRange[0] && value !== priceFilterRange[1]) ? numberWithCommas(value) !== "-Infinity" ? `$${numberWithCommas(value)}` : "$0" : ""}</div>}
            value={sliderValue}
            onChange={handleSliderChange}
            valueLabelDisplay="on"
            aria-labelledby="range-slider"
            min={priceFilterRange[0]}
            max={priceFilterRange[1]}
            step={priceFilterRange[1] - priceFilterRange[0] < 100 ? 10 : 100}
            getAriaLabel={() => "slider"}
            defaultValue={sliderValue}
          />
          <Box display="flex" alignItems="center">
            <Typography className={classes.priceRange} style={{ marginLeft: 16 }} variant="body1">${numberWithCommas(priceFilterRange[1]) !== "-Infinity" ? numberWithCommas(priceFilterRange[1]) : 0}</Typography>
          </Box>
        </Box>
      </Grid>
      {/* Spacer */}
      <Grid className={classes.spacer} item md={1} />
      <Grid item xs={12} md={6} container className={classes.advancedFilterContainer} direction="row" justify-content="flex-start">
        <Typography className={classes.sectionLabel} variant="body1">Filters</Typography>
        <Box className={classes.filtersContainer}>
          <FormControlLabel className={classes.textProperties} control={<Checkbox classes={{ root: classes.checkboxRoot }} name="auction" checked={itemTypeCheckboxes.includes("auction")} onChange={handleCheckboxChange} />} label="Bid Item"/>
          {/* <FormControlLabel className={classes.textProperties} control={<Checkbox classes={{ root: classes.checkboxRoot }} name="raffle" checked={itemTypeCheckboxes.includes("raffle")} onChange={handleCheckboxChange} />} label="Donate Item"/> */}
          <FormControlLabel className={classes.textProperties} control={<Checkbox classes={{ root: classes.checkboxRoot }} name="buy_now" checked={itemTypeCheckboxes.includes("buy_now")} onChange={handleCheckboxChange} />} label="Buy Now"/>
          <FormControlLabel className={classes.textProperties} control={<Checkbox classes={{ root: classes.checkboxRoot }} name="featured" checked={itemTypeCheckboxes.includes("featured")} onChange={handleCheckboxChange} />} label="Featured"/>
          <FormControlLabel className={classes.textProperties} control={<Checkbox classes={{ root: classes.checkboxRoot }} name="new" checked={itemTypeCheckboxes.includes("new")} onChange={handleCheckboxChange} />} label="New Items" />
          <FormControlLabel className={classes.textProperties} control={<Checkbox classes={{ root: classes.checkboxRoot }} name="ending" checked={itemTypeCheckboxes.includes("ending")} onChange={handleCheckboxChange} />} label="Ending Soon" />
          <FormControlLabel className={classes.textProperties} control={<Checkbox classes={{ root: classes.checkboxRoot }} name="this_too_shall_pass" checked={itemTypeCheckboxes.includes("this_too_shall_pass")} onChange={handleCheckboxChange} />} label="This Too Shall Pass" />
        </Box>
      </Grid>
      <Box className={classes.clearButtonContainer}>
        <Button className={classes.clearButton} color="secondary" onClick={resetFilters}>Clear All</Button>
      </Box>
    </Grid>
  )
}
