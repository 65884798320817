import React from "react"
import useStyles from "./styles"
import { Box, Typography, Button } from "@material-ui/core"
import { useHistory } from "react-router-dom"

const MoreInformation = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  let history = useHistory()
  return (
    <Box>
      <Box className={classes.pinkContainer}
        display="flex" justifyContent="center"
        alignItems="center">
        <Typography variant="h3"
          classes={{ root: classes.title }}
          color="secondary">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
        </Typography>
      </Box>
      <Box
        className={classes.moreInformationContainer}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column">
        <Typography variant="body2"
          className={classes.text}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip ex ea commodo consequat.
        </Typography>
        <Button variant="contained"
          className={classes.donateButton}
          color="primary"
          onClick={() => history.push("/items")}>
          Explore more
        </Button>
      </Box>
    </Box>
  )
}

export default MoreInformation

