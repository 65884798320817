import React, { useState } from "react"
import { TextField, InputAdornment, IconButton } from "@material-ui/core"
import FormError from "components/FormError"
import { useFormikContext } from "formik"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

const FormTextField = ({ name, errorClass = "", type = "", ...rest }) => {
  const formikProps = useFormikContext()
  const [showPassword, setShowPassword] = useState(false)
  const isPassword = type === "password"
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleFocus = event => {
    event.preventDefault()
    const { target } = event
    setTimeout(() => {
      target.selectionStart = target.value.length
    })
  }


  return (
    <>
      <TextField
        color="secondary"
        variant="outlined"
        name={name}
        onFocus={handleFocus}
        value={formikProps.values[name]}
        onChange={e => formikProps.setFieldValue(e.target.name, e.target.value)}
        fullWidth
        InputProps={isPassword &&
          {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        type={!isPassword ? type : showPassword ? "text" : "password"}
        {...rest}
      />
      <FormError formikProps={formikProps} name={name} errorClass={errorClass}/>
    </>
  )
}

export default FormTextField
