import React from "react"
import useStyles from "./styles"
import { Box, Typography } from "@material-ui/core"

const PopularRaffle = ({ }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Typography
        variant="subtitle1"
        className={classes.text}>
        Most Popular
      </Typography>
    </Box>

  )
}

export default PopularRaffle
