import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  drawerPaper: {
    width: "60%",
    backgroundColor: "#071334",
    padding: theme.spacing(2),
    height: "100vh"

  },
  closeButton: {
    color: "white",
    zIndex: 1
  },
  text: {
    color: "white",
    fontWeight: "bold",
    marginBottom: theme.spacing(2)
  },
  logoContainer: {
    height: "100%"
  },
  logo: {
    width: "115px",
    height: "20px",
    marginBottom: theme.spacing(3)
  }

}))

export default styles
