import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Box } from "@material-ui/core"
import ItemsCarousel from "react-items-carousel"
import useStyles from "./styles"

const SponsorCarousel = ({ children }) => {
  const classes = useStyles()
  const sponsors = useSelector(state => state.event?.data?.sponsors)
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const [numberOfSponsors, setNumberOfSponsors] = useState(0)
  const numberOfCards = window.innerWidth > 992 ? 5 : window.innerWidth > 576 ? 3 : 1
  const intervalTime = 3000

  useEffect(() => {
    if (sponsors) {
      setNumberOfSponsors(sponsors.length)
    }
  }, [sponsors])

  const tick = () => {
    setActiveItemIndex(prevIndex => prevIndex + 1)
  }

  useEffect(() => {
    const interval = setInterval(tick, intervalTime)
    return () => clearInterval(interval)
  })


  const SponsorContainer = ({ sponsor }) => {
    const imageSrc = sponsor?.image ? `https://res.cloudinary.com/bidr/image/upload/${JSON.parse(sponsor?.image)?.public_id}` : ""
    return (
      <>
        {sponsor.link && <a href={sponsor.link} target="_blank">
          <Box className={classes.sponsorContainer}>
            <img className={classes.sponsorImage} src={imageSrc} alt={sponsor?.sponsor_name} />
          </Box>
        </a>}
        {!sponsor.link &&
          <Box className={classes.sponsorContainer}>
            <img className={classes.sponsorImage} src={imageSrc} alt={sponsor?.sponsor_name} />
          </Box>
        }
      </>
    )
  }

  return (
    <Box>
      {sponsors &&
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={numberOfCards}
        gutter={8}
        showSlither={false}
        firstAndLastGutter
        infiniteLoop
      >
        {sponsors && sponsors.map(sponsor => (
          <SponsorContainer key={sponsor.sponsor_id} sponsor={sponsor} />
        ))}
      </ItemsCarousel>}
    </Box>
  )
}

export default SponsorCarousel
