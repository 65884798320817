import React, { useMemo } from "react"
import useStyles from "./styles"
import { Box, Typography } from "@material-ui/core"
import useCountdown from "hooks/useCountdown"
import isClosed from "utils/isClosed"

const ItemHeader = ({ currentItem }) => {
  const classes = useStyles()
  const isMobile = window.innerWidth < 600
  const timeLeft = useCountdown(currentItem.close_time)
  const timeLeftToOpen = useCountdown(currentItem.open_time)

  const timeText = useMemo(() => {
    if (isClosed(currentItem)) {
      return "Closed"
    }

    if (!timeLeftToOpen.isClosed) {
      // if (timeLeftToOpen.remaining.months !== 0) {
      //   return timeLeftToOpen.remaining.months > 1 ? `Opens in ${timeLeftToOpen.remaining.months} months` : `Opens in ${timeLeftToOpen.remaining.months} month`
      // } else if (timeLeftToOpen.remaining.days !== 0) {
      //   return timeLeftToOpen.remaining.days > 1 ? `Opens in ${timeLeftToOpen.remaining.days} days` : `Opens in ${timeLeftToOpen.remaining.days} day`
      // } else {
      //   return "Opening soon!"
      // }
      return "Opening soon!"
    } else {
      if (timeLeft.remaining.months !== 0) {
        return timeLeft.remaining.months > 1 ? `${timeLeft.remaining.months} months left` : `${timeLeft.remaining.months} month left`
      } else if (timeLeft.remaining.days !== 0) {
        return timeLeft.remaining.days > 1 ? `${timeLeft.remaining.days} days left` : `${timeLeft.remaining.days} day left`
      } else {
        return "Closing soon!"
      }
    }
  }, [timeLeftToOpen])

  return (
    <Box className={classes.itemHeaderContainer}
      style={isMobile ?
        { backgroundImage: `url(${currentItem?.image[0]?.headerUrlMobile})` } :
        { backgroundImage: `url(${currentItem?.image[0]?.headerUrl})` }
      }>
      <Box className={classes.contentContainer}>
        {currentItem.close_time !== null || (currentItem.close_time === null && isClosed(currentItem)) || (currentItem.open_time === null && isClosed(currentItem)) ? <Box className={classes.daysLeftContainer} display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h4">
            {/* {isClosed(currentItem) ? "Closed" : null }
            {((!isClosed(currentItem)) && !timeLeftToOpen.isClosed && timeLeftToOpen.remaining.days !== 0) && (timeLeftToOpen.remaining.days > 1 ? `Opens in ${timeLeftToOpen.remaining.days} days` : `Opens in ${timeLeftToOpen.remaining.days} day`)}
            {(!isClosed(currentItem)) && !timeLeftToOpen.isClosed && timeLeftToOpen.remaining.days === 0 && "Opening soon!"}
            {((!isClosed(currentItem)) && timeLeftToOpen.isClosed && timeLeft.remaining.days !== 0) && (timeLeft.remaining.days > 1 ? `${timeLeft.remaining.days} days left` : `${timeLeft.remaining.days} day left`)}
            {(!isClosed(currentItem)) && timeLeftToOpen.isClosed && timeLeft.remaining.days === 0 && timeLeft.remaining.days === 0 && "Closing soon!"} */}
            {timeText}
          </Typography>
        </Box> : null}
        <Typography variant="h1" className={classes.titleText} >{currentItem.name}</Typography>
      </Box>
    </Box>

  )
}

export default ItemHeader
