import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: "20px"
    }
  },
  pinkContainer: {
    backgroundColor: "#FDDCF3",
    width: "100%",
    height: "267px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px"
    }
  },
  title: {
    width: "80%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "95%"
    }
  },
  moreInformationContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "5px"
    }
  },
  text: {
    width: "50%",
    marginTop: theme.spacing(25),
    marginBottom: theme.spacing(10),
    textAling: "left",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      marginTop: theme.spacing(5),
      textAlign: "center"
    }
  },
  donateButton: {
    marginBottom: theme.spacing(20),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(10)
    }
  }
}))

export default styles
