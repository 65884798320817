import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  root: {
    margin: ` ${theme.spacing(2)}px`,
    boxShadow: "0px 3px 9px rgba(0, 0, 0, .12)",
    flex: 1,
    display: "inline-block",
    height: 325,
    // width: "30.33%",
    position: "relative",
    cursor: "pointer",
    [theme.breakpoints.down(600)]: {
      margin: 0,
      width: "100%"
    }
  },
  header: {
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(1)
  },
  tag: {
    padding: theme.spacing(0.5, 3),
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
    fontWeight: 700,
    borderRadius: 4
  },
  colorPrimary: {
    background: theme.palette.primary.main,
    color: "#fff"
  },
  colorSecondary: {
    background: theme.palette.secondary.main,
    color: "#fff"
  },
  media: {
    minHeight: 180
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "155px !important",
    padding: theme.spacing(2),
    textAlign: "left",
    "&:last-child": {
      paddingBottom: theme.spacing(2)
    }
  },
  noSponsorBox: {
    height: "28px !important",
    [theme.breakpoints.down("sm")]: {
      height: "22px!important"
    }
  },
  viewButton: {
    fontSize: "1.2rem"
  }
}))

export default styles
