import React, { useLayoutEffect } from "react"
import { Box } from "@material-ui/core"
import AboutUsHeader from "./components/AboutUsHeader"
import OurMission from "./components/OurMission"
import MoreInformation from "./components/MoreInformation"
import usePageTitle from "hooks/usePageTitle"
import useStyles from "./styles"

const AboutUsPage = ({ routes = [], ...rest }) => {
  const classes = useStyles()


  // Update page title
  usePageTitle("About Us")

  return (
    <Box >
      <Box>
        <AboutUsHeader />
      </Box>
      <Box >
        <OurMission />
      </Box>
      {/* <Box>
        <MoreInformation/>
      </Box> */}
    </Box>
  )
}

export default AboutUsPage
