import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  layout: {
    top: "2209px",
    left: "-185.6273193359375px",
    width: "1365px",
    height: "1537px"
  },
  h1Heading: {
    top: "2043px",
    left: "99px",
    // width: "603px",
    height: "44px"
  },
  heading: {
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2, 0)
    }
  },
  backgroundLayout: {
    background: "#FFF3FB 0% 0% no-repeat padding-box",
    opacity: "6"
  },
  container: {
    minHeight: "100vh",
    marginTop: "100px",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0
    }
  },
  spacingDiv: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2)
    }
  },
  gridButtonRow: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3)
    }
  },
  rootSearchBox: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    "& label.Mui-focused": {
      color: "transparent !important"
    },
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "0px !important"
    },
    "& .MuiInputBase-root": {
      "&:hover fieldset": {
        borderColor: "#797F91"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#797F91"
      },
      "& .MuiOutlinedInput-input": {
        padding: "12.5px 14px"
      }
    }
  },
  rootPagination: {
    "& label.Mui-focused": {
      color: "transparent !important"
    }
  },
  itemWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5)
  },
  activeButton: {
    color: theme.palette.secondary.main
  },
  tabs: {
    "& .MuiTab-root": {
      minWidth: "auto",
      padding: theme.spacing(1, 4),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1, 2)
      }
    },
    "& .MuiTab-textColorPrimary": {
      opacity: 0.52
    },
    "& .Mui-selected": {
      color: theme.palette.secondary.main,
      opacity: 1
    }
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

export default styles
