import { makeStyles } from "@material-ui/core/styles"
import Background from "assets/item-header-shaq.png"

const styles = makeStyles(theme => ({
  headerContainer: {
    position: "relative",
    background: `url(${Background})`,
    height: "429px",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "&:before": {
      height: "429px",
      content: "''",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: "transparent linear-gradient(181deg, #D83AA8 0%, #7B2D27 100%) 0% 0% no-repeat padding-box",
      opacity: "0.76"
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      backgroundSize: "600px",
      backgroundPosition: "bottom"
    }
  },
  title: {
    color: "white",
    overflow: "hidden",
    position: "relative",
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.5rem",
      lineHeight: "3.5rem"
    }
  },
  bottomBorder: {
    position: "absolute",
    bottom: -1,
    left: 0,
    width: "100%"
  }
}))

export default styles
