import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  carouselRoot: {
    "& div": {
      height: "auto"
    },
    "& .carousel-slider": {
      position: "relative",
      overflow: "inherit"
    },
    "& .control-dots": {
      height: "auto",
      bottom: -25,
      "& .dot": {
        background: theme.palette.text.primary,
        opacity: 0.6,
        outline: "none"
      },
      "& .dot.selected": {
        background: theme.palette.text.primary
      }
    },
    "& .slide": {
      background: "transparent"
    }
  },
  contentSlide: {
    display: "flex",
    justifyContent: "space-between",
    height: "auto"
  },
  backButton: {
    position: "absolute",
    width: "50px",
    height: "50px",
    left: -44,
    top: "35%",
    color: theme.palette.primary.main
  },
  forwardButton: {
    position: "absolute",
    right: -44,
    top: "35%",
    color: theme.palette.primary.main,
    width: "50px",
    height: "50px"
  },
  backArrow: {
    marginLeft: "8px"
  },
  forwardArrow: {
    marginLeft: "6px"
  },
  mobileCarousel: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: -32,
      width: 800
    }
  },
  mobileStacked: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  }
}))

export default styles
