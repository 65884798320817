import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  myItemsContainer: {
    minHeight: "100vh",
    marginTop: "100px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px"
    }
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2)
    }
  },
  statusTabsWrapper: {
    marginTop: theme.spacing(12)
  },
  endedText: {
    opacity: 0.6,
    fontSize: "1.4rem"
  },
  itemText: {
    opacity: 0.6,
    fontSize: "1.4rem"
  },
  itemsWrapper: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2)
    }
  },
  vartTabIndicator: {
    left: 0,
    right: "unset",
    width: 4,
    height: [24, "!important"]

  },
  vartTabIndicator2: {
    display: "flex",
    justifyContent: "center",
    height: 2,
    position: "relative",
    top: -10
  },
  vartTabRoot2: {
    minWidth: "initial",
    textTransform: "capitalize",
    fontSize: "1.6rem",
    padding: 0,
    marginRight: 30
  },
  vartTabRoot: {
    minWidth: "initial",
    minHeight: 20,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 10,
    marginBottom: 10,
    textTransform: "capitalize",
    fontSize: "1.6rem"
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  color: {
    color: "#071334E6",
    opacity: "0.6"
  }
}))

export default styles
