import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(9),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2)
    },
    "& h3": {
      flex: 1
    }
  },
  bottomButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  }
}))

export default styles
