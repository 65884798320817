import { useMemo } from "react"
import { useSelector } from "react-redux"

function useUserPaysource() {
  const authenticatedUser = useSelector(state => state.authentication.authenticatedUser)
  return useMemo(() => {
    return authenticatedUser?.user_paysources?.[0]
  }, [authenticatedUser])
}

export default useUserPaysource
