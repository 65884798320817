import React from "react"
import { Typography } from "@material-ui/core"
import { useFormikContext } from "formik"

const FormError = ({ name, errorClass = "" }) => {
  const formikProps = useFormikContext()
  return formikProps.touched[name] && formikProps.errors[name] ? (
    <Typography color="primary" variant="caption" className={errorClass}>
      {formikProps.errors[name]}
    </Typography>
  ) : null
}

export default FormError
