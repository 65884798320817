import React, { useState, useMemo, useEffect, useLayoutEffect, useRef } from "react"
import useStyles from "./styles"
import { Box, Chip, CircularProgress, Container, Grid, Tab, Tabs, Typography, Zoom } from "@material-ui/core"
import AdvancedFilters from "components/AdvancedFilters"
import ItemCard from "components/ItemCard"
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { TextField } from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
import { useSelector } from "react-redux"
import { humanize } from "inflection"
import InfiniteScroll from "react-infinite-scroll-component"
import useDebounce from "hooks/useDebounce"
import useSessionStorage from "hooks/useSessionStorage"
import getItemAmount from "utils/getItemAmount"
import usePublished from "hooks/usePublished"
import usePageTitle from "hooks/usePageTitle"
import moment from "moment"

const pageSize = 30

const Items = () => {
  const classes = useStyles()
  const tabsEl = useRef()
  const { loading } = useSelector(state => state.items)
  const data = usePublished()
  const [currentPage, setCurrentPage] = useState(0)
  const [defaultFilters, setDefaultFilters] = useSessionStorage("items_filters", {})
  // const [showAdvanced, setShowAdvanced] = useState(defaultFilters.showAdvanced || false)
  const [search, setSearch] = useState(defaultFilters.search || "")
  const [showItems, setShowItems] = useState(false)
  const [displayItems, setDisplayItems] = useState([])
  const [initialLoad, setInitialLoad] = useState(false)
  const debouncedSearch = useDebounce(search, 300)

  // Update page title
  usePageTitle("Items")

  const allCategories = useMemo(() => {
    return Array.from(new Set(data.map(x => x.categories).flat()))
  }, [data])
  const [categories, setCategories] = useState(defaultFilters.categories || [])
  const [itemTypeCheckboxes, setItemTypeCheckboxes] = useState(defaultFilters.itemTypeCheckboxes || [])
  const [priceFilter, setPriceFilter] = useState(defaultFilters.priceFilter || [])
  const priceFilterRange = useMemo(() => {
    const min = Math.min(...data.map(x => getItemAmount(x)))
    const max = Math.max(...data.map(x => getItemAmount(x)))
    if (priceFilter.length === 0 && isFinite(min) && isFinite(max)) {
      setPriceFilter([min, max])
    }
    return [min, max]
  }, [data])

  const filteredData = useMemo(() => {
    setCurrentPage(0)
    // setShowItems(false)
    setDefaultFilters({ search: debouncedSearch, categories, itemTypeCheckboxes, priceFilter })
    return data.map(item => {
      // check slider value
      const itemPrice = getItemAmount(item)
      if (priceFilter.length > 0 && (itemPrice < priceFilter[0] || itemPrice > priceFilter[1])) return { ...item, show: false }
      // check search
      if (debouncedSearch && item.name.search(new RegExp(debouncedSearch, "i")) === -1) return { ...item, show: false }
      // check categories
      if (categories.length > 0 && !item.categories.some(r => categories.includes(r))) return { ...item, show: false }
      // check auction_type
      // if (itemTypeCheckboxes.length > 0 && !(itemTypeCheckboxes.indexOf(item.item_type) >= 0)) return false

      const startTimeDiff = moment().add(1, "days").diff(moment(item.created), "days")
      const endTimeDiff = moment(item.close_time).diff(moment(), "days")
      if (itemTypeCheckboxes.length > 0) {
        // Check items tagged as featured
        if ((itemTypeCheckboxes.indexOf("featured") >= 0) && !(getSortedItems(item.tags, "featured"))) {
          return { ...item, show: false }
        }

        if ((itemTypeCheckboxes.indexOf("this_too_shall_pass") >= 0) && !(getSortedItems(item.tags, "this-too-shall-pass"))) {
          return { ...item, show: false }
        }

        // Check new items
        if ((itemTypeCheckboxes.indexOf("new") >= 0) && !(startTimeDiff <= 3 && startTimeDiff >= 0)) {
          return { ...item, show: false }
        }
        // Check items closing soon
        if ((itemTypeCheckboxes.indexOf("ending") >= 0) && !(endTimeDiff < 3 && endTimeDiff >= 0)) {
          return { ...item, show: false }
        }
        // Check auction_type
        if ((itemTypeCheckboxes.indexOf("auction") >= 0 || itemTypeCheckboxes.indexOf("raffle") >= 0) && !(itemTypeCheckboxes.indexOf(item.item_type) >= 0)) {
          return { ...item, show: false }
        }

        // Check fixed item_type
        if ((itemTypeCheckboxes.indexOf("buy_now") >= 0) && item.item_type !== "fixed") {
          return { ...item, show: false }
        }

        return { ...item, show: true }
      }

      return { ...item, show: true }
    })
  }, [priceFilter, categories, itemTypeCheckboxes, debouncedSearch, data])

  function getSortedItems(toFilter, term) {
    if (toFilter.length === 0) {
      return false
    }
    const noSpace = term.replaceAll("-", "")
    const noSpaceRegex = new RegExp(noSpace, "i")
    const regex = new RegExp(term, "i")

    return regex.test(toFilter.join(",")) || noSpaceRegex.test(toFilter.join(","))
  }
  // function getSortedItems(items, term, field = "categories") {
  //   return items.filter(item => {
  //     const noSpace = term.replaceAll("-", "")
  //     const noSpaceRegex = new RegExp(noSpace, "i")
  //     const regex = new RegExp(term, "i")

  //     return regex.test(item[field].join(",")) || noSpaceRegex.test(item[field].join(","))
  //   })
  // }

  // Controls Zoom transition when filter is changed
  useEffect(() => {
    if (!initialLoad) {
      setDisplayItems(() => filteredData.filter(item => item.show))
      setShowItems(true)
      setTimeout(() => {
        setInitialLoad(true)
      }, 500)
    } else {
      setTimeout(() => {
        setDisplayItems(() => filteredData.filter(item => item.show))
        setShowItems(true)
      }, 250)
    }
  }, [filteredData])

  const paginatedData = useMemo(() => displayItems.slice(0, (currentPage + 1) * pageSize), [displayItems, currentPage])

  const handleCategoryChange = (category) => {
    setShowItems(false)
    if (category === "all") {
      setCategories([])
      return
    }

    const activeCategory = !!(categories.indexOf(category) >= 0)

    setCategories(() => {
      if (activeCategory) {
        return categories.filter(x => x !== category)
      } else {
        return [...categories, category]
      }
    })
  }

  const removeCategory = (remvoeCategory) => {
    setShowItems(false)
    setCategories((currentCategories) => {
      return currentCategories.filter(category => category !== remvoeCategory)
    })
  }

  const removeTag = (removeTag) => {
    setItemTypeCheckboxes((currentCheckBoxes) => {
      return currentCheckBoxes.filter(tag => tag !== removeTag)
    })
  }

  const resetFilters = () => {
    setCategories([])
    setItemTypeCheckboxes([])
    setPriceFilter(priceFilterRange)
    setSearch("")
    setCurrentPage(0)
  }


  useEffect(() => {
    const scroller = tabsEl.current && tabsEl.current.querySelector(".MuiTabs-scroller")
    if (scroller) {
      if (categories.length >= 1) {
        const selected = scroller.querySelector(".Mui-selected")
        const selectedWidth = selected?.offsetWidth
        const offset = selected?.offsetLeft
        const middle = scroller.offsetWidth / 2

        if (middle < offset) {
          scroller.scrollTo(offset + (selectedWidth / 2) - middle, 0)
        }
      }
    }
  }, [allCategories])

  return (
    <Container>
      <Grid className={classes.container}>
        <Grid container className={classes.spacingDiv}>
          <Grid item xs={12} md={8}><Typography className={classes.heading} variant="h3">Browse All the KindBoost Items</Typography></Grid>
          <Grid item xs={12} md={4} container direction="row" justify="flex-end" alignItems="flex-start">
            <TextField
              className={classes.rootSearchBox}
              variant="outlined"
              placeholder="Search"
              InputProps={{ startAdornment: (<InputAdornment position="start"> <SearchIcon style={{ color: "#797F91", opacity: 0.37 }} /></InputAdornment>) }}
              value={search}
              onChange={e => setSearch(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
            />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} md={12} container className={classes.gridButtonRow}>
            <Tabs
              className={classes.tabs}
              value={false}
              onChange={(e, value) => (handleCategoryChange(value))}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
              aria-label="categories"
              ref={tabsEl}
            >
              <Tab label="All" className={categories.length === 0 ? "Mui-selected" : ""} value="all" color="secondary" />
              {allCategories.map((category, index) => (
                <Tab key={index} className={(categories.indexOf(category) !== -1) ? "Mui-selected" : ""} label={humanize(category)} value={category} color="secondary" />
              ))}
            </Tabs>
          </Grid>
          {/* <Grid item xs={12} md={2} className={classes.gridButtonRow} container direction="row" justify="center" alignItems="center">
            <Button color="secondary" size="large" onClick={() => setShowAdvanced(!showAdvanced)}>
              Advanced Filters <KeyboardArrowDownIcon style={{ transform: showAdvanced ? "rotate(180deg)" : "", transition: "all 300ms" }}/>
            </Button>
          </Grid> */}
        </Grid>
        {/* <Collapse in={showAdvanced}> */}
        <AdvancedFilters
          priceFilter={priceFilter}
          setPriceFilter={setPriceFilter}
          priceFilterRange={priceFilterRange}
          itemTypeCheckboxes={itemTypeCheckboxes}
          setItemTypeCheckboxes={setItemTypeCheckboxes}
          resetFilters={resetFilters}
          setShowItems={setShowItems}
        />
        {/* </Collapse> */}
        <Box marginTop={3}>
          {categories?.map(cat => (
            <Chip key={cat} className={classes.chip} label={humanize(cat)} onDelete={() => removeCategory(cat)} color="secondary" />
          ))}
          {defaultFilters?.itemTypeCheckboxes?.map(tag => {
            if (tag === null) {
              return
            }
            let label = tag

            if (tag === "auction") {
              label = "Bid Item"
            }

            if (tag === "raffle") {
              label = "Donate Item"
            }

            return <Chip key={label} className={classes.chip} label={label && humanize(label)} onDelete={() => removeTag(tag)} color="secondary" />
          })}
        </Box>
        <InfiniteScroll
          scrollThreshold={0.7}
          style={{ overflow: "initial" }}
          scrollableTarget="app-container"
          dataLength={paginatedData.length}
          next={() => {
            setCurrentPage(currentPage + 1)
          }}
          hasMore={displayItems.length !== paginatedData.length}
          loader={<div className="loader" key={0}>Loading ...</div>}
        >
          <Grid item xs={12} container direction="row" justify="space-around" className={classes.itemWrapper}>
            {!loading && initialLoad && showItems && paginatedData.length === 0 && (<Box marginTop={10}><Typography variant="h3">No items matching filters</Typography></Box>)}
            {!loading && paginatedData.length !== 0 &&
              (
                <Grid container spacing={5} className={classes.itemWrapper}>
                  {paginatedData.map(item => {
                    return (
                      <Grid key={item.item_id} item xs={12} sm={6} md={4}>
                        <Zoom in={!loading && showItems && item.show} {...(!loading ? { timeout: 250 } : {})}>
                          <Box>
                            <ItemCard item={item} />
                          </Box>
                        </Zoom>
                      </Grid>
                    )
                  })}
                </Grid>
              )
            }
            {loading && (<Box marginTop={10}><CircularProgress color="secondary" /></Box>)}
          </Grid>
        </InfiniteScroll>
      </Grid>
    </Container>
  )
}

export default Items
