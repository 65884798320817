import Record from "./base"
export class Sponsor extends Record {
  constructor(...props) {
    super(...props)
    const data = props[1]
    let lossy = "fl_lossy,f_auto"
    if (typeof data.image === "string" && data.image.indexOf("{") > -1) {
      data.image = JSON.parse(data.image)
    }
    if (typeof data.image === "string") {
      let img = data.image.replace(
        /\/events\/images\/event_images\/[\d]{4}\/web_sponsors\//,
        ""
      )
      this.image_url = encodeURI(
        `//res.cloudinary.com/bidr/image/upload/${lossy},d_eg_placeholder_xhn1xm.png/${data.auctionCode}/web_sponsors/${img}`
      )
    } else {
      this.image_url = encodeURI(
        `//res.cloudinary.com/bidr/image/upload/${lossy},d_eg_placeholder_xhn1xm.png/${data.image.image_version}/${data.auctionCode}/web_sponsors/${data.image.original_image_name}`
      )
    }
  }
}
