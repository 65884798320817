import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(theme => ({
  sponsorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 112,
    background: "#fff",
    overflow: "hidden"
  },
  sponsorImage: {
    width: "100%"
  }
}))

export default styles
