import React, { useEffect } from "react"
import useStyles from "./styles"
import { Container, Tab, Tabs } from "@material-ui/core"
import RulesStaff from "./components/RulesStaff"
import Details from "./components/Details"
import ShippingRedemption from "./components/ShippingRedemption"
import logoTrail from "assets/logo_trail.svg"
import circle from "assets/dotted_circle.svg"

const ItemInformation = ({ currentItem, shippingText }) => {
  const classes = useStyles()
  const [step, setStep] = React.useState(0)

  const handleChange = (event, newStep) => {
    setStep(newStep)
  }

  // Reset step back to details when item is changed
  useEffect(() => {
    setStep(0)
  }, [currentItem])

  const currentStep = [
    <Details currentItem={currentItem}/>,
    <RulesStaff />,
    <ShippingRedemption shippingText={shippingText}/>
  ]
  return (
    <Container className={classes.container} style={{ overflow: "hidden", position: "relative" }}>
      <Tabs
        value={step}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <Tab classes={{ wrapper: classes.labelText }} label="Details" />
        <Tab classes={{ wrapper: classes.labelText }} label="Rules" />
        {/* <Tab classes={{ wrapper: classes.labelText }} label="Shipping & Redemption" /> */}
      </Tabs>
      {currentStep[step]}
      <img src={logoTrail} className={classes.logoTrail} />
      <img src={circle} className={classes.circle1} />
      <img src={circle} className={classes.circle2} />
    </Container>
  )
}

export default ItemInformation
